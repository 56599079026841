import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/services/event.service';
import { VariableService } from 'src/app/services/variable.service';
import { SessionPickerComponent } from '../session-picker/session-picker.component';
import { SettingsComponent } from '../settings/settings.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  //providers: [DialogService]
})
export class MenuComponent implements OnInit {
  menu1: any[];
  menu2: any[];
  menu3: any[];

  constructor(
    private dialogService: DialogService,
    private variableService: VariableService,
    private eventService: EventService,
    private changeDetection: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.variableService.mapAssetId.subscribe(mapAssetId => {
      if (mapAssetId) this.menu2[0].items = this.eventService.getMissions();
    })

    this.variableService.gameTime.subscribe(gameTime => {
      if (gameTime.hour != null) {
        this.menu2[2].label = `${(!gameTime.dayTime ? (Number(gameTime.hour) + 12) : gameTime.hour)}:${gameTime.minute < 10 ? '0' + gameTime.minute : gameTime.minute}`;
        this.menu2 = [... this.menu2]
        //this.changeDetection.detectChanges();
      }
    })

    this.variableService.gameWeather.subscribe(gameWeather => {
      switch (gameWeather) {
        case 'Storm': this.menu2[1].label = this.translateService.instant("WEATHER.STORM"); break;
        case 'FogWet': this.menu2[1].label = this.translateService.instant("WEATHER.FOGWET"); break;
        case 'Ash': this.menu2[1].label = this.translateService.instant("WEATHER.ASH"); break;
        case 'FairWet': this.menu2[1].label = this.translateService.instant("WEATHER.FAIRWET"); break;
        case 'Cloudy': this.menu2[1].label = this.translateService.instant("WEATHER.CLOUDY"); break;
        case 'Snow': this.menu2[1].label = this.translateService.instant("WEATHER.SNOW"); break;
        case 'Rain': this.menu2[1].label = this.translateService.instant("WEATHER.RAIN"); break;
        case 'CloudyWindy': this.menu2[1].label = this.translateService.instant("WEATHER.CLOUDYWINDY"); break;
        case 'Fair': this.menu2[1].label = this.translateService.instant("WEATHER.FAIR"); break;
        case 'Hot': this.menu2[1].label = this.translateService.instant("WEATHER.HOT"); break;
        case 'RainHeavy': this.menu2[1].label = this.translateService.instant("WEATHER.RAINHEAVY"); break;
        case 'RainFog': this.menu2[1].label = this.translateService.instant("WEATHER.RAINFOG"); break;
        case 'RainLight': this.menu2[1].label = this.translateService.instant("WEATHER.RAINLIGHT"); break;
        case 'FogHeavy': this.menu2[1].label = this.translateService.instant("WEATHER.FOGHEAVY"); break;
        case 'RainWindy': this.menu2[1].label = this.translateService.instant("WEATHER.RAINWINDY"); break;
        case 'Fog': this.menu2[1].label = this.translateService.instant("WEATHER.FOG"); break;
        default: this.menu2[1].label = this.translateService.instant("WEATHER.UNKNOWN"); break;
      }
      this.changeDetection.detectChanges();
    });
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.refreshMenus();
    })
    this.refreshMenus();
  }

  refreshMenus() {
    this.menu1 = [
      {
        label: this.translateService.instant("SESSION-PICKER.TITLE"),
        command: () => { this.openSessionPicker() }
      }
    ];

    // InGame Menu
    this.menu2 = [
      {
        label: this.translateService.instant("MENU.GENERATE-EVENT")
      },
      {
        label: this.translateService.instant("WEATHER.UNKNOWN"),
        icon: "pi pi-cloud",
        style: { 'margin-left': 'auto' }
      },
      {
        label: '00:00',
        icon: "pi pi-clock",
        style: { 'margin-left': 'auto' }
      }
    ];

    // Settings / Layout Menu
    this.menu3 = [{
      label: this.translateService.instant("MENU.LAYOUT"),
      style: { 'margin-left': 'auto' },
      styleClass: 'p-ml-6',
      items: [
        {
          label: this.translateService.instant("MENU.EDIT-LAYOUT"),
          styleClass: 'p-ml-6',
          command: () => {
            this.variableService.toggleEditMode();
          }
        },
        {
          label: this.translateService.instant("MENU.ADD-COMPONENT"),
          items: [
            { label: this.translateService.instant("BMA.TITLE"), command: (x) => this.variableService.addComponent.next('bma') },
            { label: this.translateService.instant("CALL-REQUESTS.TITLE"), command: (x) => this.variableService.addComponent.next('call-requests') },
            { label: this.translateService.instant("EVENT-LIST.TITLE"), command: (x) => this.variableService.addComponent.next('eventList') },
            { label: this.translateService.instant("EVENT-VIEW.TITLE"), command: (x) => this.variableService.addComponent.next('eventView') },
            { label: this.translateService.instant("HOSPITALS.TITLE"), command: (x) => this.variableService.addComponent.next('hospitals') },
            { label: this.translateService.instant("MAP.TITLE"), command: (x) => this.variableService.addComponent.next('map') },
            { label: this.translateService.instant("STATUS-LOGGER.TITLE"), command: (x) => this.variableService.addComponent.next('status-logger') },
            { label: this.translateService.instant("STATUS-MONITOR.TITLE"), command: (x) => this.variableService.addComponent.next('status-monitor') },
            { label: this.translateService.instant("CHAT.TITLE"), command: (x) => this.variableService.addComponent.next('chat') },
          ]
        },
        {
          label: this.translateService.instant("MENU.SAVE-LAYOUT"),
          command: () => { this.variableService.dashboardCmd.next("save"); }
        },
        {
          label: this.translateService.instant("MENU.LOAD-LAYOUT"),
          command: () => { this.variableService.dashboardCmd.next("load"); }
        },
        {
          label: this.translateService.instant("MENU.RESET-LAYOUT"),
          command: () => { this.variableService.dashboardCmd.next("reset"); }
        }
      ]
    }, {
      label: this.translateService.instant("SETTINGS.TITLE"),
      command: () => { this.openSettings() }
    }];
  }

  openSessionPicker() {
    this.dialogService.open(SessionPickerComponent, {})
  }

  openSettings() {
    this.dialogService.open(SettingsComponent, {})

  }

}
