import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { Observable, Subscription } from 'rxjs';
import { VariableService } from 'src/app/services/variable.service';
import { EventService } from 'src/app/services/event.service';
import { Hospital, HospitalService } from 'src/app/services/hospital.service';
import { MenuItem } from 'primeng/api/menuitem';
import { Settings, SettingsService } from 'src/app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-call-requests',
  templateUrl: './call-requests.component.html',
})
export class CallRequestsComponent implements OnInit, OnDestroy {
  settings: Settings;
  @Input() height: number;

  hospitalSubscription: Subscription;
  hospitals: Hospital[] = [];
  vehicleSubscription: Subscription;
  vehicles: Vehicle[] = [];

  selectedVehicle: Vehicle;
  vehicleContextMenuItems: MenuItem[];

  constructor(
    private hospitalService: HospitalService,
    private vehicleService: VehicleService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    const vehicles = this.vehicleService.getVehicles();
    vehicles.forEach(vehicle => {
      if (vehicle.hasCallRequest > 0) {
        this.vehicles.push(vehicle);
      }
    });

    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      const data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        if (vehicle.hasCallRequest === 1) {
          this.vehicles.push({ ...vehicle });

          if (this.settings.audio.playCallRequestSound) {
            const audio = new Audio('assets/sounds/sprechwunsch.wav');
            audio.volume = this.settings.audio.volume;
            audio.play();
          }
        } else if (vehicle.hasCallRequest === 2) {
          this.vehicles.unshift(vehicle);

          if (this.settings.audio.playPrioCallRequestSound) {
            const audio = new Audio('assets/sounds/dringender-sprechwunsch.wav');
            audio.volume = this.settings.audio.volume;
            audio.play();
          }
        }
      } else {
        if (vehicle.hasCallRequest === 0) {
          this.vehicles = this.vehicles.filter(x => x.name !== vehicle.name);
        } else if (vehicle.hasCallRequest === 3) {
          data.hasCallRequest = 3;
        }
      }
      this.changeDetectorRef.detectChanges();
    });

    this.hospitals = this.hospitalService.getHospitals();
    this.hospitalSubscription = this.hospitalService.getUpdate().subscribe(hospital => {
      const data = this.hospitals.find(x => x.name === hospital.name);
      if (data == null) {
        this.hospitals.push(hospital);
      }
      this.changeDetectorRef.detectChanges();
    });

    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
  }

  onRowSelect(vehicle) {
    if (vehicle.data.currentEvent != null && vehicle.data.currentEvent > 0) {
      this.eventService.selectEvent(vehicle.data.currentEvent);
      if (vehicle.data.hasCallRequest === 1 || vehicle.data.hasCallRequest === 2) {
        this.vehicleService.setCallRequest(vehicle.data.name, 3);
        if (this.settings.sendSpeechPromtOnCallRequestSelect) this.vehicleService.setVehicleStatus(vehicle.data.name, 28);
      }
    } else if (vehicle.data.currentEvent === 0 && vehicle.data.previousEvent > 0) {
      this.eventService.selectEvent(vehicle.data.previousEvent);
      if (vehicle.data.hasCallRequest === 1 || vehicle.data.hasCallRequest === 2) {
        this.vehicleService.setCallRequest(vehicle.data.name, 3);
        if (this.settings.sendSpeechPromtOnCallRequestSelect) this.vehicleService.setVehicleStatus(vehicle.data.name, 28);
      }
    }
  }

  doubleClick(vehicle) {
    this.vehicleService.setCallRequest(vehicle.name, 0);
  }

  openVehicleContextMenu(event, cm, vehicle) {
    this.vehicleContextMenuItems = [
      { label: vehicle.displayName },
      { separator: true },
      {
        label: this.translateService.instant("VEHICLE-CONTEXT.SET-STATUS"), items: [
          { label: 'Status 1', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 1) },
          { label: 'Status 3', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 3) },
          { label: 'Status 4', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 4) },
          { label: 'Status 6', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 6) },
          { label: 'Status 7', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 7) },
          { label: 'Status 8', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 8) }
        ]
      },
      { label: this.translateService.instant("VEHICLE-CONTEXT.SPEECH-PROMPT"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 28) }
    ];

    if (vehicle.type2.includes('RTW') || vehicle.type2.includes('KTW') || vehicle.type2.includes('PTZ') || vehicle.type2.includes('ITW') || vehicle.type2.includes('RTH') || vehicle.type2.includes('IRTW') || vehicle.type2.includes('GRTW') || vehicle.type2.includes('NAW')) {
      const hospitals = [];
      this.hospitals.forEach(hospital => {
        hospitals.push({ label: hospital.name, command: (x) => this.vehicleService.assignToHospital(vehicle.name, hospital.name) });
      });

      this.vehicleContextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.ASSIGN-HOSPITAL"), items: hospitals
      });
    }

    this.vehicleContextMenuItems.push({ separator: true });

    if (vehicle.currentEvent > 0) {
      this.vehicleContextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.SELECT-EVENT"), command: (x) => {
          this.eventService.selectEvent(vehicle.currentEvent);
        }
      });
    }

    this.vehicleContextMenuItems.push({ label: this.translateService.instant("VEHICLE-CONTEXT.RESET-VEHICLE"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 27) });

    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }
}
