<p-table [value]="hospitals" [resizableColumns]="true" [scrollable]="true" [scrollHeight]="height+'px'">
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn style="flex-basis: 40%;">{{ 'HOSPITALS.HOSPITAL' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.TRAUMA' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.SURGICAL' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.NEUROLOGICAL' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.INTERNISTIC' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.BURN' | translate }}</th>
      <th pResizableColumn style="flex-basis: 10%; justify-content: center;">{{ 'HOSPITALS.ISOLATION' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-hospital>
    <tr>
      <td style="flex-basis: 40%;">{{hospital.name}}</td>
      <td class="ui-resizable-column" [class]="hospital.trauma < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.trauma}}</td>
      <td class="ui-resizable-column" [class]="hospital.surgical < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.surgical}}</td>
      <td class="ui-resizable-column" [class]="hospital.neurological < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.neurological}}</td>
      <td class="ui-resizable-column" [class]="hospital.internistic < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.internistic}}</td>
      <td class="ui-resizable-column" [class]="hospital.burn < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.burn}}</td>
      <td class="ui-resizable-column" [class]="hospital.isolation < 1 ? 'hospitalFull' : 'hospital'"
        style="flex-basis: 10%; justify-content: center;">{{hospital.isolation}}</td>
    </tr>
  </ng-template>
</p-table>
