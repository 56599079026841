<div class="p-dialog-header">
  <span class="p-dialog-title">{{'SETTINGS.TITLE' | translate}}</span>
  <div>
    <p-tabMenu [model]="tabItems" [activeItem]="activeTab"></p-tabMenu>
  </div>
  <div class="p-dialog-header-icons">
    <button type="button" class="p-dialog-header-icon p-link" (click)="resizeSettings()">
      <span class="pi pi-arrows-alt"></span>
    </button>
    <button type="button" class="p-dialog-header-icon p-link" (click)="closeSettings()">
      <span class="pi pi-times"></span>
    </button>
  </div>
</div>

<!-- Allgemeines -->
<div *ngIf="activeTab == tabItems[0]">
  <div class="grid" *ngIf="settings != null">
    <div class="col-4">
      <!-- Erste Spalte-->
      <p-panel header="{{'SETTINGS.GENERAL.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col-12 p-fluid">
            <p-selectButton [options]="languages" [(ngModel)]="settings.language" optionLabel="label"
              optionValue="value" (onChange)="changeLanguage($event)"></p-selectButton>
          </div>
          <div class="col-12 p-fluid">
            <p-toggleButton [(ngModel)]="settings.useGermanDisplayNames"
              onLabel="{{'SETTINGS.GENERAL.USE-GERMAN-DISPLAY-NAMES' | translate}}"
              offLabel="{{'SETTINGS.GENERAL.USE-GERMAN-DISPLAY-NAMES' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
          <div class="col-12 p-fluid">
            <p-toggleButton [(ngModel)]="settings.sendSpeechPromtOnCallRequestSelect"
              onLabel="{{'SETTINGS.GENERAL.AUTOMATIC-SPEECH-PROMPT' | translate}}"
              offLabel="{{'SETTINGS.GENERAL.AUTOMATIC-SPEECH-PROMPT' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
      <p-panel header="{{'SETTINGS.AUDIO.TITLE' | translate}}">
        <div class="grid mt-1" *ngIf="settings.audio != null">
          <div class="col-12"><span>{{'SETTINGS.AUDIO.VOLUME' | translate}}</span> <span
              style="float: right;">{{settings.audio.volume * 100 | number:
              '1.0-0'}}%</span></div>
          <div class="col-12">
            <p-slider [(ngModel)]="settings.audio.volume" min="0" max="1" step="0.01"></p-slider>
          </div>
          <div class="col-12"><span>{{'SETTINGS.AUDIO.SELECT-SOUNDS' | translate}}</span></div>
          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.audio.playBmaSound"
              onLabel="{{'SETTINGS.AUDIO.BMA-SOUND' | translate}}" offLabel="{{'SETTINGS.AUDIO.BMA-SOUND' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.audio.playNewEventSound"
              onLabel="{{'SETTINGS.AUDIO.NEW-EVENT-SOUND' | translate}}"
              offLabel="{{'SETTINGS.AUDIO.NEW-EVENT-SOUND' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.audio.playTimeoutSound"
              onLabel="{{'SETTINGS.AUDIO.TIMEOUT-SOUND' | translate}}"
              offLabel="{{'SETTINGS.AUDIO.TIMEOUT-SOUND' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
          <div class="col-6 p-fluid">
            <p-toggleButton [(ngModel)]="settings.audio.playCallRequestSound"
              onLabel="{{'SETTINGS.AUDIO.CALL-REQUEST-SOUND' | translate}}"
              offLabel="{{'SETTINGS.AUDIO.CALL-REQUEST-SOUND' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
          <div class="col-6 p-fluid">
            <p-toggleButton [(ngModel)]="settings.audio.playPrioCallRequestSound"
              onLabel="{{'SETTINGS.AUDIO.PRIO-CALL-REQUEST-SOUND' | translate}}"
              offLabel="{{'SETTINGS.AUDIO.PRIO-CALL-REQUEST-SOUND' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
      <p-panel header="{{'SETTINGS.LAYOUTS.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col p-fluid">
            <p-table [value]="savedLayouts" dataKey="id" editMode="row">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:10%">{{'SETTINGS.LAYOUTS.ID' | translate}}</th>
                  <th style="width:80%">{{'SETTINGS.LAYOUTS.NAME' | translate}}</th>
                  <th style="width:10%"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-layout let-editing="editing">
                <tr [pEditableRow]="layout">
                  <td> {{layout.id + 1}} </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="layout.name" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{layout.name}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <div class="flex align-items-center justify-content-center gap-2">
                      <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                        class="p-button-rounded p-button-text"></button>
                      <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash"
                        (click)="onLayoutRowDelete(layout)" class="p-button-rounded p-button-text"></button>
                      <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        (click)="onLayoutRowEditSave(layout)"
                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                      <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                        (click)="onLayoutRowEditCancel()"
                        class="p-button-rounded p-button-text p-button-danger"></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="col-4">
      <!-- Zweite Spalte-->
      <p-panel header="{{'SETTINGS.EVENTVIEW.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col-12 p-fluid">
            <p-toggleButton [(ngModel)]="settings.eventView.preferReserveVehicleWhenCloser"
              onLabel="{{'SETTINGS.GENERAL.PREFER-CLOSER-VEHICLES' | translate}}"
              offLabel="{{'SETTINGS.GENERAL.PREFER-CLOSER-VEHICLES' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"
              pTooltip="{{'SETTINGS.GENERAL.PREFER-CLOSER-VEHICLES-TOOLTIP' | translate}}"
              tooltipPosition="top"></p-toggleButton>
          </div>
          <div class="col-12 p-fluid">
            <p-toggleButton [(ngModel)]="settings.eventView.sendMessageOnHospitalAssignment"
              onLabel="{{'SETTINGS.EVENTVIEW.SENDMESSAGEONHOSPITALASSIGNMENT' | translate}}"
              offLabel="{{'SETTINGS.EVENTVIEW.SENDMESSAGEONHOSPITALASSIGNMENT' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
      <p-panel header="{{'SETTINGS.QUICK-SELECT.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col p-fluid">
            <p-table [value]="settings.eventView.fastButtons" [reorderableColumns]="true" dataKey="id" editMode="row"
              #fastButtonTable>
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:3rem"></th>
                  <th style="width:45%">{{'SETTINGS.QUICK-SELECT.LABEL' | translate}}</th>
                  <th style="width:45%">{{'SETTINGS.QUICK-SELECT.TYPE' | translate}}</th>
                  <th style="width:10%">
                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-text"
                      style="float: right" (click)="onButtonRowAdd()"></button>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-button let-editing="editing" let-index="rowIndex">
                <tr [pEditableRow]="button" [pReorderableRow]="index">
                  <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="button.name">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{button.name}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown [options]="vehicleTypes" appendTo="body" [(ngModel)]="button.value"
                          [style]="{'width':'100%'}"></p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{button.value}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <div class="flex align-items-center justify-content-center gap-2">
                      <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                        class="p-button-rounded p-button-text" (click)="onButtonRowInit(button)"></button>
                      <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash"
                        (click)="onButtonRowDelete(button)" class="p-button-rounded p-button-text"></button>
                      <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        (click)="onButtonRowEditSave(button)"
                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                      <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                        (click)="onButtonRowEditCancel(button)"
                        class="p-button-rounded p-button-text p-button-danger"></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="col-4">
      <!-- Dritte Spalte-->
      <p-panel header="{{'SETTINGS.STATUSMONITOR.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.statusMonitor.highlightVehiclesInCurrentEvent"
              onLabel="{{'SETTINGS.STATUSMONITOR.HIGHLIGHTVEHICLESINCURRENTEVENT' | translate}}"
              offLabel="{{'SETTINGS.STATUSMONITOR.HIGHLIGHTVEHICLESINCURRENTEVENT' | translate}}"
              class="p-button-raised p-button-success" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
      <p-panel header="{{'SETTINGS.MAP.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col-12 p-fluid">
            <p-selectButton [options]="vehicleOrgs" [(ngModel)]="settings.map.vehicleMarker" multiple="multiple">
            </p-selectButton>
          </div>

          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.map.eventMarker" onLabel="{{'SETTINGS.MAP.EVENTMARKER' | translate}}"
              offLabel="{{'SETTINGS.MAP.EVENTMARKER' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>

          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.map.leitsystemMarker"
              onLabel="{{'SETTINGS.MAP.LEITSYSTEMMARKER' | translate}}"
              offLabel="{{'SETTINGS.MAP.LEITSYSTEMMARKER' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
      <p-panel header="{{'SETTINGS.OTHER.TITLE' | translate}}">
        <div class="grid mt-1">
          <div class="col p-fluid">
            <p-toggleButton [(ngModel)]="settings.partyMode" onLabel="{{'SETTINGS.OTHER.PARTY-MODE' | translate}}"
              offLabel="{{'SETTINGS.OTHER.PARTY-MODE' | translate}}" class="p-button-raised p-button-success"
              onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton>
          </div>
        </div>
      </p-panel>
    </div>
  </div>
  <p style="position: absolute; bottom: 5px">{{'SETTINGS.MORE-SETTINGS-FOLLOW-SOON' | translate}}</p>
</div>

<!-- Layout -->
<!-- <div *ngIf="activeTab == tabItems[5]">
  Content 2
</div> -->

<!-- Alarm- und Ausrückeordnung -->
<div *ngIf="activeTab == tabItems[1]">
  <app-aao-editor></app-aao-editor>
</div>