import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Hospital {
  name: string;
  burn: number;
  trauma: number;
  surgical: number;
  neurological: number;
  internistic: number;
  isolation: number;
}

@Injectable({
  providedIn: 'root'
})

export class HospitalService {
  private hospitals: Hospital[] = [];
  private hospital = new Subject<Hospital>();

  public getUpdate(): Observable<Hospital> {
    return this.hospital.asObservable();
  }

  resetHospitals() {
    this.hospitals = [];
  }

  getHospitals(): Hospital[] {
    return this.hospitals;
  }

  updateHospital(hospital: Hospital) {
    const data = this.hospitals.find(x => x.name === hospital.name);
    if (data == null) {
      this.hospitals.push(hospital);
      this.hospital.next(hospital);
    } else {
      if (hospital.burn !== undefined && hospital.burn !== data.burn) {
        data.burn = hospital.burn;
      }

      if (hospital.trauma !== undefined && hospital.trauma !== data.trauma) {
        data.trauma = hospital.trauma;
      }

      if (hospital.surgical !== undefined && hospital.surgical !== data.surgical) {
        data.surgical = hospital.surgical;
      }

      if (hospital.neurological !== undefined && hospital.neurological !== data.neurological) {
        data.neurological = hospital.neurological;
      }

      if (hospital.internistic !== undefined && hospital.internistic !== data.internistic) {
        data.internistic = hospital.internistic;
      }

      if (hospital.isolation !== undefined && hospital.isolation !== data.isolation) {
        data.isolation = hospital.isolation;
      }

      this.hospital.next(data);
    }
  }

}
