import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService, EventX } from 'src/app/services/event.service';
import { VariableService } from 'src/app/services/variable.service';
import { MenuItem } from 'primeng/api/menuitem';
import { SelectItem } from 'primeng/api/selectitem';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { TreeNode } from 'primeng/api';
import { Settings, SettingsService } from 'src/app/services/settings.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
})
export class EventListComponent implements OnInit, OnDestroy {
  settings: Settings;
  @Input() height = 0;

  username = null;

  eventSubscription: Subscription;
  events: EventX[] = [];

  filterTypes: any[] = [];
  selectedFilter: number[] = [1, 2, 3];
  displayedEvents: TreeNode[] = [];

  selectedEvent: EventX;
  selectedContextEvent: TreeNode;
  cols: any[];
  items: MenuItem[];

  constructor(
    private variableService: VariableService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    const events = this.eventService.getEvents();
    events.forEach(event => {
      this.events.unshift(event);
    });
    this.filter();

    this.eventSubscription = this.eventService.getUpdate().subscribe(event => {
      let data = this.events.find(x => x.id === event.id);
      if (data == null) {
        this.events.unshift(event);
        // console.log(event.startTime);
        // const currentTime = new Date();
        // const timeDiff = (event.startTime.getTime() - currentTime.getTime()) / 1000;
        // console.log(timeDiff + ' datediff');
        if (event.state === 0) {
          if (this.settings.audio.playNewEventSound) {
            const audio = new Audio('assets/sounds/alarm.wav');
            audio.volume = this.settings.audio.volume;
            audio.play();
          }
        }
        this.filter();
      } else {
        data = event;
        this.filter();
      }
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.selectedEvent.subscribe(x => {
      this.selectEvent(x);
    });

    this.variableService.user.subscribe(x => {
      this.username = x.name;
    });

    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.refreshMenus();
    })
    this.refreshMenus();
  }

  refreshMenus() {
    this.cols = [
      { field: 'state', header: '', width: '2px' },
      { field: 'displayedId', header: this.translateService.instant("EVENT.ID"), width: '20%' },
      { field: 'text', header: this.translateService.instant("EVENT.TEXT"), width: '60%' },
      { field: 'keyword', header: this.translateService.instant("EVENT.KEYWORDS"), width: '20%' }
    ];

    this.items = [
      { label: this.translateService.instant("EVENT.FORCE-FINISH"), command: (event) => this.eventService.forceFinish(this.selectedContextEvent.data.id) },
      { label: this.translateService.instant("EVENT.CREATE-CHILD"), command: (event) => { this.eventService.addChildEvent(this.selectedContextEvent.data.id); } },
      { label: this.translateService.instant("EVENT.UNLOCK"), command: (event) => { this.eventService.forceUnblock(this.selectedContextEvent.data.id); } }
    ];

    this.filterTypes = [
      { label: this.translateService.instant("EVENT-LIST.NEW-EVENT"), value: 1 },
      { label: this.translateService.instant("EVENT-LIST.CURRENT-EVENT"), value: 2 },
      { label: this.translateService.instant("EVENT-LIST.FINISHED-EVENT"), value: 3 },
      { label: this.translateService.instant("EVENT-LIST.CLOSED-EVENT"), value: 4 },
    ]
  }


  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  onNodeSelect(event) {
    this.eventService.selectEvent(event.node.data.id);
  }

  onNodeUnSelect(event) {
    this.eventService.selectEvent(0);
  }

  filter() {
    const displayedEvents = [];

    let expandedEvents = [];
    this.displayedEvents.forEach(event => {
      if (event.expanded === true) {
        expandedEvents.push(event.data.id);
      }
    });

    let selectedEventId = null;
    if (this.selectedEvent != null && this.selectedEvent.parentEvent != null) {
      selectedEventId = this.selectedEvent.parentEvent;
    } else if (this.selectedEvent != null && this.selectedEvent.id != null) {
      selectedEventId = this.selectedEvent.id;
    }

    this.events.forEach(event => {
      if (this.selectedFilter.includes(event.state == 0 ? 1 : event.state)) {
        if (event.childEvents.length === 0) {
          if (event.parentEvent === 0) {
            if (expandedEvents.includes(event.id) || selectedEventId === event.id) {
              displayedEvents.push({ data: { displayedId: event.id, id: event.id, state: event.state, keyword: event.keyword, text: event.text }, expanded: true });
            } else {
              displayedEvents.push({ data: { displayedId: event.id, id: event.id, state: event.state, keyword: event.keyword, text: event.text } });
            }

          }
        } else {
          const children = [];
          event.childEvents.forEach(eventId => {
            const child = this.events.find(x => x.id === eventId);
            if (child != null) {
              children.push({ data: { displayedId: '>', id: child.id, state: child.state, keyword: child.keyword, text: child.text } });
            }
          });

          if (expandedEvents.includes(event.id) || selectedEventId === event.id) {
            displayedEvents.push({ data: { displayedId: event.id, id: event.id, state: event.state, keyword: event.keyword, text: event.text }, children, expanded: true });
          } else {
            displayedEvents.push({ data: { displayedId: event.id, id: event.id, state: event.state, keyword: event.keyword, text: event.text }, children });
          }
        }

      }
    });

    this.displayedEvents = displayedEvents;
  }

  selectEvent(eventId: number) {
    if (eventId === 0) {
      this.selectedEvent = null;
    } else {
      const event = this.events.find(y => y.id === eventId);
      if (event != null) {
        this.selectedEvent = event;
      }
    }
  }

  openCm(event, node, cm) {
    this.items = [];

    if (node.node.data.state !== 3) {
      this.items.push({ label: 'Einsatz abschließen', command: () => this.eventService.forceFinish(node.node.data.id) });
    }

    if (node.level === 0) {
      this.items.push({ label: 'Untereinsatz erstellen', command: () => this.eventService.addChildEvent(node.node.data.id) });
    }

    this.items.push({ label: 'Debug: Einsatz entsperren', command: () => this.eventService.forceUnblock(node.node.data.id) });

    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }
}
