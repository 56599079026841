<div [style.height]="(height - 48) + 'px'">
  <p-table [value]="messages" [scrollable]="true" [scrollHeight]="(height - 48) + 'px'">
    <ng-template pTemplate="body" let-message>
      <tr #row [attr.message]="message">
        <td>{{message}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="grid p-fluid box">
  <div class="col">
    <input type="text" pInputText [(ngModel)]="message" (keyup.enter)="sendMessage()" />
  </div>
  <div class="col-1">
    <button pButton type="button"
      pTooltip="{{'CHAT.SUBMIT' | translate}}" (click)="sendMessage()" tooltipPosition="left" showDelay="1000" hideDelay="500" icon="pi pi-send" class="toggleButtonFix"></button>
  </div>
</div>