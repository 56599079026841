import { Component, OnInit, Input, ChangeDetectorRef, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { VariableService } from 'src/app/services/variable.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit {
  @ViewChildren("row", { read: ElementRef }) rowElement: QueryList<ElementRef>;
  @Input() height = 0;

  messages = [];
  message = '';

  constructor(
    private socketService: SocketIOService,
    private variableService: VariableService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.variableService.chat.subscribe(x => {
      const message = `${x.playerName}: ${x.message}`;
      this.messages.push(message);
      this.changeDetectorRef.detectChanges();

      const el = this.rowElement.find(r => r.nativeElement.getAttribute("message") === message);
      el.nativeElement.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" });
    });
  }

  sendMessage() {
    this.socketService.socketEmit('lst', { cmd: 'chatMessage', data: { message: this.message } });
    this.message = '';
  }

}
