<gridster [options]="options">
  <gridster-item [item]="item" *ngFor="let item of dashboard" class="status-drag-content" id="grid_{{item.component}}">
    <p-panel [header]="item.name">

      <!-- <p-scrollPanel [style]="{width: '100%', height: (item.height - 45) + 'px'}" styleClass="statusScroll">
        
      </p-scrollPanel> -->

      <div class="fahrzeuge" *ngFor="let vehicle of vehicles">
        <!-- <button *ngIf="item.stationId == vehicle.stationId" pButton type="button"
          class="ui-button-raised fahrzeug status{{vehicle.status}}{{vehicle.hasCallRequest == 1 ? ' status5' : ''}}{{vehicle.hasCallRequest == 2 ? ' status0' : ''}}"
          (contextmenu)="openCm($event, vehicleContextMenu, vehicle)" (click)="addToEvent($event, vehicle)"
          (keyup.enter)="openCm($event, vehicleContextMenu, vehicle)" label=" ">
          <span class="vehButton leftText">{{vehicle.displayName}}</span>
          <span class="vehButton rightText">{{vehicle.status}}</span>
        </button> -->

        <span class="p-buttonset" *ngIf="item.stationId == vehicle.stationId">
          <button pButton [label]="vehicle.displayName" [style]="{width: '90%'}"
            class="p-button-outlined p-button-vehicle p-button-vehicle-bg p-button-status-{{vehicle.status}}-outlined"
            (click)="addToEvent($event, vehicle)" (contextmenu)="openCm($event, vehicleContextMenu, vehicle)"></button>
          <button pButton [style]="{width: '10%'}" class="p-button-vehicle p-button-status-{{vehicle.status}}" label=" ">
            <span class="p-button-label"> {{vehicle.status}}</span>
          </button>
        </span>
      </div>
    </p-panel>
  </gridster-item>
</gridster>

<p-contextMenu #vehicleContextMenu [model]="items" appendTo="body"></p-contextMenu>
