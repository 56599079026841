import { Component, OnInit } from '@angular/core';
import { EventService, EventX } from '../../services/event.service';
import { Observable } from 'rxjs';
import { SocketIOService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
})
export class TestComponent implements OnInit {
  events: Observable<EventX[]>;

  constructor(private eventService: EventService, private socketService: SocketIOService) { }

  ngOnInit(): void {
    // this.events = this.eventService.events;

    // this.eventService.createEvent({
    //   id: 1,
    //   text: 'Test',
    //   posX: 1.00,
    //   posY: 1.00,
    //   startTime: new Date(),

    //   state: 1,
    //   vehicles: [1, 2, 3, 4],
    //   messages: ['Hallo', 'Ciao'],
    //   endTime: new Date()
    // });

    // this.eventService.createEvent({
    //   id: 2,
    //   text: 'Test 2',
    //   posX: 1.00,
    //   posY: 1.00,
    //   startTime: new Date(),

    //   state: 1,
    //   vehicles: [1, 2, 3, 4],
    //   messages: ['Hallo', 'Ciao'],
    //   endTime: new Date()
    // });

    // this.socketService.socketListener().subscribe(data => {
    //   console.log(data);
    // });
  }

}
