import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

export interface Settings {
  version: number,
  theme: {
    darkMode: boolean
  }
  audio: {
    volume: number,
    playBmaSound: boolean,
    playNewEventSound: boolean,
    playTimeoutSound: boolean,
    playCallRequestSound: boolean,
    playPrioCallRequestSound: boolean
  }
  sendSpeechPromtOnCallRequestSelect: boolean
  partyMode: boolean,
  eventView: {
    fastButtons: {
      name: string,
      value: string
    }[],
    stats: {
      name: string,
      value: string
    }[],
    preferReserveVehicleWhenCloser: boolean,
    sendMessageOnHospitalAssignment: boolean
  },
  statusMonitor: {
    highlightVehiclesInCurrentEvent: boolean,
  },
  map: {
    eventMarker: boolean,
    vehicleMarker: string[],
    leitsystemMarker: boolean
  }
  useGermanDisplayNames: boolean,
  language: string
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  settingsInitialized = false;
  private _settings: Settings = {
    version: 3,
    theme: {
      darkMode: true
    },
    audio: {
      volume: 0.5,
      playBmaSound: true,
      playNewEventSound: true,
      playTimeoutSound: true,
      playCallRequestSound: true,
      playPrioCallRequestSound: true
    },
    partyMode: false,
    sendSpeechPromtOnCallRequestSelect: true,
    eventView: {
      fastButtons: [
        { name: "ELW", value: "ELW-C" },
        { name: "HLF", value: "HLF" },
        { name: "LF", value: "LF" },
        { name: "DLK", value: "DLK" },
        { name: "RTW", value: "RTW" },
        { name: "NEF", value: "NEF" },
        { name: "STW", value: "FUSTW" }
      ],
      stats: [
        { name: "LF/HLF", value: "LF" },
        { name: "RW", value: "RW" },
        { name: "DLK", value: "DLK" },
        { name: "RTW/KTW", value: "RTW" },
        { name: "NEF", value: "NEF" },
        { name: "STW", value: "FUSTW" }
      ],
      preferReserveVehicleWhenCloser: false,
      sendMessageOnHospitalAssignment: true
    },
    statusMonitor: {
      highlightVehiclesInCurrentEvent: true,
    },
    map: {
      eventMarker: true,
      vehicleMarker: ["1", "2", "3"],
      leitsystemMarker: true
    },
    useGermanDisplayNames: true,
    language: navigator.language == "de-DE" ? "de" : "en"
  };

  public settings = new BehaviorSubject<any>(this._settings);

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService
  ) { }

  async init() {
    if (!this.settingsInitialized) {
      let settings = await this.storageService.get('settings');
      if (settings) {
        settings = this.updateSettingsVersion(settings);
        this._settings = settings;
        this.settings.next(this._settings);

        // Set Language
        this.translateService.use(this._settings.language);
      }
      this.translateService.use(this._settings.language);
      this.settingsInitialized = true;
    }
  }

  updateSettingsVersion(settings) {
    console.log("SETTINGS VERSION", settings.version)
    if (settings.version <= 8) {
      switch (settings.version) {
        case 1: settings.version = 2; settings.preferReserveVehicleWhenCloser = false;
        case 2: {
          settings.version = 3;
          settings.eventView = {
            preferReserveVehicleWhenCloser: settings.preferReserveVehicleWhenCloser,
            fastButtons: [
              { id: "1", name: "ELW", value: "ELW-C" },
              { id: "2", name: "HLF", value: "HLF" },
              { id: "3", name: "LF", value: "LF" },
              { id: "4", name: "DLK", value: "DLK" },
              { id: "5", name: "RTW", value: "RTW" },
              { id: "6", name: "NEF", value: "NEF" },
              { id: "7", name: "STW", value: "FUSTW" }
            ]
          };
          delete settings.preferReserveVehicleWhenCloser;
        };
        case 3: {
          settings.version = 4;
          for (let btn of settings.eventView.fastButtons) {
            if (btn.name1 && !btn.name) { btn.name = btn.name1; delete btn.name1 }
          }
          settings.language = navigator.language == "de-DE" ? "de" : "en";
        };
        case 4: {
          settings.version = 5;
          settings.useGermanDisplayNames = true;
        };
        case 5: {
          settings.version = 6;
          settings.eventView.stats = [
            { name: "LF/HLF", value: "LF" },
            { name: "RW", value: "RW" },
            { name: "DLK", value: "DLK" },
            { name: "RTW/KTW", value: "RTW" },
            { name: "NEF", value: "NEF" },
            { name: "STW", value: "FUSTW" }
          ]
        }
        case 6: {
          settings.version = 7;
          settings.statusMonitor = {
            highlightVehiclesInCurrentEvent: true
          }
        }
        case 7: {
          settings.version = 8;
          settings.map = {
            eventMarker: true,
            vehicleMarker: ["1", "2", "3"],
            leitsystemMarker: true
          }
        }
        case 8: {
          settings.version = 8;
          settings.eventView.sendMessageOnHospitalAssignment = true;
          delete settings.preferReserveVehicleWhenCloser;
        }
      }

      this.storageService.set('settings', settings);
    }

    return settings;
  }

  async get(key: string) {
    return this.settings[key];
  }

  getAll() {
    return this._settings;
  }

  set(key: string, value: any) {
    this._settings[key] = value;
    this.settings.next(this._settings);
    this.storageService.set('settings', this._settings);
  }

  setAll(value: any) {
    this._settings = value;
    this.settings.next(this._settings);
    this.storageService.set('settings', this._settings);
  }
}
