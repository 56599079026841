import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SocketIOService } from './socket-io.service';

export interface Leitsystem {
  identifier: string;
  posX: number;
  posY: number;

  state1: string;
  state2: string;
  state3: string;
}

@Injectable({ providedIn: 'root' })

export class LeitsystemService {
  private leitsysteme: Leitsystem[] = [];
  private leitsystem = new Subject<Leitsystem>();

  constructor(private socketService: SocketIOService) { }

  public getUpdate(): Observable<Leitsystem> {
    return this.leitsystem.asObservable();
  }

  resetLeitsysteme() {
    this.leitsysteme = [];
  }

  getLeitsysteme(): Leitsystem[] {
    return this.leitsysteme;
  }

  updateLeitsystem(leitsystem: Leitsystem) {
    let data = this.leitsysteme.find(x => x.identifier === leitsystem.identifier);

    if (data == null) {
      this.leitsysteme.push(leitsystem);
      this.leitsystem.next(leitsystem);
    } else {
      data.state1 = leitsystem.state1;
      data.state2 = leitsystem.state2;
      data.state3 = leitsystem.state3;
      this.leitsystem.next(data);
    }
  }

  setLeitsystem(leitsystem) {
    this.socketService.socketEmit('lst', { cmd: 'setLeitsystem', data: {
      identifier: leitsystem.identifier,
      state1: leitsystem.state1,
      state2: leitsystem.state2,
      state3: leitsystem.state3
    } });
  }
}
