<div #dialog>
  <div [style.height]="height + 'px'">
    <div id="map"></div>
  </div>
</div>

<p-dialog header="{{'MAP.LEITSYSTEM' | translate}}" [(visible)]="displayLeitsystemDialog"
  [modal]="true" [resizable]="false" [appendTo]="dialog" [draggable]="false" [style]="{width: '50%'}">
  <div *ngIf="selectedLeitsystem != null" class="grid p-fluid">
    <div class="col-4">
      <p-dropdown [options]="leitsystemStates" [(ngModel)]="selectedLeitsystem.state1" [showClear]="true" appendTo="body"></p-dropdown>
    </div>
    <div class="col-4">
      <p-dropdown [options]="leitsystemStates" [(ngModel)]="selectedLeitsystem.state2" [showClear]="true" appendTo="body"></p-dropdown>
    </div>
    <div class="col-4">
      <p-dropdown [options]="leitsystemStates" [(ngModel)]="selectedLeitsystem.state3" [showClear]="true" appendTo="body"></p-dropdown>
    </div>
    <div class="col-12">
      <button pButton type="button" label="{{ 'MAP.LEITSYSTEM-APPLY' | translate }}" (click)="applyLeitsystem()"></button>
    </div>
  </div>
</p-dialog>