import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { SocketIOService } from './socket-io.service';
import { VariableService } from './variable.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

export interface EventX {
  id: number;
  text: string;
  posX: number;
  posY: number;
  startTime: Date;
  blockedByLst: string;
  keyword: any;
  signal: boolean;
  state: number;
  vehicles: string[];
  messages: string[];
  createdBy: string;
  parentEvent: number;
  childEvents: number[];
  endTime: Date;
  location: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private events: EventX[] = [];
  private event = new Subject<EventX>();
  private missions: any[] = [];
  private missions2: any[] = [];
  private selectedEvent = 0;
  private countSelectedVehicles = 0;

  private _selectedVehicles = [];
  public selectedVehicles = new BehaviorSubject<string[]>([]);

  constructor(
    private socketService: SocketIOService,
    private variableService: VariableService,
    private confirmationService: ConfirmationService,
    private variableSerivce: VariableService,
    private translateService: TranslateService
  ) { }

  public getUpdate(): Observable<EventX> {
    return this.event.asObservable();
  }

  resetEvents() {
    this.events = [];
  }

  getEvents(): EventX[] {
    return this.events;
  }

  updateEvent(event: EventX) {
    const data = this.events.find(x => x.id === event.id);
    // if(event.text.includes("Adv.")) return;
    if (data == null) {
      event.keyword = (typeof event.keyword == 'string' ? [event.keyword] : event.keyword);
      this.events.push(event);
      this.event.next(event);
    } else {
      if (event.text !== undefined && event.text !== data.text) {
        data.text = event.text;
      }

      if (event.posX !== undefined && event.posX !== data.posX) {
        data.posX = event.posX;
      }

      if (event.posY !== undefined && event.posY !== data.posY) {
        data.posY = event.posY;
      }

      if (event.startTime !== undefined && event.startTime !== data.startTime) {
        data.startTime = event.startTime;
      }

      if (event.blockedByLst !== undefined && event.blockedByLst !== data.blockedByLst) {
        data.blockedByLst = event.blockedByLst;
      }

      if (event.keyword !== undefined && event.keyword !== data.keyword) {
        data.keyword = (typeof event.keyword == 'string' ? Array[event.keyword] : event.keyword);
      }

      if (event.signal !== undefined && event.signal !== data.signal) {
        data.signal = event.signal;
      }

      if (event.state !== undefined && event.state !== data.state) {
        data.state = event.state;
      }

      if (event.vehicles !== undefined && event.vehicles !== data.vehicles) {
        data.vehicles = event.vehicles;
      }

      if (event.messages !== undefined && event.messages !== data.messages) {
        data.messages = event.messages;
      }

      if (event.createdBy !== undefined && event.createdBy !== data.createdBy) {
        data.createdBy = event.createdBy;
      }

      if (event.parentEvent !== undefined && event.parentEvent !== data.parentEvent) {
        data.parentEvent = event.parentEvent;
      }

      if (event.childEvents !== undefined && event.childEvents !== data.childEvents) {
        data.childEvents = event.childEvents;
      }

      if (event.endTime !== undefined && event.endTime !== data.endTime) {
        data.endTime = event.endTime;
      }

      if (event.location !== undefined && event.location !== data.location) {
        data.location = event.location;
      }

      this.event.next(data);
    }
  }

  callSpecialist(eventId: number, type: number) {
    this.socketService.socketEmit('lst', { cmd: 'callSpecialist', data: { eventId, type } });
  }

  forceFinish(eventId: number) {
    this.socketService.socketEmit('lst', { cmd: 'forceFinishEvent', data: { eventId } });
  }

  forceFinishSelectedEvent() {
    this.forceFinish(this.selectedEvent)
  }

  closeSelectedEvent() {
    this.socketService.socketEmit('lst', { cmd: 'closeEvent', data: { eventId: this.selectedEvent } });
  }

  close(eventId: number) {
    this.socketService.socketEmit('lst', { cmd: 'closeEvent', data: { eventId } });
  }

  forceUnblock(eventId: number) {
    this.socketService.socketEmit('lst', { cmd: 'forceUnblockEvent', data: { eventId } });
  }

  addChildEvent(eventId: number) {
    this.socketService.socketEmit('lst', { cmd: 'addChildEvent', data: { eventId } });
  }

  // Missions
  addMissions(missions) {
    this.missions = [];
    this.missions2 = missions;
    const categories = [];
    missions.forEach(mission => {
      // this.missions.push({ name: mission.name, event: mission.event, category: mission.category });

      const cat = categories.find(x => x === mission.category);
      if (cat == null) {
        categories.push(mission.category);
      }
    });

    categories.forEach(cat => {
      const item = { label: cat, items: [] };
      missions.forEach(mission => {
        if (mission.category === cat) {
          item.items.push({
            label: mission.name,
            command: () => {
              this.generateEvent(mission.event);
            }
          });
        }
      });

      this.missions.push(item);
    });
    this.missions.push({ label: this.translateService.instant("EVENT.GENERATE-RANDOM-EVENT"), command: () => this.generateRandomEvent() });
    this.missions.push({ separator: true });
    this.missions.push({ label: this.translateService.instant("EVENT.GENERATE-DUMMY-EVENT"), command: (event) => this.createDummyEvent() });
    // this.missions.push({
    //   label: "Custom",
    //   command: () => {
    //     this.dialogService.open(CustomEventGeneratorComponent, { header: 'Custom Event Generator', width: '70%', height: '70%' });
    //   }
    // });
  }

  getMissions() {
    return this.missions;
  }

  generateEvent(eventName: string) {
    this.socketService.socketEmit('lst', { cmd: 'generateEvent', data: { event: eventName } });
  }

  generateRandomEvent() {
    const eventNumber = Math.floor(Math.random() * this.missions2.length - 1);
    this.socketService.socketEmit('lst', { cmd: 'generateEvent', data: { event: this.missions2[eventNumber].event } });
    // this.socketService.socketEmit('lst', { cmd: 'generateEvent', data: { event: "berlin_wuppertal_edition_freeplay_eventpool/CrazyDemonstration" } });
  }

  // Frontend Stuff
  selectEvent(eventId) {
    if (eventId !== this.selectedEvent) {
      this.selectedEvent = eventId;
      this.variableService.selectedEvent.next(eventId);
      this.clearSelectedVehicles();
      this.socketService.socketEmit('lst', { cmd: 'selectEvent', data: { eventId } });
    }
  }

  getSelectedEventId() {
    return this.selectedEvent
  }

  createDummyEvent() {
    this.variableSerivce.displayDummyMarker.next(true);
  }

  addVehicleToEvent(vehicleName) {
    this.countSelectedVehicles += 1;
    this.variableService.draggedVehicle.next(vehicleName);
    this.addSelectedVehicle(vehicleName);
    this.socketService.socketEmit('lst', { cmd: 'addVehicleToEvent', data: { vehicleName } });
  }

  removeSelectedVehicle(vehicleName) {
    this._selectedVehicles = this._selectedVehicles.filter(x => x !== vehicleName);
    this.selectedVehicles.next(this._selectedVehicles);
  }

  addSelectedVehicle(vehicleName) {
    this._selectedVehicles.push(vehicleName);
    this.selectedVehicles.next(this._selectedVehicles);
  }

  clearSelectedVehicles() {
    this._selectedVehicles = [];
    this.selectedVehicles.next(this._selectedVehicles);
  }

  setInjuredPeopleCount(eventId: number, count: number) {
    this.socketService.socketEmit('lst', { cmd: 'setInjuredPeopleCount', data: { eventId, count } });
  }
}
