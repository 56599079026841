import { Component, HostListener, OnInit } from '@angular/core';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { BmaService } from 'src/app/services/bma.service';
import { EventService } from 'src/app/services/event.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { StationService } from 'src/app/services/station.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { VariableService, User } from 'src/app/services/variable.service';
import { KeywordService } from 'src/app/services/keyword.service';
import * as cryptoJS from 'crypto-js';
import { StorageService } from 'src/app/services/storage.service';
import { LeitsystemService } from 'src/app/services/leitsystem.service';

@Component({
  selector: 'app-socket-handler',
  template: ''
})
export class SocketHandlerComponent implements OnInit {

  constructor(
    private socketService: SocketIOService,
    private storageService: StorageService,

    private keywordService: KeywordService,
    private bmaService: BmaService,
    private eventService: EventService,
    private hospitalService: HospitalService,
    private leitsystemService: LeitsystemService,
    private stationService: StationService,
    private vehicleService: VehicleService,

    private variableService: VariableService
  ) { }

  async ngOnInit(): Promise<void> {
    // Communication between frontend and parent window
    window.parent.postMessage({ type: 'auth' }, "*");
    this.keywordService.setClientKeywords(await this.storageService.get('aao'));
    this.keywordService.setClientCategories(await this.storageService.get('aao_categories'));
    this.socketService.socketEmit('misc', { cmd: 'getDefaultAao', data: "WerDasLiestIstDumm" });
    this.socketService.socketEmit('misc', { cmd: 'getStatusMonitorLayouts', data: "DSDS - DummeMachenDummeSachen" });

    // SocketIO
    this.socketService.socketListener().subscribe(payload => {
      if (typeof payload != 'object') {
        payload = JSON.parse(payload);
      }
      console.log(payload)
      switch (payload.cmd) {
        // case 'createAaos': {
        //   this.keywordService.resetKeywords();
        //   payload.data.forEach(aao => {
        //     this.keywordService.updateAao(aao);
        //   });
        //   break;
        // }

        case 'createBmas': {
          this.bmaService.resetBmas();
          payload.data.forEach(bma => {
            this.bmaService.updateBma(bma);
          });
          break;
        }

        case 'updateBma': {
          this.bmaService.updateBma(payload.data);
          break;
        }

        case 'createEvents': {
          this.eventService.resetEvents();
          payload.data.forEach(event => {
            this.eventService.updateEvent(event);
          });
          break;
        }

        case 'addEvent': {
          this.eventService.updateEvent(payload.data);
          break;
        }

        case 'updateEvent': {
          this.eventService.updateEvent(payload.data);
          break;
        }

        case 'createHospitals': {
          this.hospitalService.resetHospitals();
          payload.data.forEach(hospital => {
            this.hospitalService.updateHospital(hospital);
          });
          break;
        }

        case 'updateHospital': {
          this.hospitalService.updateHospital(payload.data);
          break;
        }

        case 'createLeitsysteme': {
          this.leitsystemService.resetLeitsysteme();
          payload.data.forEach(leitsystem => {
            this.leitsystemService.updateLeitsystem(leitsystem);
          });
          break;
        }

        case 'updateLeitsystem': {
          this.leitsystemService.updateLeitsystem(payload.data);
          break;
        }

        case 'createStations': {
          this.stationService.resetStations();
          payload.data.forEach(station => {
            this.stationService.updateStation(station);
          });
          break;
        }

        case 'createVehicles': {
          payload.data.forEach(vehicle => {
            this.vehicleService.updateVehicle(vehicle);
          });
          break;
        }

        case 'updateVehicle': {
          this.vehicleService.updateVehicle(payload.data);
          break;
        }

        case 'createMissions': {
          this.eventService.addMissions(payload.data);
          break;
        }

        case 'authSessionResponse': {
          if (payload.data['error'] === 0) {
            setTimeout(() => { this.variableService.visibleModule.next('game'); }, 500);
          }
          break;
        }

        case 'authClientResponse': {
          if (payload.data.error === 0) {
            const user = {
              name: payload.data.msg.username,
              isAdmin: payload.data.msg.isAdmin,
              registrationTime: payload.data.registrationTime,
              error: 0
            };

            this.variableService.user.next(user);
            this.socketService.socketEmit('lst', { cmd: 'getSessions', data: '0' });
          } else {
            const user = {
              name: null,
              isAdmin: false,
              registrationTime: null,
              error: payload.data.error
            };

            this.variableService.user.next(user);
          }
          break;
        }

        case 'logoutResponse': {
          if (payload.data.error === 0) {
            // const user = {
            //   name: null,
            //   isAdmin: false,
            //   registrationTime: null,
            //   error: 5
            // };

            // this.variableService.user.next(user);
          }
          break;
        }

        case 'loginChallenge': {
          const autoLogin = JSON.parse(localStorage.getItem('lst_autoLogin'));
          if (autoLogin != null) {
            const hash = cryptoJS.HmacSHA1(payload.data.challenge, autoLogin.challenge);
            this.socketService.autoLoginClient(autoLogin.username, hash.toString(cryptoJS.enc.Hex));
          }
          break;
        }

        case 'selectEvent': {
          this.variableService.selectedEvent.next(payload.data.eventId);
          break;
        }

        case 'addVehicleToEvent': {
          this.variableService.draggedVehicle.next(payload.data.vehicleName);
          this.eventService.addSelectedVehicle(payload.data.vehicleName);
          break;
        }

        case 'updateDefaultAao': {
          this.keywordService.setDefaultKeywords(payload.data);
          break;
        }

        case 'statusMonitorLayouts': {
          this.variableService.defaultStatusMonitorLayouts.next(payload.data);
          break;
        }

        case 'updateTime': {
          this.variableService.gameTime.next(payload.data);
          break;
        }

        case 'updateWeather': {
          this.variableService.gameWeather.next(payload.data.weather);
          break;
        }

        case 'newChatMessage': {
          this.variableService.chat.next(payload.data);
          break;
        }

        case 'setMapAssetId': {
          this.variableService.mapAssetId.next(payload.data);
          break;
        }
      }
    });
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin !== "https://emergency-luedenscheid.de" && event.origin !== "https://wcf.web.slnt.dev")
      return;

    switch (event.data.type) {
      case 'auth':
        const auth = JSON.parse(atob(event.data.data));
        this.socketService.authClient(auth);
        break;
      case 'aao':
        if (event.data.data != null) {
          this.keywordService.setKeywords(JSON.parse(event.data.data));
        }
        break;
      case 'data':
        this.storageService.newData(event.data);
        break;
    }
  }

}
