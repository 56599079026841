import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService, EventX } from 'src/app/services/event.service';
import { Settings, SettingsService } from 'src/app/services/settings.service';
import { Vehicle, VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-event-statistics',
  templateUrl: './event-statistics.component.html'
})
export class EventStatisticsComponent implements OnChanges {
  @Input() selectedEvent: EventX = null;
  vehicles: Vehicle[] = [];
  stats: any[] = [];
  vehicleSubscription: Subscription;

  allVehiclesCount: number = 0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private eventService: EventService,
    private settingsService: SettingsService,
    private vehicleService: VehicleService
  ) {
    this.settingsService.settings.subscribe(settings => {
      if (settings.eventView.stats == null) return;
      this.stats = settings.eventView.stats.map(x => {
        return { name: x.name, type: x.value, count: 0 }
      })
      if (this.selectedEvent != null) this.updateCounts();
      // this.changeDetectorRef.detectChanges();
    })

    this.vehicles = this.vehicleService.getVehicles();
    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      let oldStatus = null;
      if (data == null) {
        this.vehicles.push(vehicle);
      } else {
        data = vehicle;
      }

    });
  }

  ngOnChanges(change): void {
    console.log("Changed Event", change)
    this.updateCounts();
  }

  updateCounts() {
    if (this.selectedEvent == null) return;

    const vehicles = this.vehicles.filter(v => v.currentEvent == this.selectedEvent.id && String(v.status) == "4");

    this.stats.forEach(s => {
      s.count = vehicles.filter(v => v.type2.includes(s.type)).length;
    })

    this.allVehiclesCount = vehicles.length;
    console.log("FZS", this.stats, vehicles);
    this.changeDetectorRef.detectChanges();
  }

}
