import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Vehicle } from './vehicle.service';

export interface User {
  name: string;
  isAdmin: boolean;
  registrationTime: Date;
  error: number;
}

export interface Time {
  hour: number;
  minute: number;
  dayTime: string;
}

export interface FastButton {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class VariableService {
  public visibleModule = new BehaviorSubject<any>('start');

  public user = new BehaviorSubject<User>({
    name: null,
    isAdmin: false,
    registrationTime: null,
    error: 5
  });
  // public game = new BehaviorSubject<object>({
  //   time: null,
  //   weather: null
  // });

  // Game Component
  public _editMode = false;
  public editMode = new BehaviorSubject<boolean>(false);
  public toggleEditMode() {
    this._editMode = !this._editMode;
    this.editMode.next(this._editMode);
  }
  public addComponent = new Subject<string>();
  public dashboardCmd = new Subject<string>();

  public selectedEvent = new BehaviorSubject<number>(0);
  public displayDummyMarker = new BehaviorSubject<boolean>(false);
  public displayPingMarker = new BehaviorSubject<boolean>(false);
  public dummyMarkerPosition = new BehaviorSubject<any>([0, 0]);
  public draggedVehicle = new Subject<any>();
  public defaultStatusMonitorLayouts = new BehaviorSubject<any>([]);

  // Game
  public gameTime = new BehaviorSubject<Time>({
    hour: null,
    minute: null,
    dayTime: null
  });
  public gameWeather = new Subject<string>();
  public chat = new Subject<any>();
  public mapAssetId = new BehaviorSubject<string>(null);
}
