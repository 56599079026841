import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { HospitalService, Hospital } from 'src/app/services/hospital.service';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
})
export class HospitalsComponent implements OnInit, OnDestroy {
  @Input() height: number;

  hospitalSubscription: Subscription;
  hospitals: Hospital[] = [];

  cols: any[];

  constructor(
    private hospitalService: HospitalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.hospitals = this.hospitalService.getHospitals();
    this.hospitalSubscription = this.hospitalService.getUpdate().subscribe(hospital => {
      let data = this.hospitals.find(x => x.name === hospital.name);
      if (data == null) {
        this.hospitals.push(hospital);
      } else {
        data = hospital;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'name', header: 'Krankenhaus', width: '40%' },
      { field: 'burn', header: 'Brand', width: '10%' },
      { field: 'trauma', header: 'Trauma', width: '10%' },
      { field: 'surgical', header: 'Surgical', width: '10%' },
      { field: 'neurological', header: 'Neorologie', width: '10%' },
      { field: 'internistic', header: 'Internistic', width: '10%' },
      { field: 'isolation', header: 'Isolation', width: '10%' }
    ];
  }

  ngOnDestroy(): void {
    this.hospitalSubscription.unsubscribe();
  }

}
