import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Bma {
  name: string;
  eventId: number;
}

@Injectable({
  providedIn: 'root'
})
export class BmaService {
  private bmas: Bma[] = [];
  private bma = new Subject<Bma>();

  public getUpdate(): Observable<Bma> {
    return this.bma.asObservable();
  }

  resetBmas() {
    this.bmas = [];
  }

  getBmas(): Bma[] {
    return this.bmas;
  }

  updateBma(bma: Bma) {
    const data = this.bmas.find(x => x.name === bma.name);
    if (data == null) {
      this.bmas.push(bma);
    } else {
      if (bma.eventId !== undefined && bma.eventId !== data.eventId) {
        data.eventId = bma.eventId;
      }
    }
    this.bma.next(bma);
  }
}
