import { HostListener, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private message = new Subject<any>();

  constructor() { }

  getMessage(): Observable<any> {
    return this.message.asObservable();
  }

  newData(data) {
    this.message.next(data);
  }


  get(key: string): Promise<any> {
    let timeout;
    window.parent.postMessage({ type: 'getData', key }, "*");

    return new Promise((resolve, reject) => {
      const messageSubscription = this.getMessage().subscribe(msg => {
        if (msg.key == key) {
          try {
            msg.data = JSON.parse(msg.data);
          } catch { }
          clearTimeout(timeout);
          resolve(msg.data);
        }
      });

      timeout = setTimeout(() => {
        console.error("timeout waiting for msg", key)
        reject(null);
        messageSubscription.unsubscribe();
      }, 1000 * 10);
    })
  }

  set(key: string, value: any): void {
    if(typeof value == 'object') {
      value = JSON.stringify(value);
    }
    window.parent.postMessage({ type: 'setData', key, data: value }, "*");
  }

  remove(key: string): void {
    window.parent.postMessage({ type: 'removeData', key }, "*");
  }
}
