<div style="height: calc(100vh - 32px)">
  <gridster [options]="options">
    <gridster-item [item]="item" *ngFor="let item of dashboard" class="drag-content" id="grid_{{item.component}}">
      <p-panel class="{{editMode ? 'drag-handler' : ''}}">

        <ng-template pTemplate="header">
          <span class="p-panel-title">{{item.component ? ((overwriteLangVariable(item.component.toUpperCase()) +
            '.TITLE') | translate) : 'Fehler :('}}</span>
          <p-tabMenu [model]="item.tabs" [style]="{'position': 'absolute', 'left': '20%', 'top': '-3px'}"
            *ngIf="item.tabs && item.tabs.length > 1" [activeItem]="item.tab"></p-tabMenu>
        </ng-template>

        <ng-template pTemplate="icons">
          <button pButton type="button" *ngIf="editMode" [style]="{'float':'right', 'margin-left': '5px'}"
            icon="pi pi-trash" class="p-panel-header-icon p-link" (click)="removeItem($event, item)"></button>

          <button pButton type="button" *ngFor="let button of item.actions"
            [style]="{'float':'right', 'margin-left': '5px'}" [icon]="button.icon" class="p-panel-header-icon p-link"
            (click)="item.action = {action: 'button', data: button.action}"
            pTooltip="{{(overwriteLangVariable(item.component.toUpperCase()) + '.ACTIONS.' + button.action.toUpperCase()) | translate}}"
            tooltipPosition="top" showDelay="1000" hideDelay="500"></button>

          <button pButton type="button" *ngIf="item.component=='eventView'"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-lock-open" class="p-panel-header-icon p-link"
            (click)="releaseEvent()" pTooltip="{{'EVENT-VIEW.ACTIONS.RELEASE-EVENT' | translate}}" tooltipPosition="top"
            showDelay="1000" hideDelay="500"></button>
          <button pButton type="button" *ngIf="item.component=='eventView'"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-check" class="p-panel-header-icon p-link"
            (click)="closeEvent()" pTooltip="{{'EVENT-VIEW.ACTIONS.CLOSE-EVENT' | translate}}" tooltipPosition="top"
            showDelay="1000" hideDelay="500"></button>
          <button pButton type="button" *ngIf="item.component=='eventView'"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-times" class="p-panel-header-icon p-link"
            (click)="cancelEvent()" pTooltip="{{'EVENT.FORCE-FINISH' | translate}}" tooltipPosition="top"
            showDelay="1000" hideDelay="500"></button>
          <button pButton type="button"
            *ngIf="item.component=='map' && (displayDummyMarker == true || displayPingMarker == true)"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-plus" class="p-panel-header-icon p-link"
            (click)="createDummyEvent()" pTooltip="{{'MAP.ACTIONS.DUMMY-MAKER' | translate}}" tooltipPosition="left"
            showDelay="1000" hideDelay="500"></button>
          <button pButton type="button"
            *ngIf="item.component=='map' && displayDummyMarker == false && displayPingMarker == false"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-circle-off" class="p-panel-header-icon p-link"
            (click)="showPingMarker()" pTooltip="{{'MAP.ACTIONS.PING-MAKER' | translate}}" tooltipPosition="left"
            showDelay="1000" hideDelay="500"></button>
          <!-- <button pButton type="button" *ngIf="item.component=='status-monitor2'"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-pencil" class="p-panel-header-icon p-link"
            (click)="item.editable ? item.editable = false : item.editable = true"></button>
          <button pButton type="button" *ngIf="item.component=='status-monitor2'"
            [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-save" class="p-panel-header-icon p-link"
            (click)="item.save ? !item.save = false : item.save = true"></button> -->
        </ng-template>

        <div [style]="{width: '100%', height: (item.height - 33) + 'px'}">
          <div *ngIf="item.component=='bma'">
            <app-bma [height]="item.height - 33"></app-bma>
          </div>
          <div *ngIf="item.component=='call-requests'">
            <app-call-requests [height]="item.height - 33"></app-call-requests>
          </div>
          <div *ngIf="item.component=='chat'">
            <app-chat [height]="item.height - 33"></app-chat>
          </div>
          <div *ngIf="item.component=='eventList'">
            <app-event-list [height]="item.height - 33"></app-event-list>
          </div>
          <div *ngIf="item.component=='eventView'">
            <app-event-view [height]="item.height - 33"></app-event-view>
          </div>
          <div *ngIf="item.component=='hospitals'">
            <app-hospitals [height]="item.height - 33"></app-hospitals>
          </div>
          <div *ngIf="item.component=='map'">
            <app-map [height]="item.height - 33"></app-map>
          </div>
          <div *ngIf="item.component=='status-logger'">
            <app-status-logger></app-status-logger>
          </div>
          <div *ngIf="item.component=='status-monitor'" [style]="{width: '100%', height: (item.height - 33) + 'px'}">
            <app-status-monitor [inputData]="item.data" (outputData)="receiveDashboardItemData(item, $event)">
            </app-status-monitor>
          </div>
          <div *ngIf="item.component=='status-monitor2'" [style]="{width: '100%', height: (item.height - 33) + 'px'}">
            <app-status-monitor2 [height]="item.height - 33" [action]="item.action" (actions)="item.actions=$event"
              (tabs)="item.tabs=$event"></app-status-monitor2>
          </div>
        </div>
      </p-panel>
    </gridster-item>
  </gridster>
</div>

<p-contextMenu #specialContextMenu [model]="items" appendTo="body"></p-contextMenu>

<p-dialog header="Layout speichern" [(visible)]="displaySaveLayout" modal="true" [style]="{width: '50%'}">
  <div class="p-fluid">
    <input type="text" pInputText [(ngModel)]="layoutName" placeholder="Wie soll das Layout heißen?"
      *ngIf="!savedLayouts || savedLayouts.length == 0" />

      <p-autoComplete *ngIf="savedLayouts && savedLayouts.length > 0" [(ngModel)]="layoutName" [suggestions]="filteredLayoutSingle"
      (completeMethod)="filterLayoutsSingle($event)" [size]="30" [minLength]="2" [dropdown]="true" appendTo="body">
    </p-autoComplete>
  </div>

  <ng-template pTemplate="footer">
    <button pButton type="button" label="Speichern" class="p-fluid p-button-success"
      (click)="saveDashboardLayout()"></button>
  </ng-template>
</p-dialog>

<p-dialog header="Wähle dein Layout" [(visible)]="displayLoadLayout" modal="true" [style]="{width: '50%'}">
  <p *ngIf="!savedLayouts || savedLayouts.length == 0">Kein Layout gefunden. Du musst zuerst Layouts speichern ;)</p>
  <p-table [value]="savedLayouts" selectionMode="single" (onRowSelect)="loadDashboardLayout($event)"
    responsiveLayout="scroll" *ngIf="savedLayouts && savedLayouts.length > 0">
    <ng-template pTemplate="body" let-layout>
      <tr [pSelectableRow]="layout">
        <td>{{layout.id}}</td>
        <td>{{layout.name}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>