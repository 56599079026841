import { Component, OnInit, OnChanges, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StorageService } from 'src/app/services/storage.service';
import { Keyword, KeywordCategory, KeywordService } from '../../services/keyword.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-aao-editor',
  templateUrl: './aao-editor.component.html'
})
export class AaoEditorComponent implements OnInit, OnChanges {
  loading = false;
  math = Math;

  keywords: Keyword[] = [];
  selectedKeyword: Keyword;
  editKeyword: any = { name: '', sosi: true, increase: '', vehicles: [], vehicleList: [] };
  isNewKeyword = true;
  filteredKeywordSingle = [];

  categories: KeywordCategory[] = [];
  selectedCategory: KeywordCategory;
  editCategory: any = { name: '', color: '' };
  isNewCategory = true;

  vehicles = {};
  addContextMenuItems = [];
  restrictions = {};
  restrictions2 = [];
  organizations = [{ label: 'Feuerwehr', value: 'Feuerwehr' }, { label: 'Rettungsdienst', value: 'Rettungsdienst' }, { label: 'Polizei', value: 'Polizei' }];
  sosiStates = [{ label: 'Mit Sondersignal', value: true }, { label: 'Ohne Sondersignal', value: false }];
  colors = [
    { label: 'Rot', value: 'red' },
    { label: 'Blau', value: 'blue' },
    { label: 'Grün', value: 'green' },
    { label: 'Gelb', value: 'yellow' },
    { label: 'Orange', value: 'orange' },
    { label: 'Lila', value: 'purple' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Magenta', value: 'magenta' }
  ];

  showExportDialog = false;
  exportString = '';
  showImportDialog = false;
  importString = '';

  tabItems = [
    {
      label: 'Stichwörter', command: () => {
        this.activeTab = this.tabItems[0]
      }
    },
    {
      label: 'Kategorien', command: () => {
        this.activeTab = this.tabItems[1]
      }
    }
  ];
  activeTab = this.tabItems[0];

  constructor(
    private keywordService: KeywordService,
    private confirmationService: ConfirmationService,
    private dialogReference: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private storageService: StorageService,
    public translateService: TranslateService
  ) {
    this.dialogConfig.showHeader = false;
    this.dialogConfig.dismissableMask = true;
    this.dialogConfig.width = '85%';
  }

  ngOnInit(): void {
    this.loadVehicleTypes();
    this.loadRestrictionTypes();
    this.keywords = this.keywordService.getKeywords();
    this.categories = this.keywordService.getCategories();
  }

  ngOnChanges() {
    if (this.keywords.length > 0) {
      this.loading = true;
    }
  }

  newKeyword() {
    this.editKeyword = { name: '', sosi: true, vehicles: [], vehicleList: [] };
    this.isNewKeyword = true;
  }

  selectKeyword() {
    this.isNewKeyword = false;
    this.editKeyword = { ...this.selectedKeyword };
    this.editKeyword.vehicleList = [];
    this.editKeyword.vehicles.forEach(vehicle => {
      const veh = vehicle.split('_');
      this.editKeyword.vehicleList.push({ type: veh[0], restriction: veh[1], id: Math.floor((Math.random() * 100000) + 1) })
    })
  }

  unselectKeyword() {
    this.newKeyword();
  }

  saveKeyword() {
    let vehicles = [];
    this.editKeyword.vehicleList.forEach(vehicle => {
      if (vehicle.restriction != null) {
        vehicles.push(vehicle.type + '_' + vehicle.restriction);
      } else {
        vehicles.push(vehicle.type);
      }
    });

    if (this.isNewKeyword) {
      const keyword = { name: this.editKeyword.name, category: this.editKeyword.category, sosi: this.editKeyword.sosi, increase: this.editKeyword.increase, vehicles };
      this.keywords.push(keyword);
      this.selectedKeyword = keyword;
      this.isNewKeyword = false;
    } else {
      this.selectedKeyword.name = this.editKeyword.name;
      this.selectedKeyword.category = this.editKeyword.category;
      this.selectedKeyword.sosi = this.editKeyword.sosi;
      this.selectedKeyword.increase = this.editKeyword.increase;
      this.selectedKeyword.vehicles = vehicles;
    }

    this.keywords = this.keywords.filter(x => x.name !== '');

    this.storageService.set('aao', this.keywords);
    this.keywordService.setKeywords(this.keywords);
  }

  saveKeywords() {
    this.storageService.set('aao', this.keywords);
    this.keywordService.setKeywords(this.keywords);
  }

  duplicateKeyword() {
    this.selectKeyword();
    this.isNewKeyword = true;
    this.editKeyword.name = this.editKeyword.name + '-KOPIE';
    this.saveKeyword();
  }

  deleteAllKeywords() {
    this.confirmationService.confirm({
      message: this.translateService.instant('AAO-EDITOR.DELETE-CONFIRMATION'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.keywords = [];
        this.newKeyword();
        this.storageService.set('aao', this.keywords);
        this.keywordService.setKeywords(this.keywords);
        6
      },
      acceptLabel: this.translateService.instant('OTHER.YES'),
      rejectLabel: this.translateService.instant('OTHER.NO')
    });
  }

  newCategory() {
    this.editCategory = { name: '', sosi: true, vehicles: [], vehicleList: [], category: null };
    this.isNewCategory = true;
  }

  selectCategory() {
    this.isNewCategory = false;
    this.editCategory = { ...this.selectedCategory };
  }

  unselectCategory() {
    this.newCategory();
  }

  saveCategory() {
    if (this.isNewCategory) {
      const category = {
        name: this.editCategory.name, color: this.editCategory.color
      };
      this.categories.push(category);
      this.selectedCategory = category;
      this.isNewCategory = false;
    } else {
      this.selectedCategory.name = this.editCategory.name;
      this.selectedCategory.color = this.editCategory.color;
    }

    this.categories = this.categories.filter(x => x.name !== '');

    this.storageService.set('aao_categories', this.categories);
    this.keywordService.setCategories(this.categories);
  }

  deleteCategory(name) {
    this.categories = this.categories.filter(val => val.name !== name);
    this.newCategory();
  }

  loadVehicleTypes() {
    this.vehicles = this.keywordService.getVehicleTypes();

    this.addContextMenuItems = [
      {
        label: this.translateService.instant('DEPARTMENTS.FIRE'),
        items: [
          { label: this.translateService.instant('VEHICLE-TYPES.HLF'), command: () => { this.editKeyword.vehicleList.push({ type: 'HLF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.LF'), command: () => { this.editKeyword.vehicleList.push({ type: 'LF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.DLK'), command: () => { this.editKeyword.vehicleList.push({ type: 'DLK', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.TLF'), command: () => { this.editKeyword.vehicleList.push({ type: 'TLF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.GTLF'), command: () => { this.editKeyword.vehicleList.push({ type: 'GTLF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.MTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'MTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.RW'), command: () => { this.editKeyword.vehicleList.push({ type: 'RW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.SW2000'), command: () => { this.editKeyword.vehicleList.push({ type: 'SW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.KRAN'), command: () => { this.editKeyword.vehicleList.push({ type: 'KRAN', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.OSF'), command: () => { this.editKeyword.vehicleList.push({ type: 'OSF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          {
            label: this.translateService.instant('VEHICLE-TYPES.ELW'),
            items: [
              { label: this.translateService.instant('VEHICLE-TYPES.ELW-C'), command: () => { this.editKeyword.vehicleList.push({ type: 'ELW-C', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.ELW-B'), command: () => { this.editKeyword.vehicleList.push({ type: 'ELW-B', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.ELW-A'), command: () => { this.editKeyword.vehicleList.push({ type: 'ELW-A', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.ELW'), command: () => { this.editKeyword.vehicleList.push({ type: 'ELW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.ELW2'), command: () => { this.editKeyword.vehicleList.push({ type: 'ELW2', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.KDOW'), command: () => { this.editKeyword.vehicleList.push({ type: 'KDOW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
            ]
          },
          {
            label: this.translateService.instant('VEHICLE-TYPES.AB'),
            items: [
              { label: this.translateService.instant('VEHICLE-TYPES.AB-ATS'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-ATS', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-DEKON'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-DEKON', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-ELW'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-ELW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-MANV'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-MANV', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-MULDE'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-MULDE', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-RUEST'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-RUEST', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-SOZIAL'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-SOZIAL', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.AB-WASSER'), command: () => { this.editKeyword.vehicleList.push({ type: 'AB-WASSER', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
            ]
          },
          {
            label: this.translateService.instant('VEHICLE-TYPES.GW'),
            items: [
              { label: this.translateService.instant('VEHICLE-TYPES.GW-ATS'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-ATS', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.DEKONP'), command: () => { this.editKeyword.vehicleList.push({ type: 'DEKONP', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.GW-RUEST'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-RUEST', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.GW-MESS'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-MESS', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.GW-TECH'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-T', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.GW-LOG'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-L', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
              { label: this.translateService.instant('VEHICLE-TYPES.GW-W'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-W', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
            ]
          },
        ]
      },
      {
        label: this.translateService.instant('DEPARTMENTS.EMERGENCY'),
        items: [
          { label: this.translateService.instant('VEHICLE-TYPES.RTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'RTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.NEF'), command: () => { this.editKeyword.vehicleList.push({ type: 'NEF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.BTCOMBI'), command: () => { this.editKeyword.vehicleList.push({ type: 'BTCOMBI', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.GRTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'GRTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.GW-SAN'), command: () => { this.editKeyword.vehicleList.push({ type: 'GW-SAN', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.HUND'), command: () => { this.editKeyword.vehicleList.push({ type: 'HUND', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.IRTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'IRTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.ITW'), command: () => { this.editKeyword.vehicleList.push({ type: 'ITW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.KTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'KTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.LNA'), command: () => { this.editKeyword.vehicleList.push({ type: 'LNA', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.NAW'), command: () => { this.editKeyword.vehicleList.push({ type: 'NAW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.PTZ'), command: () => { this.editKeyword.vehicleList.push({ type: 'PTZ', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.RTH'), command: () => { this.editKeyword.vehicleList.push({ type: 'RTH', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
        ]
      },
      {
        label: this.translateService.instant('DEPARTMENTS.POLICE'),
        items: [
          { label: this.translateService.instant('VEHICLE-TYPES.DROHNE'), command: () => { this.editKeyword.vehicleList.push({ type: 'DROHNE', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.FUSTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'FUSTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.FUSTW-MTW'), command: () => { this.editKeyword.vehicleList.push({ type: 'FUSTW-MTW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.HUMMEL'), command: () => { this.editKeyword.vehicleList.push({ type: 'HUMMEL', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.KMRD'), command: () => { this.editKeyword.vehicleList.push({ type: 'KMRD', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.SEK'), command: () => { this.editKeyword.vehicleList.push({ type: 'SEK', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.WAWE'), command: () => { this.editKeyword.vehicleList.push({ type: 'WAWE', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.HGRUKW'), command: () => { this.editKeyword.vehicleList.push({ type: 'HGRUKW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.GEFKW'), command: () => { this.editKeyword.vehicleList.push({ type: 'GEFKW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } }
        ]
      },
      {
        label: this.translateService.instant('DEPARTMENTS.TEC'),
        items: [
          { label: this.translateService.instant('VEHICLE-TYPES.STADTWERKE'), command: () => { this.editKeyword.vehicleList.push({ type: 'STADTWERKE', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.EKW'), command: () => { this.editKeyword.vehicleList.push({ type: 'EKW', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.BFU'), command: () => { this.editKeyword.vehicleList.push({ type: 'BFU', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.ASF'), command: () => { this.editKeyword.vehicleList.push({ type: 'ASF', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } },
          { label: this.translateService.instant('VEHICLE-TYPES.BESTATTER'), command: () => { this.editKeyword.vehicleList.push({ type: 'BESTATTER', restriction: null, id: Math.floor((Math.random() * 100000) + 1) }) } }

        ]
      }
    ];
  }

  loadRestrictionTypes() {
    this.restrictions2.push({ label: this.translateService.instant('AAO-EDITOR.NO-PRIO'), value: null })

    this.restrictions['BF'] = this.translateService.instant('AAO-EDITOR.PRIO-BF');
    this.restrictions2.push({ label: this.translateService.instant('AAO-EDITOR.PRIO-BF'), value: 'BF' });

    this.restrictions['FF'] = this.translateService.instant('AAO-EDITOR.PRIO-FF');
    this.restrictions2.push({ label: this.translateService.instant('AAO-EDITOR.PRIO-FF'), value: 'FF' });

    this.restrictions['WF'] = this.translateService.instant('AAO-EDITOR.PRIO-WF');
    this.restrictions2.push({ label: this.translateService.instant('AAO-EDITOR.PRIO-WF'), value: 'WF' });
  }

  deleteVehicle(id) {
    this.editKeyword.vehicleList = this.editKeyword.vehicleList.filter(val => val.id !== id);
  }

  deleteKeyword(name) {
    this.keywords = this.keywords.filter(val => val.name !== name);
    this.newKeyword();
  }

  openCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  openExportDialog() {
    this.exportString = this.b64EncodeUnicode(JSON.stringify(this.keywords));
    this.showExportDialog = true;
  }

  openImportDialog() {
    this.importString = '';
    this.showImportDialog = true;
  }

  resetKeywords() {
    this.confirmationService.confirm({
      message: this.translateService.instant('AAO-EDITOR.RESET-CONFIRMATION'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.newKeyword();
        this.keywords = this.keywordService.getDefaultKeywords();
        // localStorage.removeItem("lst_aao");
        // window.parent.postMessage({ type: 'resetAao' }, "*");
        this.storageService.remove('aao')
      },
      acceptLabel: this.translateService.instant('OTHER.YES'),
      rejectLabel: this.translateService.instant('OTHER.NO')
    });
  }

  importKeywords() {
    try {
      try {
        this.keywords = JSON.parse(this.b64DecodeUnicode(this.importString));
      } catch (error) {
        this.keywords = JSON.parse(atob(this.importString));
      }

      for (const keyword of this.keywords) {
        for (var i = 0; i < keyword.vehicles.length; i++) {
          if (keyword.vehicles[i] == 'GWW') keyword.vehicles[i] = 'GW-W';
          if (keyword.vehicles[i] == 'GWTECH') keyword.vehicles[i] = 'GW-T';
          if (keyword.vehicles[i] == 'DEKON') keyword.vehicles[i] = 'DEKONP';
          if (keyword.vehicles[i] == 'GW-TECH') keyword.vehicles[i] = 'GW-T';
          if (keyword.vehicles[i] == 'GW-LOG') keyword.vehicles[i] = 'GW-L';
          if (keyword.vehicles[i] == 'SW2000') keyword.vehicles[i] = 'SW';
        }
      }

    } catch (error) {
      this.confirmationService.confirm({
        message: 'Beim Importieren ist ein Fehler aufgetreten',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Okay',
        rejectVisible: false
      });
      console.log(error);
      return;
    }

    this.saveKeyword();
    this.showImportDialog = false;
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  closePicker() {
    this.dialogReference.close();
  }

  filterKeywordSingle(event) {
    const query = event.query;
    const filter = [];

    this.keywords.forEach(aao => {
      if (aao.name.toLowerCase().includes(query.toLowerCase())) {
        filter.push(aao.name);
      }
    });

    filter.sort();

    this.filteredKeywordSingle = filter;
  }

  changeTab(event) {
    console.log(event)
  }
}
