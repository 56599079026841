import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridsterModule } from 'angular-gridster2';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// PrimeNG
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ListboxModule } from 'primeng/listbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DragDropModule } from 'primeng/dragdrop';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BlockUIModule } from 'primeng/blockui';
import { TreeTableModule } from 'primeng/treetable';
import { ConfirmationService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { CaptchaModule } from 'primeng/captcha';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SliderModule } from 'primeng/slider';

// Misc
import { SafePipe } from './safe.pipe';

// Komponenten
import { AppComponent } from './app.component';
import { GameComponent } from './game/game.component';
import { MapComponent } from './components/map/map.component';
import { BmaComponent } from './components/bma/bma.component';
import { HospitalsComponent } from './components/hospitals/hospitals.component';
import { CallRequestsComponent } from './components/call-requests/call-requests.component';
import { StatusLoggerComponent } from './components/status-logger/status-logger.component';
import { StatusMonitorComponent } from './components/status-monitor/status-monitor.component';
import { EventViewComponent } from './components/event-view/event-view.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { TestComponent } from './components/test/test.component';
import { SocketHandlerComponent } from './components/socket-handler/socket-handler.component';
import { SessionPickerComponent } from './components/session-picker/session-picker.component';
import { VehicleContextMenuComponent } from './components/vehicle-context-menu/vehicle-context-menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AaoEditorComponent } from './components/aao-editor/aao-editor.component';
import { AppRoutingModule } from './app-routing.module';
import { ChatComponent } from './components/chat/chat.component';
import { MenuComponent } from './components/menu/menu.component';
import { StatusMonitor2Component } from './components/status-monitor2/status-monitor2.component';
import { EventStatisticsComponent } from './components/event-statistics/event-statistics.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    BmaComponent,
    HospitalsComponent,
    CallRequestsComponent,
    StatusLoggerComponent,
    StatusMonitorComponent,
    GameComponent,
    EventViewComponent,
    EventListComponent,
    TestComponent,
    SocketHandlerComponent,
    SessionPickerComponent,
    VehicleContextMenuComponent,
    SettingsComponent,
    SafePipe,
    AaoEditorComponent,
    ChatComponent,
    MenuComponent,
    StatusMonitor2Component,
    EventStatisticsComponent  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GridsterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    ButtonModule,
    TableModule,
    ScrollPanelModule,
    DialogModule,
    InputTextModule,
    ListboxModule,
    AutoCompleteModule,
    CheckboxModule,
    DragDropModule,
    DropdownModule,
    SelectButtonModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    ContextMenuModule,
    ConfirmDialogModule,
    MenuModule,
    MegaMenuModule,
    TieredMenuModule,
    BlockUIModule,
    TreeTableModule,
    InputTextareaModule,
    TooltipModule,
    CaptchaModule,
    MultiSelectModule,
    AppRoutingModule,
    ConfirmPopupModule,
    MenubarModule,
    TabMenuModule,
    TabViewModule,
    CardModule,
    InputNumberModule,
    SpeedDialModule,
    SplitButtonModule,
    ToggleButtonModule,
    SliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: navigator.language == "de-DE" ? "de" : "en"
    })
  ],
  providers: [ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
