import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CompactType, GridsterConfig, GridsterItem, GridsterItemComponent, GridsterPush, GridType, GridsterItemComponentInterface, DisplayGrid } from 'angular-gridster2';
import { StorageService } from '../services/storage.service';
import { VariableService } from '../services/variable.service';
import { MenuItem } from 'primeng/api';
import { EventService } from '../services/event.service';
import { SocketIOService } from '../services/socket-io.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GameComponent implements OnInit {
  sessionPicker = null;
  shouldRun = false;
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  itemToPush: GridsterItemComponent;
  items: MenuItem[];
  editMode = false;
  displayDummyMarker = false;
  displayPingMarker = false;
  dummyMarkerPosition = [0, 0];
  displaySaveLayout = false;
  layoutName = "";
  displayLoadLayout = false;
  savedLayouts = [];
  filteredLayoutSingle = [];

  constructor(
    private variableService: VariableService,
    private storageService: StorageService,
    private eventService: EventService,
    private socketService: SocketIOService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.options = {
      displayGrid: DisplayGrid.None,
      gridType: GridType.Fit,
      compactType: CompactType.None,
      pushItems: true,
      allowMultiLayer: true,
      defaultLayerIndex: 1,
      baseLayerIndex: 2,
      maxLayerIndex: 2,
      draggable: {
        enabled: true,
        dragHandleClass: 'drag-handler',
        ignoreContent: true
      },
      resizable: {
        enabled: false
      },
      itemResizeCallback: this.itemResize
    };

    this.dashboard = [
      { cols: 4, rows: 9, y: 9, x: 0, component: 'bma', componentName: 'BMAs' },
      { cols: 5, rows: 9, y: 9, x: 4, component: 'call-requests', componentName: 'Sprechwünsche' },
      { cols: 9, rows: 9, y: 0, x: 0, component: 'eventList', componentName: 'Einsätze' },
      { cols: 14, rows: 18, y: 0, x: 9, component: 'eventView', componentName: 'Aktueller Einsatz' },
      { cols: 17, rows: 6, y: 18, x: 0, component: 'hospitals', componentName: 'Krankenhäuser' },
      { cols: 20, rows: 11, y: 13, x: 23, component: 'map', componentName: 'Karte' },
      { cols: 6, rows: 6, y: 18, x: 17, component: 'status-logger', componentName: 'FMS-Log' },
      { cols: 20, rows: 13, y: 0, x: 23, component: 'status-monitor2', componentName: 'Status Monitor' }
    ];

    // this.savedLayouts = await this.storageService.get("layouts") || [];
    // this.dashboard = [
    //   { cols: 20, rows: 13, y: 0, x: 23, component: 'status-monitor2', componentName: 'Status Monitor', actions: [] },
    // ];

    this.variableService.editMode.subscribe(x => {
      this.editMode = x;
      if (this.options.api) {
        this.options.resizable.enabled = x;
        this.options.api.optionsChanged();
      }
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.addComponent.subscribe(component => {
      switch (component) {
        case 'bma': this.addItem('bma', 'BMAs'); break;
        case 'call-requests': this.addItem('call-requests', 'Sprechwünsche'); break;
        case 'eventList': this.addItem('eventList', 'Einsätze'); break;
        case 'eventView': this.addItem('eventView', 'Aktueller Einsatz'); break;
        case 'hospitals': this.addItem('hospitals', 'Krankenhäuser'); break;
        case 'map': this.addItem('map', 'Karte'); break;
        case 'status-logger': this.addItem('status-logger', 'FMS-Log'); break;
        case 'status-monitor': this.addItem('status-monitor2', 'Status Monitor'); break;
        case 'chat': this.addItem('chat', 'Chat'); break;
      }
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.dashboardCmd.subscribe(cmd => {
      switch (cmd) {
        case "save": {
          this.openSaveLayout();
          this.variableService.dashboardCmd.next("");
          break;
        }
        case "load": {
          this.openLoadLayout();
          this.variableService.dashboardCmd.next("");
          break;
        }
        case "reset": {
          this.resetDashboard();
          this.variableService.dashboardCmd.next("");
          break;
        }
      }
      this.changeDetectorRef.detectChanges();
    })


    this.variableService.displayDummyMarker.subscribe(x => {
      this.displayDummyMarker = x;
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.displayPingMarker.subscribe(x => {
      this.displayPingMarker = x;
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.dummyMarkerPosition.subscribe(x => {
      this.dummyMarkerPosition = x;
    });
  }

  changedOptions() {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  removeItem($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  addItem(component, componentName) {
    if (component === 'map') {
      const map = this.dashboard.find(x => x.component === 'map');
      if (map == null) {
        this.dashboard.push({ x: 0, y: 0, cols: 5, rows: 5, component, componentName });
      } else {
        // TODO: Fehlermeldung warum Map nicht hionzugefügt wird.
      }
    } else if (component === 'status-monitor2') {
      const monitor = this.dashboard.find(x => x.component === 'status-monitor2');
      if (monitor == null) {
        this.dashboard.push({ x: 0, y: 0, cols: 5, rows: 5, component, componentName });
      } else {
        // TODO: Fehlermeldung warum Map nicht hionzugefügt wird.
      }
    } else {
      this.dashboard.push({ x: 0, y: 0, cols: 5, rows: 5, component, componentName });
    }

  }

  initItem(item: GridsterItem, itemComponent: GridsterItemComponent) {
    this.itemToPush = itemComponent;
  }

  pushItem() {
    const push = new GridsterPush(this.itemToPush); // init the service
    this.itemToPush.$item.rows += 4; // move/resize your item
    if (push.pushItems(push.fromNorth)) { // push items from a direction
      push.checkPushBack(); // check for items can restore to original position
      push.setPushedItems(); // save the items pushed
      this.itemToPush.setSize();
      this.itemToPush.checkItemChanges(this.itemToPush.$item, this.itemToPush.item);
    } else {
      this.itemToPush.$item.rows -= 4;
      push.restoreItems(); // restore to initial state the pushed items
    }
    push.destroy(); // destroy push instance
    // similar for GridsterPushResize and GridsterSwap
  }

  test(item) {
    this.dashboard.forEach(x => {
      if (item == x) {
        if (!x.buttons) {
          x.buttons = [];
        }
        x.buttons.push({ label: 'Test' });
      }
    });
  }

  itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
    item.width = itemComponent.width;
    item.height = itemComponent.height;
  }

  releaseEvent() {
    this.eventService.selectEvent(0);
  }

  cancelEvent() {
    this.eventService.forceFinishSelectedEvent();
  }

  closeEvent() {
    this.eventService.closeSelectedEvent();
  }

  openCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  resetDashboard() {
    this.dashboard = [
      { cols: 4, rows: 9, y: 9, x: 0, component: 'bma', componentName: 'BMAs' },
      { cols: 5, rows: 9, y: 9, x: 4, component: 'call-requests', componentName: 'Sprechwünsche' },
      { cols: 9, rows: 9, y: 0, x: 0, component: 'eventList', componentName: 'Einsätze' },
      { cols: 14, rows: 18, y: 0, x: 9, component: 'eventView', componentName: 'Aktueller Einsatz' },
      { cols: 17, rows: 6, y: 18, x: 0, component: 'hospitals', componentName: 'Krankenhäuser' },
      { cols: 20, rows: 11, y: 13, x: 23, component: 'map', componentName: 'Karte' },
      { cols: 6, rows: 6, y: 18, x: 17, component: 'status-logger', componentName: 'FMS-Log' },
      { cols: 20, rows: 13, y: 0, x: 23, component: 'status-monitor', componentName: 'Status Monitor' }
    ];
  }

  async openSaveLayout() {
    this.savedLayouts = await this.storageService.get("layouts") || [];
    this.layoutName = "";
    this.displaySaveLayout = true;
    this.changeDetectorRef.detectChanges();
  }

  filterLayoutsSingle(event) {
    const query = event.query;
    const filter = [];

    this.savedLayouts.forEach(layout => {
      if (layout.name.toLowerCase().includes(query.toLowerCase())) {
        filter.push(layout.name);
      }
    });

    filter.sort();

    this.filteredLayoutSingle = filter;
  }

  async saveDashboardLayout() {
    this.displaySaveLayout = false;
    // localStorage.setItem('lst_dashboard_' + layout, JSON.stringify(this.dashboard));

    // Generate random id
    const length = 5;
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";

    for (let i = 0; i < length; i++) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    this.savedLayouts = await this.storageService.get("layouts") || [];
    let foundLayout = this.savedLayouts.find(x => x.name === this.layoutName);
    if (foundLayout) {
      id = foundLayout.id;
    } else {
      this.savedLayouts.push({ id, name: this.layoutName });
    }

    this.storageService.set("layouts", this.savedLayouts);
    this.storageService.set("layout_" + id, JSON.stringify(this.dashboard));
  }

  async openLoadLayout() {
    this.savedLayouts = await this.storageService.get("layouts") || [];
    this.displayLoadLayout = true;
    this.changeDetectorRef.detectChanges();
  }

  async loadDashboardLayout($event) {
    this.displayLoadLayout = false;

    const dashboard = await this.storageService.get("layout_" + $event.data.id);
    console.log(dashboard)
    if (dashboard != null) {
      this.dashboard = dashboard;
    }
    this.changeDetectorRef.detectChanges();
  }

  receiveDashboardItemData(item, data) {
    item.data = data;
  }

  createDummyEvent() {
    if (this.displayDummyMarker) {
      this.variableService.displayDummyMarker.next(false);

      const posX = this.dummyMarkerPosition[1];
      const posY = this.dummyMarkerPosition[0];

      this.socketService.socketEmit('lst', { cmd: 'addDummyEvent', data: { posX, posY } });
    }

    if (this.displayPingMarker) {
      this.variableService.displayPingMarker.next(false);

      const posX = this.dummyMarkerPosition[1];
      const posY = this.dummyMarkerPosition[0];

      this.socketService.socketEmit('lst', { cmd: 'miniMapPing', data: { posX, posY } });
    }
  }

  showPingMarker() {
    this.variableService.displayPingMarker.next(true)
  }

  overwriteLangVariable(variable) {
    switch (variable) {
      case "EVENTLIST": return "EVENT-LIST";
      case "EVENTVIEW": return "EVENT-VIEW";
      case "STATUS-MONITOR2": return "STATUS-MONITOR";
      default: return variable;
    }
  }
}
