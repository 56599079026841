<div class="p-fluid event-list">
  <p-selectButton [options]="filterTypes" [(ngModel)]="selectedFilter" multiple="multiple" (onChange)="filter()">
  </p-selectButton>
</div>

<p-treeTable [value]="displayedEvents" [columns]="cols" selectionMode="single" [(selection)]="selectedEvent"
  dataKey="id" [scrollable]="true" [scrollHeight]="(height - 55) + 'px'" [resizableColumns]="true"
  [metaKeySelection]="true" (onNodeSelect)="onNodeSelect($event)" (onNodeUnselect)="onNodeUnSelect($event)"
  [resizableColumns]="true">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" ttResizableColumn>
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode" [ttContextMenuRow]="rowNode"
      (contextmenu)="openCm($event, rowNode, eventListContextMenu)">
      <td *ngFor="let col of columns; let i = index"
        class="ui-resizable-column {{col.field == 'state' ? 'state' + rowData[col.field] : ''}}">
        <div *ngIf="i == 1" style="float: right">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </div>

        <div *ngIf="i != 0 && i != 1 && i != 2"> {{rowData[col.field]}}</div>
        <div *ngIf="i == 1" style="position: absolute;"> {{rowData[col.field]}}</div>
        <div *ngIf="i == 2"> {{rowData.state == 0 ? ('EVENT-LIST.UNKNOWN' | translate) : rowData[col.field]}}</div>
      </td>
    </tr>
  </ng-template>
</p-treeTable>

<p-contextMenu #eventListContextMenu [model]="items" appendTo="body"></p-contextMenu>