import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-status-logger',
  templateUrl: './status-logger.component.html',
})
export class StatusLoggerComponent implements OnInit, OnDestroy {
  vehicleSubscription: Subscription;
  vehicles: any[] = [];

  cols = [];

  constructor(
    private vehicleService: VehicleService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      const veh = this.vehicles.find(v => v.name === vehicle.name);
      if (veh != null) {
        if (veh.status !== vehicle.status) {
          // tslint:disable-next-line: max-line-length
          this.vehicles.unshift({ name: vehicle.name, displayName: vehicle.displayName, status: vehicle.status, currentEvent: vehicle.currentEvent, hasCallRequest: vehicle.hasCallRequest });
        } else if (vehicle.hasCallRequest > veh.hasCallRequest) {
          let status = 5;
          if (vehicle.hasCallRequest === 2) status = 0;
          // tslint:disable-next-line: max-line-length
          this.vehicles.unshift({ name: vehicle.name, displayName: vehicle.displayName, status: status, currentEvent: vehicle.currentEvent, hasCallRequest: vehicle.hasCallRequest });
        }
      } else {
        // tslint:disable-next-line: max-line-length
        this.vehicles.unshift({ name: vehicle.name, displayName: vehicle.displayName, status: vehicle.status, currentEvent: vehicle.currentEvent, hasCallRequest: vehicle.hasCallRequest });
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'status', header: '', width: '2px' },
      { field: 'name', header: 'Fahrzeug', width: '75%' },
      { field: 'status', header: 'Status', width: '25%' }
    ];
  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
  }
}
