import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Keyword {
  name: string;
  category: string;
  sosi: boolean;
  vehicles: string[];
  increase: string;
}

export interface KeywordCategory {
  name: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})

export class KeywordService {
  private keywords: Keyword[] = [];
  private keyword = new Subject<Keyword>();
  private defaultKeywords: Keyword[] = [];

  private categories: KeywordCategory[] = [
    { name: "Feuer", color: "red" },
    { name: "Technische Hilfeleistung", color: "orange" },
    { name: "Rettungsdienst", color: "green" },
    { name: "Polizei", color: "blue" }
  ];
  private category = new Subject<KeywordCategory>();
  private defaultCategories: KeywordCategory[] = [
    { name: "Feuer", color: "red" },
    { name: "Technische Hilfeleistung", color: "orange" },
    { name: "Rettungsdienst", color: "green" },
    { name: "Polizei", color: "blue" }
  ];

  public getVehicleTypes() {
    // Alle Fahrzeuge bitte Alphabetisch ordnen, keine Lust da suchen zu müssen -> Kuss!
    const types = []

    types['AB-ATS'] = { name: "Abrollbehälter Atemschutz", org: 'Feuerwehr', restriction: [], sort: 92 };
    types['AB-DEKON'] = { name: "Abrollbehälter Dekon", org: 'Feuerwehr', restriction: [], sort: 91 };
    types['AB-ELW'] = { name: "Abrollbehälter Einsatzleitung", org: 'Feuerwehr', restriction: [], sort: 93 };
    types['AB-MANV'] = { name: "Abrollbehälter MANV", org: 'Feuerwehr', restriction: [], sort: 94 };
    types['AB-MULDE'] = { name: "Abrollbehälter MULDE", org: 'Feuerwehr', restriction: [], sort: 95 };
    types['AB-RUEST'] = { name: "Abrollbehälter RUEST", org: 'Feuerwehr', restriction: [], sort: 96 };
    types['AB-SOZIAL'] = { name: "Abrollbehälter Sozial", org: 'Feuerwehr', restriction: [], sort: 97 };
    types['AB-WASSER'] = { name: "Abrollbehälter Wasser", org: 'Feuerwehr', restriction: [], sort: 98 };
    types['DEKONP'] = { name: "Gerätewagen Dekontamination Person", org: 'Feuerwehr', restriction: [], sort: 90 };
    types['DLK'] = { name: "Drehleiter mit Korb", org: 'Feuerwehr', restriction: ['BF', 'FF'], sort: 12 };
    types['ELW'] = { name: "Einsatzleitwagen", org: 'Feuerwehr', restriction: [], sort: 1 };
    types['ELW-C'] = { name: "Einsatzleitwagen mit C-Dienst", org: 'Feuerwehr', restriction: [], sort: 2 };
    types['ELW-B'] = { name: "Einsatzleitwagen mit B-Dienst", org: 'Feuerwehr', restriction: [], sort: 3 };
    types['ELW-A'] = { name: "Einsatzleitwagen mit A-Dienst", org: 'Feuerwehr', restriction: [], sort: 4 };
    types['ELW2'] = { name: "Einsatzleitwagen 2", org: 'Feuerwehr', restriction: [], sort: 5 };
    types['GTLF'] = { name: "Großtanklöschfahrzeug", org: 'Feuerwehr', restriction: [], sort: 16 };
    types['GW-ATS'] = { name: "Gerätewagen Atemschutz", org: 'Feuerwehr', restriction: [], sort: 85 };
    types['GW-RUEST'] = { name: "Gerätewagen Rüstaustattung", org: 'Feuerwehr', restriction: [], sort: 87 };
    types['GW-MESS'] = { name: "Gerätewagen Messtechnik", org: 'Feuerwehr', restriction: [], sort: 86 };
    types['GW-T'] = { name: "Gerätewagen Technik", org: 'Feuerwehr', restriction: [], sort: 84 };
    types['GW-L'] = { name: "Gerätewagen Logistik", org: 'Feuerwehr', restriction: [], sort: 89 };
    types['GW-W'] = { name: "Gerätewagen Wasserrettung", org: 'Feuerwehr', restriction: [], sort: 88 };
    types['HLF'] = { name: "Hilfeleistungslöschgruppenfahrzeug", org: 'Feuerwehr', restriction: ['BF', 'FF'], sort: 10 };
    types['KDOW'] = { name: "Komandowagen", org: 'Feuerwehr', restriction: [], sort: 6 };
    types['KRAN'] = { name: "Feuerwehrkran", org: 'Feuerwehr', restriction: [], sort: 20 };
    types['LF'] = { name: "Löschgruppenfahrzeug", org: 'Feuerwehr', restriction: ['BF', 'FF'], sort: 11 };
    types['MTW'] = { name: "Mannschaftstransportwagen", org: 'Feuerwehr', restriction: ['BF', 'FF'], sort: 18 };
    types['OSF'] = { name: "Ölschadenfahrzeuge", org: 'Feuerwehr', restriction: [], sort: 20 };
    types['RW'] = { name: "Rüstwagen", org: 'Feuerwehr', restriction: [], sort: 14 };
    types['SW'] = { name: "Schlauchwagen", org: 'Feuerwehr', restriction: [], sort: 89 };
    types['TLF'] = { name: "Tanklöschfahrzeug", org: 'Feuerwehr', restriction: ['BF', 'FF'], sort: 15 };
    types['WLF'] = { name: "Wechselladerfahrzeug", org: 'Feuerwehr', restriction: [], sort: 20 };

    // Rettungsdienst
    types['BTCOMBI'] = { name: "Betreuungskombi", org: 'Rettungsdienst', restriction: [], sort: 110 };
    types['GRTW'] = { name: "Großraumrettungswagen", org: 'Rettungsdienst', restriction: [], sort: 108 };
    types['GW-SAN'] = { name: "Gerätewagen Sanitöt", org: 'Rettungsdienst', restriction: [], sort: 108 };
    types['HUND'] = { name: "Fachgruppe Ortung", org: 'Rettungsdienst', restriction: [], sort: 150 };
    types['IRTW'] = { name: "Infekt-Rettungswagen", org: 'Rettungsdienst', restriction: [], sort: 104 };
    types['ITW'] = { name: "Intensivtransportwagen", org: 'Rettungsdienst', restriction: [], sort: 104 };
    types['KTW'] = { name: "Krankentransportwagen", org: 'Rettungsdienst', restriction: [], sort: 105 };
    types['LNA'] = { name: "Leitender Notarzt", org: 'Rettungsdienst', restriction: [], sort: 101 };
    types['NAW'] = { name: "Notarztwagen", org: 'Rettungsdienst', restriction: [], sort: 102 };
    types['NEF'] = { name: "Notarzteinsatzfahrzeug", org: 'Rettungsdienst', restriction: [], sort: 102 };
    types['PTZ'] = { name: "Personentransportzug", org: 'Rettungsdienst', restriction: [], sort: 301 };
    types['RTH'] = { name: "Rettungshubschrauber", org: 'Rettungsdienst', restriction: [], sort: 103 };
    types['RTW'] = { name: "Rettungswagen", org: 'Rettungsdienst', restriction: [], sort: 103 };

    // Polizei
    types['BEPO'] = { name: "Bereitschaftspolizei", org: 'Polizei', restriction: [], sort: 203 };
    types['DROHNE'] = { name: "Beweissicherungs- und Dokumentationskraftwagen mit Drohne", org: 'Polizei', restriction: [], sort: 208 };
    types['FUSTW'] = { name: "Funkstreifenwagen", org: 'Polizei', restriction: [], sort: 201 };
    types['FUSTW-MTW'] = { name: "Funkstreifenwagen MTW", org: 'Polizei', restriction: [], sort: 202 };
    types['GEFKW'] = { name: "Gefangenenkraftwagen", org: 'Polizei', restriction: [], sort: 210 };
    types['HGRUKW'] = { name: "Halbgruppenkraftwagen", org: 'Polizei', restriction: [], sort: 209 };
    types['HUMMEL'] = { name: "Polizeihubschrauber", org: 'Polizei', restriction: [], sort: 207 };
    types['KMRD'] = { name: "Kampfmittelräumdienst", org: 'Polizei', restriction: [], sort: 206 };
    types['KTU'] = { name: "Kriminaltechnische Untersuchung", org: 'Polizei', restriction: [], sort: 205 };
    types['SEK'] = { name: "Sondereinsatzkommando", org: 'Polizei', restriction: [], sort: 204 };
    types['WAWE'] = { name: "Wasserwerfer", org: 'Polizei', restriction: [], sort: 208 };

    // Sonstiges
    types['BFU'] = { name: "BFU", org: 'Polizei', restriction: [], sort: 302 };
    types['EKW'] = { name: "Entstördienst", org: 'Polizei', restriction: [], sort: 302 };
    types['STADTWERKE'] = { name: "Stadtreinigung", org: 'Polizei', restriction: [], sort: 302 };
    types['ASF'] = { name: "Abschleppfahrzeug", org: 'Polizei', restriction: [], sort: 302 };
    types['BESTATTER'] = { name: "Bestatter", org: 'Polizei', restriction: [], sort: 302 };

    return types;
  }

  public getUpdate(): Observable<Keyword> {
    return this.keyword.asObservable();
  }

  resetKeywords() {
    this.keywords = [];
  }

  getKeywords(): Keyword[] {
    return this.keywords;
  }

  setDefaultKeywords(keywords) {
    this.defaultKeywords = keywords;
    if (this.keywords == null || this.keywords.length == 0) {
      this.keywords = keywords;
    }
  }

  getDefaultKeywords(): Keyword[] {
    return this.defaultKeywords;
  }

  setClientKeywords(keywords) {
    if (keywords != null && Array.isArray(keywords) && keywords.length > 0) {
      console.log("IMPORTING AAO")
      for(const keyword of keywords) {
        for(var i=0; i < keyword.vehicles.length; i++) {
          if (keyword.vehicles[i] == 'GWW') keyword.vehicles[i] = 'GW-W';
          if (keyword.vehicles[i] == 'GWTECH') keyword.vehicles[i] = 'GW-T';
          if (keyword.vehicles[i] == 'DEKON') keyword.vehicles[i] = 'DEKONP';
          if (keyword.vehicles[i] == 'GW-TECH') keyword.vehicles[i] = 'GW-T';
          if (keyword.vehicles[i] == 'GW-LOG') keyword.vehicles[i] = 'GW-L';
          if (keyword.vehicles[i] == 'SW2000') keyword.vehicles[i] = 'SW';
         }
      }
      this.keywords = keywords;
    }
  }

  updateKeyword(keyword: any) {
    const data = this.keywords.find(x => x.name === keyword.name);
    if (data == null) {
      keyword.vehicles = keyword.vehicles;
      this.keywords.push(keyword);
    } else {
      if (keyword.sosi !== undefined && keyword.sosi !== data.sosi) {
        data.sosi = keyword.sosi;
      }
      if (keyword.vehicles !== undefined && keyword.vehicles !== data.vehicles) {
        data.vehicles = keyword.vehicles;
      }
    }
    this.keyword.next(keyword);

    this.keywords.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  setKeywords(keywords) {
    this.keywords = keywords;
  }

  setCategories(categories) {
    this.categories = categories;
  }

  getCategories(): KeywordCategory[] {
    return this.categories;
  }

  getDefaultCategories(): KeywordCategory[] {
    return this.defaultCategories;
  }

  setClientCategories(categories) {
    if (categories != null && categories.length > 0) {
      this.categories = categories;
    }
  }

  getKeywordColor(keyword) {
    const kw = this.keywords.find(x => x.name == keyword);
    if (kw && kw.category != null && kw.category != '') {
      const category = this.categories.find(x => x.name == kw.category);
      if (category) return category.color;
    }
    return null;
  }
}
