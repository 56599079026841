<div class="p-dialog-header">
  <span class="p-dialog-title">{{'SESSION-PICKER.TITLE' | translate}} ({{sessions.length}})</span>
  <div class="p-dialog-header-icons">
    <button class="p-dialog-header-icon p-link" (click)="getSessions()">
      <span class="pi pi-replay"></span>
    </button>
    <button type="button" class="p-dialog-header-icon p-link" (click)="closePicker()">
      <span class="pi pi-times"></span>
    </button>
  </div>
</div>

<p-table [value]="sessions" selectionMode="single" [(selection)]="selectedSession" [scrollable]="true"
  [scrollHeight]="error == null ? 'calc(100vh - 318px)' : 'calc(100vh - 356px)'">
  <ng-template pTemplate="header">
    <tr>
      <th>{{'SESSION-PICKER.CREATOR' | translate}}</th>
      <th>{{'SESSION-PICKER.STARTTIME' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-session>
    <tr [pSelectableRow]="session">
      <td>{{session.sessionName}}</td>
      <td>{{session.startTime | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
    </tr>
  </ng-template>
</p-table>

<div class="p-fluid">
  <div>
    <label>{{'SESSION-PICKER.PASSWORD' | translate}}</label>
    <input [(ngModel)]="sessionPassword" type="password" pInputText placeholder="{{'SESSION-PICKER.PASSWORD' | translate}}" [disabled]="blockedPanel"
      (keyup.enter)="auth()" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
  </div>

  <div *ngIf="error != null" [style]="{'padding-top':'10px'}">
    <p-message severity="{{errorType}}" text="{{error}}"></p-message>
  </div>

  <div [style]="{'padding-top':'10px'}">
    <button pButton type="button" label="{{'SESSION-PICKER.SUBMIT' | translate}}" (click)="auth()" [disabled]="blockedPanel"></button>
  </div>

  <div [style]="{'padding-top':'10px'}" class="p-fluid">
    <p-message severity="warn" text="Hinweis: Der Versuch sich in fremde Sessions einzuloggen wird mit einem Ausschluss bestraft."></p-message>
  </div>
</div>
