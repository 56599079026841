import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketIOService {
  private socketUrl = environment.socketUrl;
  private socket;

  constructor(
  ) {
    this.socket = io(this.socketUrl);
  }

  socketEmit(event, obj) {
    this.socket.emit(event, JSON.stringify(obj));
  }

  socketListener() {
    return new Observable<any>(subscriber => {
      this.socket.on('toLst', (payload) => {
        // console.log(payload);
        try {
          if (typeof payload === 'object') {
            subscriber.next(payload);
          } else {
            subscriber.next(JSON.parse(payload));
          }
        } catch (error) {
          // :(
          console.log("Hier ist ein Error passoert \n" + error);
        }
      });
    });
  }

  authSession(sessionName, startTime, sessionPassword) {
    if (sessionName == null || startTime == null || sessionPassword == null) {
      return;
    }

    this.socketEmit('auth', { cmd: 'authSession', data: { sessionName, startTime, sessionPassword } });
  }

  loginClient(username, password, authString) {
    if (username == null || password == null) {
      return;
    }

    this.socketEmit('auth', { cmd: 'authClient', data: { type: 'login', username, password, authString } });
  }

  autoLoginClient(username, password) {
    if (username == null || password == null) {
      return;
    }

    this.socketEmit('auth', { cmd: 'authClient', data: { type: 'autoLogin', username, password } });
  }

  registerClient(username, password, authString, captcha) {
    if (username == null || password == null || captcha == null) {
      return;
    }

    this.socketEmit('auth', { cmd: 'authClient', data: { type: 'register', username, password, authString, captcha } });
  }

  logoutClient() {
    this.socketEmit('auth', { cmd: 'logout', data: 0 });
  }

  authClient(authData) {
    this.socketEmit('auth', { cmd: 'authClient', data: { type: 'newAuth', authData } });
  }
}
