import { Component, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { BmaService, Bma } from 'src/app/services/bma.service';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { Settings, SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-bma',
  templateUrl: './bma.component.html',
})
export class BmaComponent implements OnDestroy {
  settings: Settings;
  @Input() height = 0;

  bmaSubscription: Subscription;
  bmas: Bma[] = [];

  constructor(
    private bmaService: BmaService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.bmas = this.bmaService.getBmas();

    this.bmaSubscription = this.bmaService.getUpdate().subscribe(bma => {
      let data = this.bmas.find(x => x.name === bma.name);
      if (data == null) {
        this.bmas.push(bma);
      } else {
        data = bma;

        if (bma.eventId != 0) {
          if (this.settings.audio.playBmaSound) {
            const audio = new Audio('assets/sounds/ausgeloeste-brandmeldeanlage.wav');
            audio.volume = this.settings.audio.volume;
            audio.play();
          }
        }
      }
      this.changeDetectorRef.detectChanges();
    });

    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  ngOnDestroy(): void {
    this.bmaSubscription.unsubscribe();
  }

  selectEvent(eventId) {
    this.eventService.selectEvent(eventId);
  }
}
