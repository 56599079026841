import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CompactType, GridsterConfig, GridsterItem, GridsterItemComponent, GridType, GridsterItemComponentInterface, DisplayGrid } from 'angular-gridster2';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { Observable, Subscription } from 'rxjs';
import { StationService, Station } from 'src/app/services/station.service';
import { MenuItem } from 'primeng/api/menuitem';
import { HospitalService, Hospital } from 'src/app/services/hospital.service';
import { EventService } from 'src/app/services/event.service';
import { VariableService } from 'src/app/services/variable.service';

@Component({
  selector: 'app-status-monitor',
  templateUrl: './status-monitor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StatusMonitorComponent implements OnInit, OnDestroy {
  @Input() inputData: any;
  @Output() outputData = new EventEmitter<any>();

  vehicleSubscription: Subscription;
  vehicles: Vehicle[] = [];

  stationSubscription: Subscription;
  stations: Station[] = [];

  hospitalSubscription: Subscription;
  hospitals: Hospital[] = [];

  options: GridsterConfig;
  dashboard: Array<GridsterItem> = [];
  itemToPush: GridsterItemComponent;
  items: MenuItem[];

  constructor(
    private vehicleService: VehicleService,
    private stationService: StationService,
    private hospitalService: HospitalService,
    private eventService: EventService,
    private variableService: VariableService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  // Todo: Bei vergrößern von Component ggf. mehr Spalten anzeigen
  // Todo: SortOrder für Fahrzeuge?
  ngOnInit(): void {
    this.options = {
      displayGrid: DisplayGrid.Always,
      gridType: GridType.ScrollVertical,
      compactType: CompactType.CompactLeft,
      pushItems: true,
      draggable: {
        enabled: true,
        dragHandleClass: 'p-panel-header',
        ignoreContent: true
      },
      resizable: {
        enabled: true
      },

      itemChangeCallback: this.itemChangeCallback
    };

    this.vehicles = this.vehicleService.getVehicles();
    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        this.vehicles.push(vehicle);
      } else {
        data = vehicle;
      }
      this.changeDetectorRef.detectChanges();
    });

    // Todo: Größe der GridItems an Inhalt anpassen
    this.stations = this.stationService.getStations();
    this.stationService.getStations().forEach(station => {
      if (this.inputData == null) {
        this.dashboard.push({ cols: station.smCols, rows: station.smRows, y: station.smY, x: station.smX, stationId: station.id, name: station.name });
      }
    });

    this.stationSubscription = this.stationService.getUpdate().subscribe(station => {
      let data = this.stations.find(x => x.id === station.id);
      if (data == null) {
        this.stations.push(station);
        if (this.inputData == null) {
          this.dashboard.push({ cols: station.smCols, rows: station.smRows, y: station.smY, x: station.smX, stationId: station.id, name: station.name });
        }
      } else {
        data = station;
      }
      this.changeDetectorRef.detectChanges();
    });

    this.hospitals = this.hospitalService.getHospitals();
    this.hospitalSubscription = this.hospitalService.getUpdate().subscribe(hospital => {
      let data = this.hospitals.find(x => x.name === hospital.name);
      if (data == null) {
        this.hospitals.push(hospital);
      } else {
        data = hospital;
      }
      this.changeDetectorRef.detectChanges();
    });

    if (this.inputData != null) {
      this.inputData.forEach(station => {
        this.dashboard.push({
          x: station.x, y: station.y, rows: station.rows, cols: station.cols, stationId: station.stationId, name: station.name
        });
      });
    }

  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
    this.stationSubscription.unsubscribe();
    this.hospitalSubscription.unsubscribe();
  }

  itemChangeCallback = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
    item.width = itemComponent.width;
    item.height = itemComponent.height;

    this.outputData.emit(this.dashboard);
  }

  openCm(event, cm, vehicle: Vehicle) {
    this.items = [
      { label: vehicle.displayName },
      { separator: true },
      {
        label: 'Status setzen', items: [
          { label: 'Status 1', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 1) },
          { label: 'Status 3', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 3) },
          { label: 'Status 4', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 4) },
          { label: 'Status 6', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 6) },
          { label: 'Status 7', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 7) },
          { label: 'Status 8', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 8) }
        ]
      },
      { label: 'Sprechaufforderung', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 28) }
    ];

    if (vehicle.type2.includes('RTW') || vehicle.type2.includes('KTW') || vehicle.type2.includes('PTZ') || vehicle.type2.includes('ITW') || vehicle.type2.includes('RTH') || vehicle.type2.includes('IRTW') || vehicle.type2.includes('GRTW') || vehicle.type2.includes('NAW')) {
      const hospitals = [];
      this.hospitals.forEach(hospital => {
        hospitals.push({ label: hospital.name, command: (x) => this.vehicleService.assignToHospital(vehicle.name, hospital.name) });
      });

      this.items.push({
        label: 'Krankenhaus zuweisen', items: hospitals
      });
    }

    this.items.push({ separator: true });

    if (vehicle.currentEvent > 0) {
      this.items.push({
        label: 'Zum Einsatz springen', command: (x) => {
          this.eventService.selectEvent(vehicle.currentEvent);
        }
      });
    }

    this.items.push({ label: 'Fahrzeug zurücksetzen', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 27) });

    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  addToEvent(event, vehicle) {
    if (vehicle.status == 1 || vehicle.status == 2) {
      this.eventService.addVehicleToEvent(vehicle.name);
    } else if (vehicle.status == 3 || vehicle.status == 4 || vehicle.status == "C") {
      this.eventService.selectEvent(vehicle.currentEvent);
    }
  }
}
