<div class="grid" style="margin-left:0; margin-right:0;">
  <div class="dock-demo col" style="padding-left:0; padding-right:0;">
    <p-menubar [model]="menu1" styleClass="menubar"></p-menubar>
  </div>
  <div class="dock-demo col" style="padding-left:0; padding-right:0;">
    <p-menubar [model]="menu2" styleClass="menubar"></p-menubar>
  </div>
  <div class="dock-demo col" style="padding-left:0; padding-right:0;">
    <p-menubar [model]="menu3" styleClass="menubar" baseZIndex="1000"></p-menubar>
  </div>
</div>
