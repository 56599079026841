<p-table [value]="vehicles" selectionMode="single" [(selection)]="selectedVehicle" (onRowSelect)="onRowSelect($event)"
  [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true" [scrollHeight]="height+'px'">
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn>{{ 'CALL-REQUESTS.VEHICLE' | translate }}</th>
      <th pResizableColumn>{{ 'OTHER.TIMESTAMP' | translate }}</th>
      <th pResizableColumn style="flex-basis: 1%; justify-content: center;">E</th>
      <th pResizableColumn style="flex-basis: 1%; justify-content: center;">S</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-request>
    <tr class="call{{request.hasCallRequest}}" [pSelectableRow]="request" (dblclick)="doubleClick(request)"
      (contextmenu)="openVehicleContextMenu($event, vehicleContextMenu, request)">
      <td>{{request.displayName}}</td>
      <td>{{request.requestTime | date:'HH:mm:ss'}}</td>
      <td>{{request.currentEvent}}</td>
      <td>{{request.status}}</td>
    </tr>
  </ng-template>
</p-table>

<p-contextMenu #vehicleContextMenu [model]="vehicleContextMenuItems" appendTo="body"></p-contextMenu>
