<div #dialog>
  <div [style]="{height: (height) + 'px'}" *ngFor="let group of layout"
    [style.display]="group.visible1 ? 'grid' : 'none'">
    <gridster [options]="group.options" class="status-gridster">
      <gridster-item [item]="item" *ngFor="let item of group.items" class="drag-content" id="grid_{{item.component}}">

        <!-- Vehicle -->
        <span class="p-buttonset" style="height:20px !Important" *ngIf="item.type == 0">
          <button pButton [label]="item.name" style="width: calc(100% - 25px)"
            class="p-button-outlined p-button-vehicle p-button-vehicle-bg p-button-status-{{item}}-outlined"
            (contextmenu)="openContextMenu($event, vehicleContextMenu, item)"></button>
          <button pButton style="width: 25px" class="p-button-vehicle p-button-status-{{item.status || 6}}" label=" ">
            <span class="p-button-label" (click)="group.items.pop()"> {{item.status || 6}}</span>
          </button>
        </span>

        <!-- Brigade -->
        <p-panel *ngIf="item.type == 1" [header]="item.name">
          <ng-template pTemplate="icons">
            <button pButton type="button" *ngIf="editMode" [style]="{'float':'right', 'margin-left': '5px'}"
              icon="pi pi-trash" class="p-panel-header-icon p-link" (click)="removeItem($event, item)"></button>
            <button pButton type="button" *ngIf="!editMode" [style]="{'float':'right', 'margin-left': '5px'}"
              icon="pi pi-play" class="p-panel-header-icon p-link" (click)="addBrigadeToEvent(item.vehicles)"></button>
          </ng-template>

          <div *ngIf="getVehiclesByNames(item.vehicles) != null">
            <div class="fahrzeuge" *ngFor="let vehicle of getVehiclesByNames(item.vehicles)">
              <span class="p-buttonset">
                <button pButton [label]="vehicle.displayName" [style]="{width: '90%'}"
                  class="p-button-outlined p-button-vehicle p-button-vehicle-bg p-button-status-{{vehicle.status}}-outlined {{isSelectedVehicle(vehicle.name) ? 'p-button-selected' : ''}} {{isVehicleInCurrentEvent(vehicle) ? 'p-button-selected2' : ''}}"
                  (click)="item.isNotAlertable ? null : addToEvent($event, vehicle)"
                  (contextmenu)="openContextMenu($event, vehicleContextMenu, vehicle)"></button>
                <button pButton [style]="{width: '10%'}" class="p-button-vehicle p-button-status-{{vehicle.status}}"
                  label=" ">
                  <span class="p-button-label"> {{vehicle.status}}</span>
                </button>
              </span>
            </div>
          </div>
        </p-panel>

        <!-- Panel -->
        <p-panel *ngIf="item.type == 2" [header]="item.label">
          <div [style.height]="(item.height - 33) + 'px'"></div>
        </p-panel>

        <!-- Station -->
        <p-panel *ngIf="item.type == 4" [header]="item.name">
          <ng-template pTemplate="icons">
            <button pButton type="button" *ngIf="editMode" [style]="{'float':'right', 'margin-left': '5px'}"
              icon="pi pi-trash" class="p-panel-header-icon p-link" (click)="removeItem($event, item)"></button>
          </ng-template>

          <div class="fahrzeuge" *ngFor="let vehicle of getVehiclesByStationIdentifier(item.stationIdentifier)">
            <span class="p-buttonset">
              <button pButton [label]="vehicle.displayName" [style]="{width: '90%'}"
                class="p-button-outlined p-button-vehicle p-button-vehicle-bg p-button-status-{{vehicle.status}}-outlined {{isSelectedVehicle(vehicle.name) ? 'p-button-selected' : ''}} {{isVehicleInCurrentEvent(vehicle) ? 'p-button-selected2' : ''}}"
                (click)="addToEvent($event, vehicle)"
                (contextmenu)="openContextMenu($event, vehicleContextMenu, vehicle)"></button>
              <button pButton [style]="{width: '10%'}" class="p-button-vehicle p-button-status-{{vehicle.status}}"
                label=" ">
                <span class="p-button-label"> {{vehicle.status}}</span>
              </button>
            </span>
          </div>
        </p-panel>
      </gridster-item>
    </gridster>
  </div>
</div>

<p-dialog header="Hinzufügen" [(visible)]="showAddDialog" [closable]="true" [modal]="true" [resizable]="false"
  [draggable]="false" [appendTo]="dialog" [style]="{width: '75%', height: '75%'}">
  <div class="p-grid p-fluid">
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.TYPE' | translate}}</label>
      <p-selectButton [options]="addDialogOptions" [(ngModel)]="addDialogSelectedOption"></p-selectButton>
    </div>
  </div>

  <!-- 0: Vehicle -->
  <div class="p-grid p-fluid" *ngIf="addDialogSelectedOption == 0">
    <div class="field p-col-12">
      <label>Fahrzeug</label>
      <p-dropdown [options]="vehicles" [(ngModel)]="addDialogSelectedVehicle" optionLabel="displayName" [filter]="true"
        filterBy="name" [showClear]="true" placeholder="{{'STATUS-MONITOR.SELECT-VEHICLE' | translate}}" appendTo="body">
      </p-dropdown>
    </div>

    <button pButton type="button" label="Fahrzeug hinzufügen" class="p-fluid p-button-success"
      (click)="addVehicle()"></button>
  </div>

  <!-- 1: Brigade -->
  <div class="p-grid p-fluid" *ngIf="addDialogSelectedOption == 1">
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.NAME' | translate}}</label>
      <input type="text" pInputText [(ngModel)]="addDialogName" />
    </div>
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.VEHICLES' | translate}}</label>
      <p-multiSelect [options]="vehicles" [(ngModel)]="addDialogSelectedVehicles" optionLabel="displayName"
        appendTo="body"></p-multiSelect>
    </div>
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.CANT-ALERT-SINGLE-VEHICLE' | translate}}</label>
      <p-checkbox [(ngModel)]="addDialogIsNotAlertable" [binary]="true"></p-checkbox>
    </div>

    <button pButton type="button" label="{{'STATUS-MONITOR.ADD-BRIGADE' | translate}}" class="p-fluid p-button-success"
      (click)="addBrigade()"></button>
  </div>

  <!-- 2: Group (Style-Element) -->
  <div class="p-grid p-fluid" *ngIf="addDialogSelectedOption == 2">
    <div class="field p-col-12">
      <label>Name</label>
      <input type="text" pInputText [(ngModel)]="addDialogName" />
    </div>

    <button pButton type="button" label="Gruppe hinzufügen" class="p-fluid p-button-success"
      (click)="addGroup()"></button>
  </div>

  <!-- 3: Tab -->
  <div class="p-grid p-fluid" *ngIf="addDialogSelectedOption == 3">
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.NAME' | translate}}</label>
      <input type="text" pInputText [(ngModel)]="addDialogName" />
    </div>

    <button pButton type="button" label="{{'STATUS-MONITOR.ADD-TAB' | translate}}" class="p-fluid p-button-success" (click)="addTab()"></button>
  </div>

  <!-- 4: Station -->
  <div class="p-grid p-fluid" *ngIf="addDialogSelectedOption == 4">
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.NAME' | translate}}</label>
      <input type="text" pInputText [(ngModel)]="addDialogName" />
    </div>
    <div class="field p-col-12">
      <label>{{'STATUS-MONITOR.STATION' | translate}}</label>
      <p-dropdown [options]="stations" [(ngModel)]="addDialogSelectedStation" optionLabel="name" [filter]="true"
        filterBy="name" [showClear]="true" placeholder="{{'STATUS-MONITOR.SELECT-STATION' | translate}}" appendTo="body"
        (onChange)="addDialogName == '' ? addDialogName = addDialogSelectedStation.name : null">
      </p-dropdown>
    </div>

    <button pButton type="button" label="{{'STATUS-MONITOR.ADD-STATION' | translate}}" class="p-fluid p-button-success"
      (click)="addStation()"></button>
  </div>
</p-dialog>

<p-dialog header="{{'STATUS-MONITOR.ACTIONS.SETTINGS' | translate}}" [(visible)]="showSettingsDialog" [closable]="true"
  [modal]="true" [resizable]="false" [draggable]="false" [appendTo]="dialog">
  <div class="grid p-fluid" *ngIf="activeTab && activeTab.options">
    <div class="field col-9">
      <label>{{'STATUS-MONITOR.NAME' | translate}}</label>
      <input type="text" pInputText [(ngModel)]="activeTab.label" />
    </div>
    <div class="field col-12 md:col-3">
      <label>{{'STATUS-MONITOR.NUMBER-OF-COLUMNS' | translate}}</label>
      <p-inputNumber [ngModel]="activeTab.options.minCols" [showButtons]="true" [min]="1" [max]="20"
        (onInput)="setActiveTabCols($event)"></p-inputNumber>
    </div>
    <div class="field col-12" *ngIf="layout.length > 1">
      <button pButton type="button" label="Tab löschen" class="p-button-danger" (click)="deleteActiveTab()"></button>
    </div>
  </div>
</p-dialog>

<p-dialog header="{{'STATUS-MONITOR.CHOOSE-LAYOUT' | translate}}" [(visible)]="showLayoutChooseDialog" [closable]="false" [modal]="true"
  [resizable]="false" [appendTo]="dialog" [draggable]="false" [style]="{width: '50%'}">
  <p-table [value]="defaultLayouts" selectionMode="single" (onRowSelect)="selectLayout($event.data.layout)"
    responsiveLayout="scroll">
    <ng-template pTemplate="body" let-layout>
      <tr [pSelectableRow]="layout">
        <td>{{layout.name}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-contextMenu #vehicleContextMenu [model]="contextMenuItems" appendTo="body"></p-contextMenu>