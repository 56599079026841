<p-tabView>
  <p-tabPanel header="{{ 'AAO-EDITOR.KEYWORDS' | translate }}">
    <div class="grid">
      <div class="col-2">
        <p-panel header="{{ 'AAO-EDITOR.KEYWORDS' | translate }}">
          <ng-template pTemplate="icons">
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-plus"
              class="p-panel-header-icon p-link" (click)="newKeyword()"
              pTooltip="{{ 'AAO-EDITOR.CREATE-NEW-KEYWORD' | translate }}" tooltipPosition="top" showDelay="1000"
              hideDelay="500"></button>
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-copy"
              class="p-panel-header-icon p-link" (click)="duplicateKeyword()"
              pTooltip="{{ 'AAO-EDITOR.COPY-KEYWORD' | translate }}" tooltipPosition="top" showDelay="1000"
              hideDelay="500"></button>
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-sort-alt"
              class="p-panel-header-icon p-link" (click)="saveKeywords()"
              pTooltip="{{ 'AAO-EDITOR.SAVE-KEYWORD-SORT' | translate }}" tooltipPosition="top" showDelay="1000"
              hideDelay="500"></button>
          </ng-template>

          <p-table [value]="keywords" selectionMode="single" [(selection)]="selectedKeyword" dataKey="name"
            [scrollable]="true" [rows]="100" scrollHeight="calc(100vh - 516px)" (onRowSelect)="selectKeyword()"
            (onRowUnselect)="unselectKeyword()" [reorderableColumns]="true">
            <ng-template pTemplate="body" let-keyword let-index="rowIndex">
              <tr [pSelectableRow]="keyword" [pReorderableRow]="index">
                <td pReorderableRowHandle>{{keyword.name}}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>

        <p-panel header="{{ 'AAO-EDITOR.FUNNY-BUTTONS' | translate }}" [style]="{'padding-top': '10px'}">
          <div class="box">
            <div class="field p-fluid">
              <button pButton type="button" label="{{ 'AAO-EDITOR.EXPORT-KEYWORDS' | translate }}"
                (click)="openExportDialog()"></button>
            </div>
            <div class="field p-fluid">
              <button pButton type="button" label="{{ 'AAO-EDITOR.IMPORT-KEYWORDS' | translate }}"
                (click)="showImportDialog = true"></button>
            </div>

            <div class="field p-fluid">
              <button pButton type="button" label="{{ 'AAO-EDITOR.RESET-KEYWORDS' | translate }}"
                (click)="resetKeywords()"></button>
            </div>
            <div class="field p-fluid">
              <button pButton type="button" label="{{ 'AAO-EDITOR.DELETE-KEYWORDS' | translate }}"
                (click)="deleteAllKeywords()"></button>
            </div>
          </div>
        </p-panel>
      </div>

      <div class="col">
        <p-panel
          header="{{ (isNewKeyword ? 'AAO-EDITOR.CREATE-NEW-KEYWORD' : 'AAO-EDITOR.EDIT-KEYWORD') | translate }}">
          <ng-template pTemplate="icons">
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-save"
              class="p-panel-header-icon p-link" (click)="saveKeyword()"
              pTooltip="{{'AAO-EDITOR.SAVE-KEYWORD' | translate}}"></button>

            <button pButton type="button" *ngIf="!isNewKeyword" [style]="{'float':'right', 'margin-left': '5px'}"
              icon="pi pi-trash" class="p-panel-header-icon p-link" (click)="deleteKeyword(editKeyword.name)"></button>
          </ng-template>

          <div class="p-fluid box">
            <div class="field grid">
              <label for="name" class="col-1">{{ 'AAO-EDITOR.NAME' | translate }}:</label>
              <div class="col">
                <input name="name" [(ngModel)]="editKeyword.name" type="text" pInputText>
              </div>
              <label for="category" class="col-1">{{ 'AAO-EDITOR.CATEGORY' | translate }}:</label>
              <div class="col">
                <p-dropdown [options]="categories" [(ngModel)]="editKeyword.category" optionLabel="name"
                  optionValue="name" placeholder="{{ 'OTHER.SELECT' | translate }}"></p-dropdown>
              </div>
              <label for="increase" class="col-1">{{ 'AAO-EDITOR.INCREASE' | translate }}:</label>
              <div class="col">
                <p-autoComplete [(ngModel)]="editKeyword.increase" [suggestions]="filteredKeywordSingle"
                  (completeMethod)="filterKeywordSingle($event)" [size]="30" [minLength]="2" [dropdown]="true">
                </p-autoComplete>
              </div>
            </div>

            <div class="field grid">
              <label for="sosi" class="col-fixed" style="width:200px">{{ 'AAO-EDITOR.SOSI' | translate }}:</label>
              <div class="col">
                <p-checkbox name="sosi" value="Sondersignal" [(ngModel)]="editKeyword.sosi" binary="true"></p-checkbox>
              </div>
            </div>

            <div class="grid field">
              <div class="col">
                <button pButton type="button" label="ELW"
                  (click)="editKeyword.vehicleList.push({ type: 'ELW', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="HLF"
                  (click)="editKeyword.vehicleList.push({ type: 'HLF', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="LF"
                  (click)="editKeyword.vehicleList.push({ type: 'LF', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="DLK"
                  (click)="editKeyword.vehicleList.push({ type: 'DLK', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="RTW"
                  (click)="editKeyword.vehicleList.push({ type: 'RTW', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="NEF"
                  (click)="editKeyword.vehicleList.push({ type: 'NEF', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col">
                <button pButton type="button" label="STW"
                  (click)="editKeyword.vehicleList.push({ type: 'FUSTW', restriction: null, id: math.floor((math.random() * 100000) + 1)})"></button>
              </div>
              <div class="col-2">
                <button pButton type="button" label="{{'DEPARTMENTS.TEC' | translate}}"
                  (contextmenu)="openCm($event, addContextMenu)" (click)="openCm($event, addContextMenu)"
                  (keyup.enter)="openCm($event, addContextMenu)"></button>
              </div>
            </div>

            <div class="field grid">
              <div class="col">
                <p-table [value]="editKeyword.vehicleList" [scrollable]="true" scrollHeight="calc(100vh - 437px)"
                  [reorderableColumns]="true">
                  <ng-template pTemplate="colgroup">
                    <colgroup>
                      <col>
                      <col>
                      <col>
                      <col style="width:44px">
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:3rem"></th>
                      <th>{{ 'AAO-EDITOR.VEHICLE' | translate }}</th>
                      <th>{{ 'AAO-EDITOR.PRIO' | translate }}</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-vehicle let-index="rowIndex">
                    <tr [pReorderableRow]="index">
                      <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                      </td>
                      <td>
                        {{vehicles[vehicle.type] ? ('VEHICLE-TYPES.' + vehicle.type | translate) + ' (' + vehicle.type +
                        ')' : ('AAO-EDITOR.VEHICLE-TYPE-NOT-FOUND' | translate) + ' (' + vehicle.type + ')'}}
                      </td>

                      <td pEditableColumn>
                        <p-cellEditor>
                          <ng-template pTemplate="input" *ngIf="vehicles[vehicle.type].restriction.length > 0">
                            <p-dropdown [options]="restrictions2" [(ngModel)]="vehicle.restriction"
                              [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
                          </ng-template>
                          <ng-template pTemplate="output" *ngIf="vehicles[vehicle.type].restriction.length > 0">
                            {{restrictions[vehicle.restriction] ? restrictions[vehicle.restriction] :
                            ('AAO-EDITOR.NO-PRIO' | translate) }}
                          </ng-template>
                          <ng-template pTemplate="output" *ngIf="vehicles[vehicle.type].restriction.length == 0">
                            {{'AAO-EDITOR.NO-PRIO' | translate}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td><button pButton type="button" icon="pi pi-trash" (click)="deleteVehicle(vehicle.id)"
                          pTooltip="{{'AAO-EDITOR.REMOVE-VEHICLE-TOOLTIP' | translate}}" tooltipPosition="top"
                          showDelay="1000" hideDelay="500"></button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="{{ 'AAO-EDITOR.CATEGORIES' | translate }}">
    <div class="grid">
      <div class="col-2">
        <p-panel header="{{ 'AAO-EDITOR.CATEGORIES' | translate }}">
          <ng-template pTemplate="icons">
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-plus"
              class="p-panel-header-icon p-link" (click)="newCategory()"
              pTooltip="{{ 'AAO-EDITOR.CREATE-NEW-CATEGORY' | translate }}" tooltipPosition="top" showDelay="1000"
              hideDelay="500"></button>
          </ng-template>

          <p-table [value]="categories" selectionMode="single" [(selection)]="selectedCategory" dataKey="name"
            [scrollable]="true" [rows]="100" scrollHeight="calc(100vh - 297px)" (onRowSelect)="selectCategory()"
            (onRowUnselect)="unselectCategory()">
            <ng-template pTemplate=" header">
              <tr>
                <th>{{ 'AAO-EDITOR.CATEGORY' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-category>
              <tr [pSelectableRow]="category">
                <td>{{category.name}}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>

      <div class="col">
        <p-panel header="{{ (isNewCategory ? 'AAO-EDITOR.NEW-CATEGORY' : 'AAO-EDITOR.EDIT-CATEGORY') | translate }}">
          <ng-template pTemplate="icons">
            <button pButton type="button" [style]="{'float':'right', 'margin-left': '5px'}" icon="pi pi-save"
              class="p-panel-header-icon p-link" (click)="saveCategory()"
              pTooltip="{{ 'AAO-EDITOR.SAVE-CATEGORY' | translate }}"></button>

            <button pButton type="button" *ngIf="!isNewCategory" [style]="{'float':'right', 'margin-left': '5px'}"
              icon="pi pi-trash" class="p-panel-header-icon p-link"
              (click)="deleteCategory(editCategory.name)"></button>
          </ng-template>

          <div class="p-fluid box">
            <div class="field grid">
              <label for="name" class="col-fixed" style="width:200px">{{ 'AAO-EDITOR.NAME' | translate }}</label>
              <div class="col">
                <input name="name" [(ngModel)]="editCategory.name" type="text" pInputText>
              </div>
            </div>

            <div class="field grid">
              <label for="color" class="col-fixed" style="width:200px">{{ 'AAO-EDITOR.COLOR' | translate }}:</label>
              <div class="col">
                <p-dropdown [options]="colors" [(ngModel)]="editCategory.color">
                  <ng-template pTemplate="selectedItem" let-color>
                    <div [style]="{'color': color.value}">
                      <div>{{color.label}}</div>
                    </div>
                  </ng-template>
                  <ng-template let-color pTemplate="item">
                    <div [style]="{'color': color.value}">
                      <div>{{color.label}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>

              </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

<p-contextMenu #addContextMenu [model]="addContextMenuItems" appendTo="body"></p-contextMenu>

<p-dialog header="AAO exportieren" [(visible)]="showExportDialog" [modal]="true" [draggable]="false" [resizable]="false"
  [closable]="false">

  <div class="p-fluid">
    <input pInputText [ngModel]="exportString" #output>
    <p-button icon="pi pi-save" label="In Zwischenablage kopieren" styleClass="p-button-text"
      (click)="copyInputMessage(output)"></p-button>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Fertig" styleClass="p-button-text" (click)="showExportDialog = false">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="AAO importieren" [(visible)]="showImportDialog" [modal]="true" [draggable]="false" [resizable]="false"
  [closable]="false">

  <div class="p-fluid">
    <input pInputText [(ngModel)]="importString">
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" label="Abbrechen" styleClass="p-button-text" (click)="showImportDialog = false">
    </p-button>
    <p-button icon="pi pi-check" label="Importieren" styleClass="p-button-text" (click)="importKeywords()">
    </p-button>
  </ng-template>
</p-dialog>