import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CompactType, DisplayGrid, GridsterComponent, GridsterConfig, GridsterItem, GridsterItemComponentInterface, GridType } from 'angular-gridster2';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { Hospital, HospitalService } from 'src/app/services/hospital.service';
import { Station, StationService } from 'src/app/services/station.service';
import { StorageService } from 'src/app/services/storage.service';
import { VariableService } from 'src/app/services/variable.service';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { MessageService } from 'primeng/api';
import { Settings, SettingsService } from 'src/app/services/settings.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

enum ItemType {
  Vehicle,
  Brigade,
  Group,
  Tab,
  Station
}

@Component({
  selector: 'app-status-monitor2',
  templateUrl: './status-monitor2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StatusMonitor2Component implements OnInit, OnDestroy, OnChanges {
  settings: Settings
  @Input() action: string;
  @Output() actionChange = new EventEmitter<string>();
  @Output() actions = new EventEmitter<any>();
  @Output() tabs = new EventEmitter<any>();
  @Input() height: number;

  layout: any;
  defaultOptions: GridsterConfig;
  editMode = false;
  mapAssetId = null;

  vehicleSubscription: Subscription;
  vehicles: Vehicle[] = [];
  selectedVehicles: string[] = []

  stationSubscription: Subscription;
  stations: Station[] = [];

  hospitalSubscription: Subscription;
  hospitals: Hospital[] = [];

  activeTab: any = null
  contextMenuItems: MenuItem[];

  currentEvent = 0;

  showAddDialog = false;
  addDialogOptions: any = [];
  addDialogSelectedOption: any = null;
  addDialogName: string = "";
  addDialogSelectedVehicles: any[] = null;
  addDialogFilteredVehicleSingle = [];
  addDialogSelectedVehicle: any = null;
  addDialogFilteredStationSingle = [];
  addDialogSelectedStation: any = null;
  addDialogIsNotAlertable: boolean = false;

  showSettingsDialog = false;

  showLayoutChooseDialog = false;
  defaultLayouts = [];

  constructor(
    private vehicleService: VehicleService,
    private stationService: StationService,
    private hospitalService: HospitalService,
    private eventService: EventService,
    private storageService: StorageService,
    private settingsService: SettingsService,
    private variableService: VariableService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {
    this.vehicles = this.vehicleService.getVehicles();
    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        this.vehicles.push(vehicle);
      } else {
        data = vehicle;
      }
      this.changeDetectorRef.detectChanges();
    });

    this.eventService.selectedVehicles.subscribe(vehicles => {
      this.selectedVehicles = [...vehicles];
      this.changeDetectorRef.markForCheck();
    })

    this.stationSubscription = this.stationService.getUpdate().subscribe(station => {
      let data = this.stations.find(x => x.id === station.id);
      if (data == null) {
        this.stations.push(station);
        // if (true == true) {
        //   console.log("LAYOUT", this.layout)
        //   this.layout[0].items.push({ cols: station.smCols, rows: station.smRows, y: station.smY, x: station.smX, stationId: station.id, name: station.name, type: 4, maxItemRows: 20 });
        // }
      } else {
        data = station;
      }
      this.changeDetectorRef.detectChanges();
    });

    this.hospitals = this.hospitalService.getHospitals();
    this.hospitalSubscription = this.hospitalService.getUpdate().subscribe(hospital => {
      let data = this.hospitals.find(x => x.name === hospital.name);
      if (data == null) {
        this.hospitals.push(hospital);
      } else {
        data = hospital;
      }
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.mapAssetId.subscribe(mapAssetId => {
      this.mapAssetId = mapAssetId;
      if (this.mapAssetId) this.loadLayout();
    });

    this.variableService.selectedEvent.subscribe(event => {
      this.currentEvent = event;
    });

    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  ngOnInit(): void {
    this.actions.emit([
      { tooltip: 'Dashboard bearbeiten', icon: 'pi pi-pencil', action: 'edit' },
      { tooltip: 'Einstellungen', icon: 'pi pi-align-justify', action: 'settings' },
      { tooltip: 'Dashboard speichern', icon: 'pi pi-save', action: 'save' },
      { tooltip: 'Tab hinzufügen', icon: 'pi pi-plus', action: 'add' },
      { tooltip: 'Status-Monitor zurücksetzen', icon: 'pi pi-directions-alt', action: 'reset' },
      // { tooltip: 'Debug', icon: 'pi pi-bug', action: 'debug' },
      // { tooltip: 'Alte Wachen hinzufügen', icon: 'pi pi-bug', action: 'debug-add' },
    ]);

    this.defaultOptions = {
      gridType: GridType.VerticalFixed,
      compactType: CompactType.None,
      pushItems: false,
      draggable: {
        enabled: false,
        dragHandleClass: 'p-panel-header',
        ignoreContent: false
      },
      resizable: {
        enabled: false
      },
      allowMultiLayer: true,
      defaultLayerIndex: 1,
      baseLayerIndex: 2,
      maxLayerIndex: 2,
      outerMargin: false,
      maxItemRows: 1,
      minItemRows: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedRowHeight: 20,
      minCols: 5,
      maxCols: 5,
      itemChangeCallback: this.itemChangeCallback
    };

    if (!this.layout) {
      this.layout = [
        {
          command: () => { this.changeTab(0) },
          label: 'Standard',
          items: [],
          visible1: false,
          options: { ...this.defaultOptions }
        }
      ];
    }
    this.activeTab = this.layout[0];
    setTimeout(() => {
      this.tabs.emit(this.layout);
      this.changeTab(0);
    }, 200);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.refreshMenus();
    })
    this.refreshMenus();
  }

  refreshMenus() {
    this.addDialogOptions = [
      { label: this.translateService.instant("STATUS-MONITOR.TAB"), value: ItemType.Tab },
      { label: this.translateService.instant("STATUS-MONITOR.STATION"), value: ItemType.Station },
      // { label: 'Fahrzeug', value: ItemType.Vehicle },
      { label: this.translateService.instant("STATUS-MONITOR.BRIGADE"), value: ItemType.Brigade },
      // { label: 'Gruppe (Style-Element)', value: ItemType.Group },
    ];
  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
  }

  ngOnChanges($event): void {
    if (this.layout == null) return;
    if ($event.action?.currentValue?.action == 'button') {
      switch ($event.action.currentValue.data) {
        case 'edit': {
          this.editMode = !this.editMode;
          this.layout.forEach(group => {
            if (group.options.api == null) return;
            group.options.draggable.enabled = this.editMode
            group.options.resizable.enabled = this.editMode
            group.options.api.optionsChanged();
            group.options.api.resize();
          });
          break;
        }
        case 'save': this.saveLayout(); break;
        case 'add': this.openAddDialog(); break;
        case 'settings': this.showSettingsDialog = true; break;
        case 'debug': console.log(this.layout); this.changeDetectorRef.detectChanges(); break;
        case 'debug-add': this.loadOldLayout(); break;
        case 'reset': {
          this.confirmationService.confirm({
            message: this.translateService.instant("STATUS-MONITOR.RESET-CONFIRMATION"),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.storageService.remove("layout_sm2_" + this.mapAssetId);

              this.layout = [
                {
                  command: () => { this.changeTab(0) },
                  label: 'Standard',
                  items: [],
                  visible1: false,
                  options: { ...this.defaultOptions }
                }
              ];

              this.resetToDefaultLayout();
            },
            acceptLabel: this.translateService.instant("OTHER.YES"),
            rejectLabel: this.translateService.instant("OTHER.NO")
          });
          break;
        }
      }
    }

    // if ($event.action?.currentValue?.action == 'tab') {
    //   switch ($event.action.currentValue.data) {
    //     case 'edit': {
    //       this.editMode = !this.editMode;
    //       this.layout.forEach(group => {
    //         if (group.options.api == null) return;
    //         group.options.draggable.enabled = this.editMode
    //         group.options.resizable.enabled = this.editMode
    //         group.options.api.optionsChanged();
    //         group.options.api.resize();
    //       });
    //       break;
    //     }
    //     case 'add': this.openAddDialog(); this.addDialogSelectedOption = 0; break;
    //     case 'settings': this.showSettingsDialog = true; break;
    //     case 'debug': console.log(this.layout); break;
    //   }
    // }
    this.actionChange.emit(null);
  }

  async loadLayout() {
    const layout = await this.storageService.get("layout_sm2_" + this.mapAssetId);
    if (layout) {
      for (let i = 0; i < layout.length; i++) {
        layout[i].command = () => { this.changeTab(i) }
      }
      this.layout = layout;

      this.tabs.emit(this.layout);
      this.changeTab(0);
    } else {
      this.resetToDefaultLayout();
    }
  }

  resetToDefaultLayout() {
    this.variableService.defaultStatusMonitorLayouts.subscribe(layouts => {
      layouts = layouts.filter(x => x.mapAssetId == this.mapAssetId);
      layouts.push({
        name: "Leer", layout: [{
          command: () => { this.changeTab(0) },
          label: 'Standard',
          items: [],
          visible1: false,
          options: { ...this.defaultOptions }
        }]
      })
      if (layouts.length > 1) {
        this.defaultLayouts = [...layouts];
        this.showLayoutChooseDialog = true;
      } else if (layouts.length == 1) {
        this.selectLayout({ ...layouts[0].layout });
      }
    })
    this.changeDetectorRef.detectChanges();
  }

  selectLayout(layout) {
    this.showLayoutChooseDialog = false;

    for (let i = 0; i < layout.length; i++) {
      layout[i].command = () => { this.changeTab(i) }
    }

    this.layout = [...layout];
    this.tabs.emit(this.layout);
    this.changeTab(0);

    this.changeDetectorRef.detectChanges();
  }

  loadOldLayout() {
    for (const station of this.stations) {
      // this.layout[0].items.push({ cols: station.smCols, rows: station.smRows, y: station.smY, x: station.smX, stationId: station.id, name: station.name, type: 4, maxItemRows: 20 });
      this.addItem({ type: ItemType.Station, stationIdentifier: station.identifier, name: station.name, maxItemRows: 20, rows: this.getVehiclesByStationId(station.id).length + 2, y: station.smY, x: station.smX })
    }

  }

  saveLayout() {
    // this.layoutSaveEmitter.emit(this.layout);
    if (this.mapAssetId) {
      this.storageService.set("layout_sm2_" + this.mapAssetId, this.layout)
    }
  }

  itemChangeCallback(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
    item.width = itemComponent.width;
    item.height = itemComponent.height;

    // this.layoutSaveEmitter.emit(this.layout);
  }

  @ViewChild("gridster") gridster: GridsterComponent;
  changeTab(no) {
    this.layout.forEach((element, i) => {
      if (i === no) {
        element.visible1 = true;
        element.items.push({ type: 15, name: "vehicleName12", label: "Fahrzeug 2.1", layerIndex: 0 })
        setTimeout(() => { element.items.pop() }, 1000)
        this.activeTab = element;
        this.changeDetectorRef.detectChanges();
      }
      else element.visible1 = false;
    });
    this.changeDetectorRef.detectChanges();
  }

  openContextMenu(event, cm, vehicle: Vehicle) {
    this.contextMenuItems = [
      { label: vehicle.displayName },
      { separator: true },
      {
        label: this.translateService.instant("VEHICLE-CONTEXT.SET-STATUS"), items: [
          { label: 'Status 1', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 1) },
          { label: 'Status 3', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 3) },
          { label: 'Status 4', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 4) },
          { label: 'Status 6', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 6) },
          { label: 'Status 7', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 7) },
          { label: 'Status 8', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 8) }
        ]
      },
      { label: this.translateService.instant("VEHICLE-CONTEXT.SPEECH-PROMPT"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 28) }
    ];

    if (vehicle.type2.includes('RTW') || vehicle.type2.includes('KTW') || vehicle.type2.includes('PTZ') || vehicle.type2.includes('ITW') || vehicle.type2.includes('RTH') || vehicle.type2.includes('IRTW') || vehicle.type2.includes('GRTW') || vehicle.type2.includes('NAW')) {
      const hospitals = [];
      this.hospitals.forEach(hospital => {
        hospitals.push({ label: hospital.name, command: (x) => this.vehicleService.assignToHospital(vehicle.name, hospital.name) });
      });

      this.contextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.ASSIGN-HOSPITAL"), items: hospitals
      });
    } else if (vehicle.type2.includes('FUSTW') && vehicle.status == 2) {
      this.contextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.SET-PATROL"), command: (x) => {
          this.vehicleService.patrol(vehicle.name);
        }
      });
    }

    this.contextMenuItems.push({ separator: true });

    if (vehicle.currentEvent > 0) {
      this.contextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.SELECT-EVENT"), command: (x) => {
          this.eventService.selectEvent(vehicle.currentEvent);
        }
      });
    }

    this.contextMenuItems.push({ label: this.translateService.instant("VEHICLE-CONTEXT.RESET-VEHICLE"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 27) });

    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  openAddDialog() {
    this.addDialogSelectedOption = ItemType.Tab;
    this.addDialogName = "";
    this.addDialogSelectedVehicles = [];
    this.addDialogFilteredVehicleSingle = [];
    this.addDialogSelectedVehicle = null;
    this.addDialogFilteredStationSingle = [];
    this.addDialogSelectedStation = null;
    this.addDialogIsNotAlertable = false;

    this.showAddDialog = true;
  }

  addVehicle() {
    // this.activeTab.items.push({ type: ItemType.Vehicle, name: $event.data.vehicleName, label: $event.data.displayName, layerIndex: 5, x: 0, y: 0 })
    // this.addItem({ type: ItemType.Vehicle, stationId: 30380, name: this.addDialogSelectedVehicle.displayName, layerIndex: 5, resizeEnabled: false })
    this.showAddDialog = false;
  }

  addBrigade() {
    if (this.addDialogName == "") {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant("OTHER.ERROR"), detail: this.translateService.instant("STATUS-MONITOR.BIGARDE-NAME-ERROR") });
      return;
    }

    const vehicles = []
    for (const veh of this.addDialogSelectedVehicles) vehicles.push(veh.name)
    this.addItem({ type: ItemType.Brigade, vehicles: vehicles, name: this.addDialogName, isNotAlertable: this.addDialogIsNotAlertable, maxItemRows: 20, rows: 2 })
    this.showAddDialog = false;
  }

  addGroup() {
    this.showAddDialog = false;
  }

  addTab() {
    if (this.addDialogName == "") {
      this.messageService.add({ severity: 'error', summary: this.translateService.instant("OTHER.ERROR"), detail: this.translateService.instant("STATUS-MONITOR.TAB-NAME-ERROR") });
      return;
    }

    let pos = this.layout.length;
    this.layout.push({
      command: () => { this.changeTab(pos) },
      label: this.addDialogName,
      items: [],
      options: { ...this.defaultOptions }
    });
    this.showAddDialog = false;
    this.activeTab = this.layout[pos];
    this.changeTab(pos);
    this.showSettingsDialog = true;
    this.tabs.emit(this.layout);
  }

  addStation() {
    if (this.addDialogName == "") this.addDialogName = this.addDialogSelectedStation.name;
    this.addItem({ type: ItemType.Station, stationIdentifier: this.addDialogSelectedStation.identifier, name: this.addDialogName, maxItemRows: 20, rows: this.getVehiclesByStationId(this.addDialogSelectedStation.id).length + 2 })
    this.showAddDialog = false;
  }

  setActiveTabCols($event) {
    this.activeTab.options.minCols = $event.value;
    this.activeTab.options.maxCols = $event.value;
    this.activeTab.options.api.optionsChanged();
  }

  deleteActiveTab() {
    if (this.layout.length <= 1) return;
    this.layout.splice(this.layout.indexOf(this.activeTab), 1);
    this.changeTab(0);
    this.showSettingsDialog = false;
    this.tabs.emit(this.layout);
  }

  addToEvent(event, vehicle) {
    if (vehicle.status == 1 || vehicle.status == 2) {
      this.eventService.addVehicleToEvent(vehicle.name);
    } else if (vehicle.status == 3 || vehicle.status == 4 || vehicle.status == "C") {
      this.eventService.selectEvent(vehicle.currentEvent);
    }
  }

  addItem(data) {
    this.activeTab.items.push({ x: 0, y: 0, cols: 1, rows: 1, ...data });
  }

  removeItem($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    this.activeTab.items.splice(this.activeTab.items.indexOf(item), 1);
  }

  filterVehicleSingle(event) {
    const query = event.query;
    const filter = [];

    this.vehicles.forEach(vehicle => {
      if (vehicle.displayName.toLowerCase().includes(query.toLowerCase())) {
        filter.push(vehicle);
      }
    });

    filter.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));

    this.addDialogFilteredVehicleSingle = filter;
  }

  filterStationSingle(event) {
    const query = event.query;
    const filter = [];

    this.stations.forEach(station => {
      if (station.name.toLowerCase().includes(query.toLowerCase())) {
        filter.push(station);
      }
    });

    filter.sort((a, b) => (a.name < b.name ? -1 : 1));

    this.addDialogFilteredStationSingle = filter;
  }

  getVehiclesByNames(vehicleNames) {
    return this.vehicles.filter(x => vehicleNames.includes(x.name));
  }

  getVehiclesByStationId(stationId) {
    return this.vehicles.filter(x => x.stationId == stationId).sort((a, b) => (a.type < b.type ? -1 : 1));;
  }

  getVehiclesByStationIdentifier(stationIdentifier) {
    return this.vehicles.filter(x => x.station == stationIdentifier).sort((a, b) => (a.type < b.type ? -1 : 1));;
  }

  addBrigadeToEvent(vehicles) {
    // Party-Mode
    if (this.settings.partyMode && vehicles.some(x => x.startsWith("oerk_"))) {
      let audio = new Audio('assets/sounds/abfahrt.mp3');
      audio.volume = this.settings.audio.volume;
      audio.play();
    }

    const vehs = this.getVehiclesByNames(vehicles);
    if (vehs) {
      for (const veh of vehs) {
        if (veh.status == 1 || veh.status == 2) {
          this.eventService.addVehicleToEvent(veh.name);
        }
      }
    }
  }

  isSelectedVehicle(vehicleName) {
    if (this.selectedVehicles.includes(vehicleName)) return true;
    return false;
  }

  isVehicleInCurrentEvent(vehicle) {
    if (this.settings.statusMonitor.highlightVehiclesInCurrentEvent && this.currentEvent != 0 && vehicle.currentEvent == this.currentEvent) return true;
    return false;
  }
}
