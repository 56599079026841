import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SocketIOService } from './socket-io.service';
import { Settings, SettingsService } from './settings.service';
import { EventService } from './event.service';
import { HospitalService } from './hospital.service';

export interface Vehicle {
  name: string;
  displayName: string;
  displayNameEn: string;
  type: string;
  type2: string[];
  stationId: number;
  station: string;
  status: any;
  posX: number;
  posY: number;
  distanceOffset: number;
  currentEvent: number;
  previousEvent: number;
  hasCallRequest: number;
  alertTime: Date;
  requestTime: Date;
  blockedByLst: string;
  signal: boolean;
  statusChanges?: any;

  // Frontend only variables
  distance: number;
  displayedDistance: number;
}

@Injectable({
  providedIn: 'root'
})

export class VehicleService {
  settings: Settings;
  private vehicles: Vehicle[] = [];
  private vehicle = new Subject<Vehicle>();

  constructor(
    private eventService: EventService,
    private hospitalService: HospitalService,
    private socketService: SocketIOService,
    private settingsService: SettingsService,
  ) {
    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  public getUpdate(): Observable<Vehicle> {
    return this.vehicle.asObservable();
  }

  resetVehicles() {
    this.vehicles = [];
  }

  getVehicles(): Vehicle[] {
    return this.vehicles;
  }

  updateVehicle(vehicle: Vehicle) {
    const data = this.vehicles.find(x => x.name === vehicle.name);
    if (data == null) {
      if (!this.settings.useGermanDisplayNames && vehicle.displayNameEn != null) {
        vehicle.displayName = vehicle.displayNameEn;
      }
      this.vehicles.push(vehicle);
      this.vehicle.next(vehicle);
    } else {
      if (vehicle.type !== undefined && vehicle.type !== data.type) {
        data.type = vehicle.type;
      }

      if (vehicle.type2 !== undefined && vehicle.type2 !== data.type2) {
        data.type2 = vehicle.type2;
      }

      if (vehicle.stationId !== undefined && vehicle.stationId !== data.stationId) {
        data.stationId = vehicle.stationId;
      }

      if (vehicle.station !== undefined && vehicle.station !== data.station) {
        data.station = vehicle.station;
      }

      if (vehicle.status !== undefined && vehicle.status !== data.status) {
        data.status = vehicle.status;
      }

      if (vehicle.posX !== undefined && vehicle.posX !== data.posX) {
        data.posX = vehicle.posX;
      }

      if (vehicle.posY !== undefined && vehicle.posY !== data.posY) {
        data.posY = vehicle.posY;
      }

      if (vehicle.distanceOffset !== undefined && vehicle.distanceOffset !== data.distanceOffset) {
        data.distanceOffset = vehicle.distanceOffset;
      }

      if (vehicle.currentEvent !== undefined && vehicle.currentEvent !== data.currentEvent) {
        data.currentEvent = vehicle.currentEvent;
      }

      if (vehicle.previousEvent !== undefined && vehicle.previousEvent !== data.previousEvent) {
        data.previousEvent = vehicle.previousEvent;
      }

      if (vehicle.hasCallRequest !== undefined && vehicle.hasCallRequest !== data.hasCallRequest) {
        data.hasCallRequest = vehicle.hasCallRequest;
      }

      if (vehicle.alertTime !== undefined && vehicle.alertTime !== data.alertTime) {
        data.alertTime = vehicle.alertTime;
      }

      if (vehicle.requestTime !== undefined && vehicle.requestTime !== data.requestTime) {
        data.requestTime = vehicle.requestTime;
      }

      if (vehicle.blockedByLst !== undefined && vehicle.blockedByLst !== data.blockedByLst) {
        data.blockedByLst = vehicle.blockedByLst;
      }

      if (vehicle.signal !== undefined && vehicle.signal !== data.signal) {
        data.signal = vehicle.signal;
      }

      if (vehicle.statusChanges !== undefined && vehicle.statusChanges !== data.statusChanges) {
        data.statusChanges = vehicle.statusChanges;
      }

      this.vehicle.next(data);
    }
  }

  patrol(vehicleName) {
    this.socketService.socketEmit('lst', { cmd: 'patrol', data: { vehicleName } });
  }

  setVehicleStatus(vehicleName, status) {
    this.socketService.socketEmit('lst', { cmd: 'setVehicleStatus', data: { name: vehicleName, status } });
  }

  assignToHospital(vehicleName: string, hospitalName: string) {
    this.socketService.socketEmit('lst', { cmd: 'assignVehicleToHospital', data: { vehicleName, hospitalName } });
    if (!this.settings.eventView.sendMessageOnHospitalAssignment) return;
    
    const vehicle = this.vehicles.find(x => x.name === vehicleName);
    if (vehicle) {
      const message = `${this.settings.useGermanDisplayNames ? vehicle.displayName : vehicle.displayNameEn} mit Voranmeldung: ${hospitalName}`;
      this.socketService.socketEmit('lst', { cmd: 'addEventMessage', data: { id: vehicle.currentEvent, message } });
    }
  }

  setCallRequest(vehicleName: string, state: number) {
    this.socketService.socketEmit('lst', { cmd: 'setVehicleCallRequest', data: { vehicleName, state } });
  }

  blockVehicle(vehicleName: string) {
    this.socketService.socketEmit('lst', { cmd: 'blockVehicle', data: { vehicleName } });
  }

  unblockVehicle(vehicleName: string) {
    this.socketService.socketEmit('lst', { cmd: 'unblockVehicle', data: { vehicleName } });
  }

}
