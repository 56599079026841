<div class="box">
  <div class="grid p-fluid">
    <div class="col-1">
      <div class="ui-float-label">
        <label for="eventIdFloatInput">{{ 'EVENT.ID' | translate }}</label>
        <input id="eventIdFloatInput" type="text" size="15" pInputText [(ngModel)]="eventId" readonly [disabled]="disableEventForm">
      </div>
    </div>

    <div class="col">
      <div class="ui-float-label">
        <label for="eventTextFloatInput">{{ 'EVENT.TEXT' | translate }}</label>
        <input id="eventTextFloatInput" type="text" size="15" pInputText [(ngModel)]="eventText" readonly [disabled]="disableEventForm">
      </div>
    </div>

    <div class="col-3">
      <div class="ui-float-label">
        <label for="eventStartTimeFloatInput">{{ 'OTHER.TIMESTAMP' | translate }}</label>
        <input id="eventStartTimeFloatInput" type="text" size="15" pInputText [(ngModel)]="eventStartTime" readonly [disabled]="disableEventForm">
      </div>
    </div>

    <div class="col-2" style="margin-top: 15px;">
      <div class="ui-float-label">
      <button pButton type="button" label="{{ 'EVENT-VIEW.ALERT' | translate }}" (click)="alertNewVehicles()"
        [disabled]="disableEventForm"></button>
      </div>
    </div>
  </div>

  <div class="grid p-fluid keywordFix">
    <div class="col">
      <div>
        <label>{{ 'EVENT.KEYWORDS' | translate }}</label>
        <p-autoComplete [(ngModel)]="keyword" [suggestions]="filteredAaoSingle"
          (completeMethod)="filterAaoSingle($event)" [size]="30" [minLength]="2" [dropdown]="true"
          [disabled]="disableEventForm" (keyup.enter)="onKeywordEnter($event)" [multiple]="true">
          <ng-template let-keyword pTemplate="selectedItem"><div [class]="'keyword-' + getKeywordColor(keyword)">{{keyword}}</div>
            <i *ngIf="!disableEventForm && isIncreasable(keyword)" class="pi pi-arrow-circle-up keywordButtonFix"
              (click)="increaseKeyword(keyword)"></i>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="col-1" style="margin-top: 15px;">
      <button pButton type="button" (click)="addAaoVehicles()" [disabled]="disableEventForm"
        pTooltip="{{ 'EVENT-VIEW.APPLY-KEYWORD' | translate }}" tooltipPosition="top" showDelay="1000" hideDelay="500" icon="pi pi-angle-double-right" class="toggleButtonFix"></button>
    </div>
    <div class="col-1" style="margin-top: 15px;">
      <p-toggleButton [(ngModel)]="signal" onIcon="pi pi-check" offIcon="pi pi-times" [disabled]="disableEventForm" pTooltip="{{ 'EVENT-VIEW.SOSI' | translate }}" tooltipPosition="top" styleClass="toggleButtonFix"></p-toggleButton>
    </div>
  </div>

  <div class="grid p-fluid">
    <div class="col-12">
      <span class="p-buttonset">
          <button *ngFor="let button of settings.eventView.fastButtons" pButton type="button" [label]="button.name" [disabled]="disableEventForm"
          (click)="addNearestVehicleOfType2(button.value)"></button>
      </span>
    </div>
  </div>

  <div class="grid p-fluid">
    <div class="col-8">
      <p-autoComplete [(ngModel)]="selectedVehicle" [suggestions]="filteredVehicleSingle"
        (completeMethod)="filterVehicleSingle($event)" [size]="30" [minLength]="2" [dropdown]="true"
        [disabled]="disableEventForm" (keyup.enter)="addVehicle()" (onSelect)="addVehicle()">
      </p-autoComplete>
    </div>
    <div class="col-4">
      <button pButton type="button" label="{{ 'EVENT-VIEW.ADD-FEEDBACK' | translate }}" class="ui-button-raised button"
        (click)="displayMessageDialog = true" [disabled]="disableEventForm"></button>
    </div>

    <div class="col-8">
      <p-table [value]="displayedVehicles" [resizableColumns]="true" [scrollable]="true"
        [scrollHeight]="(height - 191) + 'px'" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn style="flex-basis:5%; justify-content: center;">S</th>
            <th pResizableColumn style="flex-basis:50%;">{{ 'EVENT-VIEW.VEHICLE' | translate }}</th>
            <!-- <th pResizableColumn style="flex-basis:35%;">Alarmzeit</th> -->
            <th pResizableColumn style="flex-basis:15%;">C</th>
            <th pResizableColumn style="flex-basis:15%;">3</th>
            <th pResizableColumn style="flex-basis:15%;">4</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vehicle>
          <tr [pSelectableRow]="vehicle" class="{{vehicle.removed ? 'vehicleRemoved' : ''}}"
            (contextmenu)="openVehicleContextMenu($event, vehicleContextMenu, vehicle)">
            <td *ngIf="vehicle.removable" style="flex-basis:5%; justify-content: center;">
              <i class="pi pi-trash" (click)="removeVehicleFromSelected(vehicle)"></i>
            </td>
            <td *ngIf="!vehicle.removable"
              class="{{vehicle.callRequest > 0 ? 'status5' : (vehicle.status ? 'status' + vehicle.status : 'status6')}}"
              style="flex-basis:5%; justify-content: center;">{{vehicle.status}}</td>
            <td style="flex-basis:50%;" *ngIf="vehicle.removed" (click)="addVehicleDoubleClick(vehicle.name)">{{vehicle.displayName}}</td>
            <td style="flex-basis:50%;" *ngIf="!vehicle.removed">{{vehicle.displayName}}</td>
            <!-- <td style="flex-basis:35%;" (click)="log(vehicle)">{{vehicle.alertTime | date:'HH:mm:ss'}}</td> -->
            <td style="flex-basis:15%;" (click)="log(vehicle)">{{vehicle.statusChanges[0] | date:'HH:mm:ss'}}</td>
            <td style="flex-basis:15%;" (click)="log(vehicle)">{{vehicle.statusChanges[3] | date:'HH:mm:ss'}}</td>
            <td style="flex-basis:15%;" (click)="log(vehicle)">{{vehicle.statusChanges[4] | date:'HH:mm:ss'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="col-4">
      <p-table [value]="messages" [scrollable]="true" [scrollHeight]="(height - 191) + 'px'">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'EVENT-VIEW.FEEDBACKS' | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-message>
          <tr>
            <td class="message">{{message}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog header="Rückmeldung hinzufügen" [(visible)]="displayMessageDialog" [baseZIndex]="50" [draggable]="false"
  [resizable]="false" [modal]="true" [closable]="true" [style]="{width: '50vh'}">
  <div class="p-fluid">
    <textarea [rows]="5" [cols]="30" pInputTextarea [(ngModel)]="message"></textarea>
    <button pButton type="button" label="Absenden" (click)="addMessage()"></button>
  </div>
</p-dialog>

<p-dialog header="Fahrzeug hinzufügen" [(visible)]="displayVehicleDialog" [baseZIndex]="50" [draggable]="false"
  [resizable]="false" [modal]="true" [closable]="true" [style]="{width: '75%'}" (onHide)="closeVehicleDialog()">
  <div class="grid p-fluid">
    <div class="col">
      <input type="text" placeholder="Filter" pInputText [(ngModel)]="vehicleFilter" (keyup)="getAvailableVehicles()" />
    </div>
    <div class="col">
      <button pButton type="button" label="Hinzufügen" class="ui-button-raised button"
        (click)="addVehicleToEventVehicles()"></button>
    </div>

    <!-- TODO: NIcht nur Drag&Drop sondn auch doppelklick -->
    <div class="grid">
      <div class="col drag-column">
        <p-table [value]="availableVehicles" [scrollable]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Entfernung</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-car>
            <tr pDraggable="cars" (onDragStart)="dragStart($event,car)" (onDragEnd)="dragEnd($event)">
              <td>{{car.displayName}}</td>
              <td>{{car.status}}</td>
              <td>{{round(car.displayedDistance * 500) / 100 + ' km'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- Fahrzeug mit Status 1 / 2 aus EInsatz löschbar machen // Nicht alarmiert -->
      <div class="col drocolumn" pDroppable="cars" (onDrop)="drop($event)"
        [ngClass]="{'ui-highlight-car':draggedVehicle}">
        <p-table [value]="vehicleDialogSelectedVehicles" [scrollable]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-car>
            <tr>
              <td>{{car.displayName}}</td>
              <td>{{car.status}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-dialog>

<p-contextMenu #vehicleContextMenu [model]="vehicleContextMenuItems" appendTo="body"></p-contextMenu>
