import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { VariableService } from 'src/app/services/variable.service';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { Subscription } from 'rxjs';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { EventX, EventService } from 'src/app/services/event.service';
import { MenuItem } from 'primeng/api/menuitem';
import { KeywordService, Keyword, KeywordCategory } from 'src/app/services/keyword.service';
import { Hospital, HospitalService } from 'src/app/services/hospital.service';
import { MessageService } from 'primeng/api';
import { Settings, SettingsService } from 'src/app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  providers: [MessageService]
})
export class EventViewComponent implements OnInit, OnDestroy {
  settings: Settings;

  @Input() height = 0;

  //#region Global Variables
  username = null;
  //#endregion

  //#region Subscriptions
  eventSubscription: Subscription;
  events: EventX[] = [];

  aaos: Keyword[] = null;
  keywordCategories: KeywordCategory[] = [];

  hospitalSubscription: Subscription;
  hospitals: Hospital[] = [];

  vehicleSubscription: Subscription;
  vehicles: Vehicle[] = [];
  //#endregion

  //#region Event Form
  // Event Data
  selectedEvent: EventX = null;
  eventId = null;
  eventText = null;
  eventStartTime = null;
  location = null;

  // AAO
  keyword = [];
  oldKeyword = [];
  filteredAaoSingle = [];
  signal = true;

  // Messages
  displayMessageDialog = false;
  messages = [];
  message = '';

  // Vehicles
  displayVehicleDialog = false;
  displayedVehicles = [];
  availableVehicles = [];
  selectedVehicles = [];
  vehicleDialogSelectedVehicles = [];
  draggedVehicle: Vehicle = null;
  vehicleFilter = '';
  vehicleContextMenuItems: MenuItem[];
  filteredVehicleSingle = [];
  selectedVehicle = null;

  // Other Stuff
  disableEventForm = true;

  //#endregion

  constructor(
    private variableService: VariableService,
    private vehicleService: VehicleService,
    private eventService: EventService,
    private keywordService: KeywordService,
    private hospitalService: HospitalService,
    private socketService: SocketIOService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.vehicles = this.vehicleService.getVehicles();
    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        this.vehicles.push(vehicle);
      } else {
        data = vehicle;
        this.getDisplayedVehicles();
      }
      this.changeDetectorRef.detectChanges();
    });

    this.events = this.eventService.getEvents();
    this.eventSubscription = this.eventService.getUpdate().subscribe(event => {
      let data = this.events.find(x => x.id === event.id);
      if (data == null) {
        this.events.push(event);
      } else {
        data = event;
        if (event.id === this.eventId) {
          this.updateEvent(event);
        }
      }
      this.changeDetectorRef.detectChanges();
    });

    this.hospitals = this.hospitalService.getHospitals();
    this.hospitalSubscription = this.hospitalService.getUpdate().subscribe(hospital => {
      const data = this.hospitals.find(x => x.name === hospital.name);
      if (data == null) {
        this.hospitals.push(hospital);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.deselectEvent();
    // Todo: Einsatz abschließen und Untereinsatz erstellen hinzufügen

    this.settingsService.settings.subscribe(settings => {
      this.settings = { ...settings };
      this.changeDetectorRef.detectChanges();
    })

    this.variableService.selectedEvent.subscribe(x => {
      if (x === 0) {
        this.deselectEvent();
      } else {
        const event = this.events.find(y => y.id === x);
        if (event != null) {
          this.deselectEvent();
          this.selectEvent(event);
        }
      }
      this.changeDetectorRef.detectChanges();
    });

    this.variableService.user.subscribe(x => {
      this.username = x.name;
    });

    this.variableService.draggedVehicle.subscribe(x => {
      if (this.eventId != null) {
        this.getAvailableVehicles();
        const isFound = this.selectedVehicles.find(y => y.name === x);
        if (isFound == null) {
          const vehicle = this.availableVehicles.find(z => z.name === x);
          if (vehicle != null) {
            this.selectedVehicles.push(vehicle);
            this.getDisplayedVehicles();
            this.changeDetectorRef.detectChanges();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.vehicleSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
    this.hospitalSubscription.unsubscribe();
  }

  selectEvent(event: EventX) {
    if (this.aaos == null) this.aaos = this.keywordService.getKeywords();

    this.selectedEvent = event;

    // Event Data
    this.eventId = event.id;
    this.eventText = event.text;
    this.eventStartTime = new Date(event.startTime).toLocaleString();
    this.location = event.location;

    // AAO
    this.keyword = event.keyword;
    this.oldKeyword = event.keyword;
    this.signal = Boolean(event.signal);

    // Messages
    this.displayMessageDialog = false;
    this.messages = event.messages;

    // Vehicles
    this.displayVehicleDialog = false;
    this.displayedVehicles = [];
    this.selectedVehicles = [];
    this.draggedVehicle = null;
    this.vehicleFilter = '';

    this.getDisplayedVehicles();

    if ((event.blockedByLst == null || event.blockedByLst === this.username) && event.state !== 3) {
      this.disableEventForm = false;
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Info', detail: 'Dieser Einsatz wird derzeit von ' + event.blockedByLst + ' bearbeitet und ist in der Zeit für dich gesperrt.' });
    }
  }

  deselectEvent() {
    this.disableEventForm = true;

    // Clean Form
    this.selectedEvent = null;
    this.eventId = null;
    this.eventText = null;
    this.eventStartTime = null;
    this.location = null;

    // AAO
    this.keyword = [];
    this.filteredAaoSingle = [];
    this.signal = true;

    // Messages
    this.displayMessageDialog = false;
    this.messages = [];

    // Vehicles
    this.displayVehicleDialog = false;
    this.displayedVehicles = [];
    this.selectedVehicles = [];
    this.draggedVehicle = null;
    this.vehicleFilter = '';
  }

  updateEvent(event: EventX) {
    if (this.messages !== event.messages) {
      this.messages = event.messages;
    }

    if (event.blockedByLst == null) {
      // this.socketService.socketEmit('lst', { cmd: 'blockEvent', data: { eventId: event.id } });
      this.disableEventForm = false;
    }
  }

  getDisplayedVehicles() {
    if (this.eventId > 0) {
      this.displayedVehicles = [];

      // Alle derzeit im Einsatz aktiven Fahrzeuge
      this.vehicles.forEach(vehicle => {
        if (vehicle.currentEvent === this.eventId) {
          this.displayedVehicles.push({ name: vehicle.name, displayName: vehicle.displayName, status: vehicle.status, alertTime: vehicle.alertTime, type: vehicle.type, callRequest: (vehicle.hasCallRequest > 0 ? true : false), type2: vehicle.type2, statusChanges: vehicle.statusChanges });
        }
      });

      this.displayedVehicles.sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        } else if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      });

      this.displayedVehicles.sort((a, b) => {
        if (a.type < b.type) {
          return -1;
        } else if (a.type > b.type) {
          return 1;
        }
        return 0;
      });

      // Noch nicht alarmierte Fahrzeuge
      this.selectedVehicles.forEach(vehicle => {
        this.displayedVehicles.push({ name: vehicle.name, displayName: vehicle.displayName, status: vehicle.status, alertTime: null, removable: true, type: "Y", callRequest: false, type2: vehicle.type2, statusChanges: {} });
      });

      // Aus dem Einsatz ausgeschiedene Fahrzeuge
      this.selectedEvent.vehicles.forEach(vehicle => {
        const isDisplayed = this.displayedVehicles.find(x => x.name === vehicle);
        if (isDisplayed == null) {
          const veh = this.vehicles.find(x => x.name === vehicle);
          if (veh != null) {
            this.displayedVehicles.push({ name: veh.name, displayName: veh.displayName, status: '/', alertTime: null, removed: true, type: "Z", callRequest: false, type2: veh.type2, statusChanges: {} });
          }
        }
      });
    }
  }

  alertNewVehicles() {
    this.socketService.socketEmit('lst', { cmd: 'updateEventKeyword', data: { eventId: this.eventId, keyword: this.keyword } });
    const newVehicles = [];

    this.selectedVehicles.forEach(vehicle => {
      if (vehicle.currentEvent === 0) {
        newVehicles.push(vehicle.name);
        setTimeout(() => {
          const checkVehicle = this.vehicles.find(x => x.name === vehicle.name && x.status == 'C');
          if (checkVehicle != null && this.settings.audio.playBmaSound) {
            const audio = new Audio('assets/sounds/zeitueberschreitung.wav');
            audio.volume = this.settings.audio.volume;
            audio.play();
          }
        }, 120000);
      }
    });

    if (newVehicles.length !== 0) {
      const signal = this.signal ? 1 : 0;
      if (!this.keyword || this.keyword.length == 0) {
        this.keyword = [];
        this.keyword.push(this.selectedEvent.text);
      }
      const keyword = this.keyword;

      // Party-Mode
      if (this.settings.partyMode && newVehicles.some(x => x.startsWith("oerk_"))) {
        let audio = new Audio('assets/sounds/finale.mp3');
        audio.volume = this.settings.audio.volume;
        audio.play();

        let intervall = setInterval(() => {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          document.body.style.backgroundColor = "#" + randomColor;
        }, 50);
        setTimeout(() => {
          clearInterval(intervall);
          document.body.style.backgroundColor = "#0f131a";
        }, 10000)
      }

      this.socketService.socketEmit('lst', { cmd: 'alertEvent', data: { eventId: this.selectedEvent.id, signal, keyword, newVehicles } });
    } else {
      const checkVehicle = this.vehicles.find(x => x.currentEvent === this.selectedEvent.id && x.status == 'C');
      if (checkVehicle != null) {
        this.socketService.socketEmit('lst', { cmd: 'reAlertEvent', data: { eventId: this.selectedEvent.id } });
      }
    }

    this.selectedVehicles = [];
  }

  //#region Vehicledialog
  openVehicleDialog() {
    this.displayVehicleDialog = true;
    this.getAvailableVehicles();
  }

  closeVehicleDialog() {
    this.vehicleFilter = '';
    this.vehicleDialogSelectedVehicles = [];
    this.displayVehicleDialog = false;
  }

  addVehicleToEventVehicles() {
    this.vehicleDialogSelectedVehicles.forEach(vehicle => {
      this.selectedVehicles.push(vehicle);
    });

    this.closeVehicleDialog();
    this.getDisplayedVehicles();
  }

  getAvailableVehicles() {
    this.availableVehicles = [];

    this.vehicles.forEach(vehicle => {
      if (vehicle.blockedByLst == null || vehicle.blockedByLst === this.username) { // Wird von keiner Leitstelle benutzt
        if (vehicle.currentEvent === 0 && (vehicle.status == 1 || vehicle.status == 2)) { // Ist in keinem Event & Status 1 oder 2
          let veh = this.vehicleDialogSelectedVehicles.find(x => x.name === vehicle.name);

          if (veh == null) {
            veh = this.selectedVehicles.find(x => x.name === vehicle.name);
          }

          if (veh == null) { // Ist noch nichts ausgewählt
            if (this.vehicleFilter === '') {
              if (vehicle.status == 1) {
                vehicle.distance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY) - 0.10000;
              } else {
                vehicle.distance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY) + vehicle.distanceOffset;
              }
              vehicle.displayedDistance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY);
              this.availableVehicles.push(vehicle);
            } else {
              if (vehicle.displayName.toLowerCase().includes(this.vehicleFilter.toLowerCase())) {
                if (vehicle.status == 1) {
                  vehicle.distance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY) - 0.10000;
                } else {
                  vehicle.distance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY) + vehicle.distanceOffset;
                }
                vehicle.displayedDistance = this.getDistance(this.selectedEvent.posX, this.selectedEvent.posY, vehicle.posX, vehicle.posY);
                this.availableVehicles.push(vehicle);
              }
            }
          }
        }
      }
    });

    this.availableVehicles.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
  }

  filterVehicleSingle(event) {
    const query = event.query;
    const filter = [];

    this.vehicles.forEach(vehicle => {
      if (vehicle.displayName.toLowerCase().includes(query.toLowerCase()) && (vehicle.status == 1 || vehicle.status == 2) && !vehicle.type2.includes("PTZ")) {
        filter.push(vehicle.displayName);
      }
    });

    filter.sort();

    this.filteredVehicleSingle = filter;
  }

  addVehicle() {
    console.log(this.selectedVehicle);
    const vehicle = this.vehicles.find(x => x.displayName === this.selectedVehicle);
    if (vehicle != null) {
      const isFound = this.selectedVehicles.find(x => x.name === vehicle.name);
      if (isFound == null) {
        this.selectedVehicles.push(vehicle);
        this.getDisplayedVehicles();
      }
    }

    this.selectedVehicle = "";
  }

  onchange($event) {
    console.log($event)
  }

  dragStart(event, vehicle) {
    this.draggedVehicle = vehicle;
  }

  drop(event) {
    if (this.draggedVehicle) {
      const draggedVehicleIndex = this.availableVehicles.findIndex(x => x.name === this.draggedVehicle.name);
      this.vehicleDialogSelectedVehicles = [...this.vehicleDialogSelectedVehicles, this.draggedVehicle];
      this.availableVehicles = this.availableVehicles.filter((val, i) => i !== draggedVehicleIndex);
      // this.vehicleService.blockVehicle(this.draggedVehicle.name);
      this.draggedVehicle = null;
    }
  }

  dragEnd(event) {
    this.draggedVehicle = null;
  }

  addVehicleDoubleClick(vehicleName) {
    this.eventService.addVehicleToEvent(vehicleName);
  }
  //#endregion

  //#region Messagedialog
  addMessage() {
    this.socketService.socketEmit('lst', { cmd: 'addEventMessage', data: { id: this.selectedEvent.id, message: this.message } });
    this.closeMessageDialog();
  }

  closeMessageDialog() {
    this.message = '';
    this.displayMessageDialog = false;
  }
  //#endregion

  //#region AAO
  addNearestVehicleOfType2(type: string) {
    this.getAvailableVehicles();
    let vehicle = null;

    if (this.settings.eventView.preferReserveVehicleWhenCloser) {
      vehicle = this.findClosestVehicleByType2WithPrio(this.availableVehicles, type);
    } else {
      vehicle = this.findClosestVehicleByType2(this.availableVehicles, type);
    }


    if (vehicle) {
      this.vehicleService.blockVehicle(vehicle.name);
      this.selectedVehicles.push(vehicle);
      this.eventService.addSelectedVehicle(vehicle.name);
      this.getDisplayedVehicles();
    }

    // console.log("VEHICLES", this.availableVehicles)
    // let foundVehicle = null;
    // let foundVehicleButFarAway = null;

    // for (const vehicle of this.availableVehicles) {
    //   foundVehicle = vehicle.type2.find(x => x.toUpperCase() === type);
    //   if (foundVehicle != null) {
    //     if (vehicle.distance > 0.5) {
    //       console.log("FOUNDFARAWAY", vehicle)
    //       if (!foundVehicleButFarAway) foundVehicleButFarAway = vehicle;
    //     } else {
    //       console.log("FOUND", vehicle)
    //       this.vehicleService.blockVehicle(vehicle.name);
    //       this.selectedVehicles.push(vehicle);
    //       this.getDisplayedVehicles();
    //       return;
    //     }
    //   }
    // }

    // if (foundVehicle == null) {
    //   for (const vehicle of this.availableVehicles) {
    //     foundVehicle = vehicle.type2.find(x => x.toUpperCase() === type + '_RESERVE');
    //     if (foundVehicle != null) {
    //       console.log("FOUND_R", vehicle)
    //       if (foundVehicleButFarAway) {
    //         if (vehicle.distance < foundVehicleButFarAway.distance) {
    //           this.vehicleService.blockVehicle(vehicle.name);
    //           this.selectedVehicles.push(vehicle);
    //           this.getDisplayedVehicles();
    //           return;
    //         } else {
    //           this.vehicleService.blockVehicle(foundVehicleButFarAway.name);
    //           this.selectedVehicles.push(foundVehicleButFarAway);
    //           this.getDisplayedVehicles();
    //           return;
    //         }
    //       } else {
    //         this.vehicleService.blockVehicle(vehicle.name);
    //         this.selectedVehicles.push(vehicle);
    //         this.getDisplayedVehicles();
    //         return;
    //       }
    //     }
    //   }
    // }

    // if (foundVehicleButFarAway) {
    //   this.vehicleService.blockVehicle(foundVehicleButFarAway.name);
    //   this.selectedVehicles.push(foundVehicleButFarAway);
    //   this.getDisplayedVehicles();
    //   return;
    // }

    // if (!foundVehicle) {
    //   for (const vehicle of this.availableVehicles) {
    //     foundVehicle = vehicle.type2.find(x => x.toUpperCase() === type + '_RESERVE_RESERVE');
    //     if (foundVehicle != null) {
    //       console.log("FOUND_R_R", vehicle)
    //       this.vehicleService.blockVehicle(vehicle.name);
    //       this.selectedVehicles.push(vehicle);
    //       this.getDisplayedVehicles();
    //       return;
    //     }
    //   }
    // }
  }

  findClosestVehicleByType2WithPrio(vehicles, targetType) {
    const typeExtensions = ['', '_RESERVE', '_RESERVE_RESERVE'];
    let closestVehicle = null;
    let closestDistance = Infinity;
    let difference = targetType.includes("_WF") ? 0.75 : 0.3;

    for (let i = 0; i < typeExtensions.length; i++) {
      const extendedType = targetType + typeExtensions[i];
      let preferredVehicle = null;
      let preferredDistance = Infinity;

      for (let j = 0; j < vehicles.length; j++) {
        const vehicle = vehicles[j];

        if (vehicle.type2.includes(extendedType)) {
          const distance = vehicle.distance + vehicle.distanceOffset;

          if (vehicle.type2.includes(targetType)) {
            if (closestVehicle === null || distance < closestDistance - difference) {
              closestVehicle = vehicle;
              closestDistance = distance;
            }
            break;
          } else if (extendedType !== targetType + '_RESERVE_RESERVE' && (preferredVehicle === null || distance < preferredDistance)) {
            preferredVehicle = vehicle;
            preferredDistance = distance;
          }
        }
      }

      if (preferredVehicle !== null && (closestVehicle === null || preferredDistance < closestDistance - difference)) {
        closestVehicle = preferredVehicle;
        closestDistance = preferredDistance;
      }
    }

    return closestVehicle || null;
  }

  findClosestVehicleByType2(vehicles, targetType) {
    let closestVehicle = null;
    let reserveVehicle = null;
    let reserveReserveVehicle = null;

    for (let i = 0; i < vehicles.length; i++) {
      let vehicle = vehicles[i];

      if (vehicle.type2.includes(targetType)) {
        if (closestVehicle === null ||
          vehicle.distance + vehicle.distanceOffset < closestVehicle.distance + closestVehicle.distanceOffset
        ) {
          closestVehicle = vehicle;
        }
      }

      if (vehicle.type2.includes(targetType + "_RESERVE")) {
        if (reserveVehicle === null ||
          vehicle.distance + vehicle.distanceOffset < reserveVehicle.distance + reserveVehicle.distanceOffset
        ) {
          reserveVehicle = vehicle;
        }
      }

      if (vehicle.type2.includes(targetType + "_RESERVE_RESERVE")) {
        if (reserveReserveVehicle === null ||
          vehicle.distance + vehicle.distanceOffset < reserveReserveVehicle.distance + reserveReserveVehicle.distanceOffset
        ) {
          reserveReserveVehicle = vehicle;
        }
      }
    }

    if (closestVehicle !== null) {
      return closestVehicle;
    } else if (reserveVehicle !== null) {
      return reserveVehicle;
    } else {
      return reserveReserveVehicle;
    }
  }

  filterAaoSingle(event) {
    const query = event.query;
    const filter = [];

    this.aaos.forEach(aao => {
      if (aao.name.toLowerCase().includes(query.toLowerCase())) {
        filter.push(aao.name);
      }
    });

    filter.sort();

    this.filteredAaoSingle = filter;
  }

  addAaoVehicles() {
    if (this.oldKeyword == null || this.oldKeyword.length == 0) {
      for (const keyword of this.keyword) {
        const aao = this.aaos.find(x => x.name === keyword);
        if (aao) {
          aao.vehicles.forEach(vehicle => {
            this.addNearestVehicleOfType2(vehicle);
          });

          this.signal = aao.sosi;
          this.oldKeyword = this.keyword;
        }
      }
    } else {
      let neededVehicles = [];
      for (const keyword of this.keyword) {
        const aao = this.aaos.find(x => x.name === keyword);
        if (aao) {
          neededVehicles = [...neededVehicles, ...aao.vehicles];
          this.signal = (this.signal == false ? aao.sosi : true);
        }
      }

      const vehicles = this.displayedVehicles.filter(x => (x.status == 3 || x.status == 4 || x.status == 'C' || x.removable == true));
      // for (const neededVehicle of neededVehicles) {
      //   const vehs = []
      //   for (const vehicle of vehicles) {
      //     console.log("Vehs #1", vehicles);
      //     if (vehicle.type2.includes(neededVehicle)) {
      //       console.log("Vehs #2", neededVehicle, vehicles);
      //       vehs.push({ vehicle, prio: vehicle.type2.findIndex(x => x === neededVehicle) });
      //     }
      //   }
      //   console.log("Vehs #3", vehs);
      //   if (vehs.length > 0) {
      //     vehs.sort((a, b) => a.prio - b.prio);
      //     vehicles.splice(vehs[0].vehicle, 1);
      //     neededVehicles.splice(neededVehicles.findIndex(x => x === neededVehicle), 1);
      //   }
      // }


      for (const veh of vehicles) {
        for (const type of veh.type2) {
          if (neededVehicles.includes(type) || neededVehicles.includes(type.slice(0, -8)) || neededVehicles.includes(type.slice(0, -16))) {
            let type2 = type;
            if (type.includes('_RESERVE_RESERVE')) {
              type2 = type.slice(0, -16);
            } else if (type.includes('_RESERVE')) {
              type2 = type.slice(0, -8);
            }
            neededVehicles.splice(neededVehicles.findIndex(x => x === type2), 1);
            break;
          }
        }
      }

      for (const veh of neededVehicles) {
        this.addNearestVehicleOfType2(veh);
      }
    }


  }
  //#endregion

  openVehicleContextMenu(event, cm, vehicle) {
    this.vehicleContextMenuItems = [
      { label: vehicle.displayName },
      { separator: true },
      {
        label: this.translateService.instant("VEHICLE-CONTEXT.SET-STATUS"), items: [
          { label: 'Status 1', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 1) },
          { label: 'Status 3', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 3) },
          { label: 'Status 4', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 4) },
          { label: 'Status 6', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 6) },
          { label: 'Status 7', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 7) },
          { label: 'Status 8', command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 8) }
        ]
      },
      { label: this.translateService.instant("VEHICLE-CONTEXT.SPEECH-PROMPT"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 28) }
    ];

    if (vehicle.type2.includes('RTW') || vehicle.type2.includes('KTW') || vehicle.type2.includes('PTZ') || vehicle.type2.includes('ITW') || vehicle.type2.includes('RTH') || vehicle.type2.includes('IRTW') || vehicle.type2.includes('GRTW') || vehicle.type2.includes('NAW')) {
      const hospitals = [];
      this.hospitals.forEach(hospital => {
        hospitals.push({ label: hospital.name, command: (x) => this.vehicleService.assignToHospital(vehicle.name, hospital.name) });
      });

      this.vehicleContextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.ASSIGN-HOSPITAL"), items: hospitals
      });
    }

    this.vehicleContextMenuItems.push({ separator: true });

    if (vehicle.currentEvent > 0) {
      this.vehicleContextMenuItems.push({
        label: this.translateService.instant("VEHICLE-CONTEXT.SELECT-EVENT"), command: (x) => {
          this.eventService.selectEvent(vehicle.currentEvent);
        }
      });
    }

    this.vehicleContextMenuItems.push({ label: this.translateService.instant("VEHICLE-CONTEXT.RESET-VEHICLE"), command: (x) => this.vehicleService.setVehicleStatus(vehicle.name, 27) });

    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  removeVehicleFromSelected(vehicle) {
    this.eventService.removeSelectedVehicle(vehicle.name);
    const index = this.selectedVehicles.findIndex(x => x.name === vehicle.name);
    if (index > -1) {
      this.selectedVehicles.splice(index, 1);
    }
    this.getDisplayedVehicles();
  }

  onKeywordEnter(event) {
    // if (this.keyword == null) {
    //   this.keyword = [String(event.srcElement.value).toLocaleUpperCase()];
    // } else if (event.srcElement.value != null && event.srcElement.value != '' && !this.keyword.includes(event.srcElement.value)) {
    //   this.keyword.push(String(event.srcElement.value).toLocaleUpperCase());
    //   event.srcElement.value = '';
    // } else if  {
    //   event.srcElement.value = '';
    // }


    if (event.srcElement.value != null && this.keyword == null) {
      this.keyword = [String(event.srcElement.value).toLocaleUpperCase()];
    } else if (event.srcElement.value != '' && !this.keyword.includes(event.srcElement.value)) {
      this.keyword.push(String(event.srcElement.value).toLocaleUpperCase());
    } else if (event.srcElement.value == '') {
      this.addAaoVehicles();
    }

    event.srcElement.value = '';
  }

  increaseKeyword(keyword) {
    const index = this.keyword.indexOf(keyword);
    const kw = this.aaos.find(x => x.name == keyword);
    if (kw && kw.increase != null && kw.increase != '') {
      this.keyword.splice(index, 1, kw.increase);
    }
  }

  isIncreasable(keyword) {
    const kw = this.aaos.find(x => x.name == keyword);
    if (kw && kw.increase != null && kw.increase != '') {
      const kwi = this.aaos.find(x => x.name == kw.increase);
      if (kwi != null) return true;
    }
    return false;
  }

  getKeywordColor(keyword) {
    return this.keywordService.getKeywordColor(keyword);
  }

  //#region Misc
  getDistance(x1, y1, x2, y2) {
    return Math.hypot(x1 - x2, y1 - y2);
  }

  round(num) {
    return Math.round(num);
  }
  //#endregion

  log(vehicle) {
    console.log(vehicle);
  }
}
