import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem'; // ACHTUNG HIER KÖNNTE ETWAS FALSCH SEIN

@Component({
  selector: 'app-vehicle-context-menu',
  templateUrl: './vehicle-context-menu.component.html',
})
export class VehicleContextMenuComponent implements OnInit {

  constructor() { }
  items: MenuItem[];

  ngOnInit(): void {

    this.items = [
      {
        label: 'File',
        items: [{
          label: 'New',
          icon: 'pi pi-fw pi-plus',
          items: [
            { label: 'Project' },
            { label: 'Other' },
          ]
        },
        { label: 'Open' },
        { label: 'Quit' }
        ]
      },
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
          { label: 'Delete', icon: 'pi pi-fw pi-trash' },
          { label: 'Refresh', icon: 'pi pi-fw pi-refresh' }
        ]
      }
    ];
  }

}
