import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Settings, SettingsService } from './settings.service';

export interface Station {
  id: number;
  identifier: string;
  name: string;
  nameEn: string;
  type: string;
  posX: number;
  posY: number;

  smCols: number;
  smRows: number;
  smX: number;
  smY: number;
}

@Injectable({ providedIn: 'root' })

export class StationService {
  settings: Settings;

  private stations: Station[] = [];
  private station = new Subject<Station>();

  constructor(
    private settingsService: SettingsService
  ) {
    this.settingsService.settings.subscribe(settings => {
      this.settings = settings;
    })
  }

  public getUpdate(): Observable<Station> {
    return this.station.asObservable();
  }

  resetStations() {
    this.stations = [];
  }

  getStations(): Station[] {
    return this.stations;
  }

  updateStation(station: Station) {
    let data = this.stations.find(x => x.identifier === station.identifier);
    if (data == null) {
      if (!this.settings.useGermanDisplayNames && station.nameEn != null) {
        station.name = station.nameEn;
      }
      this.stations.push(station);
      this.station.next(station);
    } else {
      if (station.id !== undefined && station.id !== data.id) {
        data = station;
      }

      this.station.next(data);
    }
  }
}
