import { Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { KeywordService } from 'src/app/services/keyword.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  settings = null;
  tabItems = [];
  activeTab = null;

  languages = [
    { label: "Deutsch (de)", value: "de" },
    { label: "English (en)", value: "en" },
    { label: "Chinese (zh)", value: "zh" }
  ]

  savedLayouts = null;
  vehicleTypes = [];
  vehicleOrgs = [
    { label: this.translateService.instant("SETTINGS.MAP.FIREDEPT"), value: "1" },
    { label: this.translateService.instant("SETTINGS.MAP.RESCUE"), value: "2" },
    { label: this.translateService.instant("SETTINGS.MAP.POLICE"), value: "3" }
  ]

  constructor(
    private dialogReference: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private keywordService: KeywordService,
    private translateService: TranslateService
  ) {
    this.dialogConfig.showHeader = false;
    this.dialogConfig.dismissableMask = false;
    this.dialogConfig.width = '85%';
    this.dialogConfig.height = '85%';

    this.dialogReference.onDestroy.subscribe(() => {
      this.closeSettings();
    })
  }

  async ngOnInit() {
    this.settings = this.settingsService.getAll();
    this.savedLayouts = await this.storageService.get("layouts") || [];

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.refreshMenus();
    })
    this.refreshMenus();
  }

  refreshMenus() {
    this.tabItems = [
      {
        label: this.translateService.instant("SETTINGS.GENERAL.TITLE"), command: () => {
          this.activeTab = this.tabItems[0]
        }
      },
      // {
      //   label: 'Layout', command: () => {
      //     this.activeTab = this.tabItems[1]
      //   }
      // },
      {
        label: this.translateService.instant("AAO-EDITOR.TITLE"), command: () => {
          this.activeTab = this.tabItems[1]
        }
      }
    ];
    this.activeTab = this.tabItems[0];

    const vehicles = this.keywordService.getVehicleTypes();
    this.vehicleTypes = [];
    for (const vehicle in vehicles) {
      this.vehicleTypes.push({ label: this.translateService.instant("VEHICLE-TYPES." + vehicle), value: vehicle })
    }
  }

  closeSettings() {
    this.settingsService.setAll(this.settings);
    this.dialogReference.close();
  }

  resizeSettings() {
    if (this.dialogConfig.width == "85%") {
      this.dialogConfig.width = "100%";
      this.dialogConfig.height = "100%";
    } else {
      this.dialogConfig.width = "85%";
      this.dialogConfig.height = "85%";
    }
  }

  save(key: string, value: any) {
    this.settings[key] = value;
    this.settingsService.set(key, value);
  }

  onLayoutRowEditSave(layout) {
    if (layout.name.length == 0) return;
    this.storageService.set("layouts", this.savedLayouts);
  }

  async onLayoutRowEditCancel() {
    this.savedLayouts = await this.storageService.get("layouts") || [];
  }

  onLayoutRowDelete(layout) {
    this.savedLayouts = this.savedLayouts.filter(x => x.id !== layout.id);
    this.storageService.remove("layouts_" + layout.id);
    this.storageService.set("layouts", this.savedLayouts);
  }

  onButtonRowInit(button) {
    button.oldName = button.name;
  }

  onButtonRowEditSave(button) {
    if (button.name.length == 0) {
      button.name = button.value;
    }
    delete button.oldName;
  }

  async onButtonRowEditCancel(button) {
    button.name = button.oldName;
    delete button.oldName;
  }

  onButtonRowDelete(button) {
    this.settings.eventView.fastButtons = this.settings.eventView.fastButtons.filter(x => x.id !== button.id);
  }

  @ViewChild('fastButtonTable', { static: false }) private fastButtonTable: Table;
  onButtonRowAdd() {
    // Generate random id
    const length = 5;
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";

    for (let i = 0; i < length; i++) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    this.settings.eventView.fastButtons.push({ id, name: "", value: "", new: true });
    this.fastButtonTable.initRowEdit(this.fastButtonTable.value[this.settings.eventView.fastButtons.length - 1])
  }

  changeLanguage($event) {
    this.translateService.use($event.value);
  }
}
