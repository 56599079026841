import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SessionPickerComponent } from './components/session-picker/session-picker.component';
import { DialogService } from 'primeng/dynamicdialog';
import { StorageService } from './services/storage.service';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [ConfirmationService, MessageService, DialogService]
})
export class AppComponent implements OnInit {
  constructor(
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private settingsService: SettingsService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (await this.storageService.get('cookies') != "1") {
      this.confirmationService.confirm({
        header: 'Cookies / Localstorage',
        // tslint:disable-next-line: max-line-length
        message: 'Diese Seite verwendet Cookies und den Local Storage deines Browsers zum Speichern von Daten.<br/>Ohne die Nutzung dessen ist die Nutzung der Leitstelle leider nicht möglich.<br/>Dürfen deinen Local Storage nutzen?',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        accept: () => {
          this.storageService.set('cookies', '1');
          this.openSessionPicker();
        },
        reject: () => {
          window.location.href = 'https://de.wikipedia.org/wiki/HTTP-Cookie';
        }
      });
    } else {
      this.openSessionPicker();
    }

    // Initialize Settings
    await this.settingsService.init()
  }

  openSessionPicker() {
    this.dialogService.open(SessionPickerComponent, {});
  }

}
