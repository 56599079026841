import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SocketIOService } from 'src/app/services/socket-io.service';
import { VariableService } from 'src/app/services/variable.service';

@Component({
  selector: 'app-session-picker',
  templateUrl: './session-picker.component.html',
})
export class SessionPickerComponent implements OnInit {
  sessions = [];
  selectedSession: any;
  sessionPassword: string = '';
  filteredSessionsSingle: any[];
  saveUsername: boolean;
  blockedPanel: boolean = true;
  error = null;
  errorType = 'success';

  constructor(
    private variableService: VariableService,
    private socketService: SocketIOService,
    private router: Router,
    private dialogReference: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.dialogConfig.showHeader = false;
    this.dialogConfig.dismissableMask = true;
    this.dialogConfig.width = '75%';
  }

  ngOnInit(): void {
    this.variableService.mapAssetId.subscribe(mapAssetId => {
      if (mapAssetId != null) {
        this.dialogReference.close();
      }
    });

    this.variableService.user.subscribe(x => {
      if (x.name != null) {
        this.blockedPanel = false;
      } else {
        this.blockedPanel = true;
      }
    });
    this.getSessions();
    this.socketService.socketListener().subscribe(payload => {
      if (payload['cmd'] == 'updateSessions' && payload['data'] && payload['data'].length > 0) this.sessions = payload['data'];
      if (payload['cmd'] == 'authSessionResponse') {
        if (payload['data'].error > 0) {
          this.error = payload['data'].msg;
          this.errorType = 'error';
        } else {
          this.errorType = 'success';
          this.error = 'Sitzung gefunden, bitte warten!';
        }
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  auth() {
    this.socketService.authSession(this.selectedSession.sessionName, this.selectedSession.startTime, this.sessionPassword);
  }

  getSessions() {
    this.socketService.socketEmit('lst', { cmd: 'getSessions', data: '0' });
  }

  // filterSessionSingle(event) {
  //   const query = event.query;
  //   this.filteredSessionsSingle = this.filterSession(query, this.sessions);
  // }

  // filterSession(query, sessions: any[]): any[] {
  //   let filtered: any[] = [];
  //   for (let i = 0; i < sessions.length; i++) {
  //     const session = sessions[i];
  //     if (session.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(session);
  //     }
  //   }
  //   return filtered;
  // }

  closePicker() {
    this.dialogReference.close();
  }
}
