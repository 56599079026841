import { Component, AfterViewInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import { EventService, EventX } from 'src/app/services/event.service';
import { VehicleService, Vehicle } from 'src/app/services/vehicle.service';
import { Subscription } from 'rxjs';
import { VariableService } from 'src/app/services/variable.service';
import { Router } from '@angular/router';
import { Leitsystem, LeitsystemService } from 'src/app/services/leitsystem.service';
import { Settings, SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
})
export class MapComponent implements OnDestroy {
  settings: Settings;

  @Input() height = 0;
  @Input() width = 0;

  eventSubscription: Subscription;
  events: EventX[] = [];
  eventMarker = [];
  leitsysteme: Leitsystem[] = [];
  leitsystemSubscription: Subscription;
  leitsystemMarker = [];
  selectedLeitsystem: Leitsystem = null;
  displayLeitsystemDialog = false;
  leitsystemStates = ['speed_30', 'speed_50', 'speed_60', 'speed_70', 'speed_80', 'speed_100', 'speed_120', 'speed_130', 'ueberholverbot', 'ueberholverbot_lkw', 'leer', 'achtung', 'stau', 'baustelle', 'oelspur', 'verengung_links', 'verengung', 'verengung_rechts', 'achtung_schnee', 'achtung_bodenwelle', 'achtung_unfall', 'off', 'schrift_stau', 'schrift_unfall', 'schrift_oelspur', 'schrift_nebel', 'pfeil_gruen', 'kreuz_rot', 'gesperrt', 'unfall_links', 'unfall_rechts', 'pfeil_gelb'];
  vehicleSubscription: Subscription;
  vehicles: Vehicle[] = [];
  vehicleMarker = [];

  displayDummyMarker = false;
  displayPingMarker = false;
  dummyMarkerPosition = [0, 0];
  dummyMarker = null;
  selectedEvent = 0;

  mapAssetId: string = null;

  private map;

  constructor(
    private eventService: EventService,
    private changeDetectorRef: ChangeDetectorRef,
    private leitsystemService: LeitsystemService,
    private vehicleService: VehicleService,
    private variableService: VariableService,
    private router: Router,
    private settingsService: SettingsService
  ) {
    const events = this.eventService.getEvents();
    events.forEach(event => {
      let data = this.events.find(x => x.id === event.id);
      if (data == null) {
        this.events.push(event);
        setTimeout(() => {
          this.updateEventMarker(event);
        }, 2000)
      } else {
        data = event;
        this.updateEventMarker(event);
      }
    });
    this.eventSubscription = this.eventService.getUpdate().subscribe(event => {
      let data = this.events.find(x => x.id === event.id);
      if (data == null) {
        this.events.push(event);
      } else {
        data = event;
      }
      this.updateEventMarker(event);
      // this.changeDetectorRef.detectChanges();
    });

    const leitsysteme = this.leitsystemService.getLeitsysteme();
    leitsysteme.forEach(leitsystem => {
      let data = this.leitsysteme.find(x => x.identifier === leitsystem.identifier);
      if (data == null) {
        this.leitsysteme.push(leitsystem);
        setTimeout(() => {
          this.updateLeitsystemMarker(leitsystem);
        }, 2000)
      } else {
        data = leitsystem;
        this.updateLeitsystemMarker(leitsystem);
      }
    });
    this.leitsystemSubscription = this.leitsystemService.getUpdate().subscribe(leitsystem => {
      let data = this.leitsysteme.find(x => x.identifier === leitsystem.identifier);
      if (data == null) {
        this.leitsysteme.push(leitsystem);
      } else {
        data = leitsystem;
      }
      this.updateLeitsystemMarker(leitsystem);
      // this.changeDetectorRef.detectChanges();
    });

    const vehicles = this.vehicleService.getVehicles();
    vehicles.forEach(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        this.vehicles.push(vehicle);
        setTimeout(() => {
          this.updateVehicleMarker(vehicle);
        }, 2000)
      } else {
        data = vehicle;
        this.updateVehicleMarker(vehicle);
      }
    });
    this.vehicleSubscription = this.vehicleService.getUpdate().subscribe(vehicle => {
      let data = this.vehicles.find(x => x.name === vehicle.name);
      if (data == null) {
        this.vehicles.push(vehicle);
      } else {
        data = vehicle;
      }
      this.updateVehicleMarker(vehicle);
      // this.changeDetectorRef.detectChanges();
    });

    this.variableService.displayDummyMarker.subscribe(x => {
      this.setDisplayDummyMarker(x);
    });

    this.variableService.displayPingMarker.subscribe(x => {
      this.setDisplayDummyMarker(x);
    });

    this.variableService.selectedEvent.subscribe(x => {
      const blueIcon = L.icon({
        iconUrl: 'assets/leaflet/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41]
      });
      const redIcon = L.icon({
        iconUrl: 'assets/leaflet/marker-icon-red.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41]
      });

      if (this.eventMarker[this.selectedEvent] != null) {
        this.eventMarker[this.selectedEvent].setIcon(blueIcon);
      }

      if (this.eventMarker[x]) {
        this.eventMarker[x].setIcon(redIcon);
      }

      this.selectedEvent = x;
    });

    this.variableService.mapAssetId.subscribe(mapAssetId => {
      this.changeMap(mapAssetId);
    });

    this.settingsService.settings.subscribe(settings => {
      this.settings = { ...settings };
    })

  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
    this.vehicleSubscription.unsubscribe();
    if (this.map) {
      this.map.remove();
    }
  }

  changeMap(mapAssetId) {
    this.mapAssetId = mapAssetId;

    switch (mapAssetId) {
      case '2228270134': {
        setTimeout(() => {
          if (this.router.url === '/game') {
            this.map = L.map('map', {
              crs: L.CRS.Simple,
              minZoom: -3.5,
              maxZoom: 1,
              wheelPxPerZoomLevel: 240,
              zoomDelta: 0.20,
              zoomSnap: 0,
              scrollWheelZoom: true
            });
            this.setBackground('assets/leaflet/maps/linzing.webp', 2048, 1526, this.map);
          }
        }, 2000);
        break;
      }
      case '3082008348': {
        setTimeout(() => {
          if (this.router.url === '/game') {
            this.map = L.map('map', {
              crs: L.CRS.Simple,
              minZoom: -3.5,
              maxZoom: 1,
              wheelPxPerZoomLevel: 240,
              zoomDelta: 0.20,
              zoomSnap: 0,
              scrollWheelZoom: true
            });
            this.setBackground('assets/leaflet/maps/luedenscheid_121.webp', 2048, 2048, this.map);
          }
        }, 2000);
        break;
      }
      case '2228270752': {
        setTimeout(() => {
          if (this.router.url === '/game') {
            this.map = L.map('map', {
              crs: L.CRS.Simple,
              minZoom: -3.5,
              maxZoom: 1,
              wheelPxPerZoomLevel: 240,
              zoomDelta: 0.20,
              zoomSnap: 0,
              scrollWheelZoom: true
            });
            this.setBackground('assets/leaflet/maps/brandstedt_13.webp', 2048, 1486, this.map);
          }
        }, 2000);
        break;
      }
    }
  }

  setBackground(mapUrl, w, h, map) {
    const southWest = map.unproject([0, h], map.getMaxZoom() - 1);
    const northEast = map.unproject([w, 0], map.getMaxZoom() - 1);
    const bounds = new L.LatLngBounds(southWest, northEast);

    L.imageOverlay(mapUrl, bounds).addTo(map);
    map.setZoom(-2.5);
    map.fitBounds(bounds);
    map.panTo(new L.LatLng(0, 0));
  }

  getCoords(x, y) {
    // Berechnung
    if (this.mapAssetId == '2228270134') {
      const xcord = 2048 * x;
      const ycord = (1526 * y) * -1;
      return [ycord, xcord];
    }

    if (this.mapAssetId == '3082008348') {
      const xcord = 2048 * x;
      const ycord = (2048 * y) * -1;
      return [ycord, xcord];
    }

    if (this.mapAssetId == '2228270752') {
      const xcord = 2048 * x;
      const ycord = (1486 * y) * -1;
      return [ycord, xcord];
    }

    return [0, 0];
  }

  updateEventMarker(event: EventX) {
    if (this.map == null) {
      setTimeout(() => this.updateEventMarker(event), 2000);
      return;
    }

    if (this.eventMarker[event.id] == null && this.settings.map.eventMarker === true) {
      if (event.state === 2 || event.state === 1) {
        const marker = L.marker(this.getCoords(event.posX, event.posY));
        this.eventMarker[event.id] = marker;
        this.eventMarker[event.id].on('click', () => {
          this.eventService.selectEvent(event.id);
        });

        this.addEventMarker(event);
      }
    } else {
      if (event.state === 3) {
        this.eventMarker[event.id].remove();
        this.eventMarker[event.id] = null;
      }
    }
  }

  addLeitsystemMarker(leitsystem: Leitsystem) {
    if (this.map !== undefined) {
      this.leitsystemMarker[leitsystem.identifier].addTo(this.map);
    } else {
      setTimeout(() => { this.addLeitsystemMarker(leitsystem); }, 1000);
    }
  }

  updateLeitsystemMarker(leitsystem: Leitsystem) {
    if (this.map == null) {
      setTimeout(() => this.updateLeitsystemMarker(leitsystem), 2000);
      return;
    }

    if (this.leitsystemMarker[leitsystem.identifier] == null && this.settings.map.leitsystemMarker === true) {
      const icon = L.icon({
        iconUrl: 'assets/leaflet/traffic-icon.png',
        iconSize: [32, 32]
      });
      const marker = L.marker(this.getCoords(leitsystem.posX, leitsystem.posY), { icon });
      this.leitsystemMarker[leitsystem.identifier] = marker;
      this.leitsystemMarker[leitsystem.identifier].on('click', () => {
        // this.leitsystemService.selectEvent(leitsystem.identifier);
        this.selectedLeitsystem = leitsystem;
        this.displayLeitsystemDialog = true;
        this.changeDetectorRef.detectChanges();
        console.log("LEIT", this.selectedLeitsystem)
      });

      this.addLeitsystemMarker(leitsystem);
    }
  }

  applyLeitsystem() {
    this.leitsystemService.setLeitsystem(this.selectedLeitsystem);
    this.displayLeitsystemDialog = false;
    this.selectedLeitsystem = null;
    this.changeDetectorRef.detectChanges();
  }

  addEventMarker(event: EventX) {
    if (this.map !== undefined) {
      this.eventMarker[event.id].addTo(this.map);
    } else {
      setTimeout(() => { this.addEventMarker(event); }, 1000);
    }
  }

  updateVehicleMarker(vehicle: Vehicle) {
    if (this.map == null) {
      setTimeout(() => this.updateVehicleMarker(vehicle), 2000);
      return;
    }

    const vehicleId = this.vehicles.findIndex(x => x.name === vehicle.name);
    if (this.vehicleMarker[vehicleId] == null && this.settings.map.vehicleMarker.includes(vehicle.type)) {
      if (vehicle.status != 2 && vehicle.status != 6 && vehicle.status != 7) {
        let iconPath = 'assets/leaflet/vehicles/' + vehicle.name + '.webp';

        if (vehicle.signal) {
          iconPath = 'assets/leaflet/vehicles/' + vehicle.name + '_b.webp';
        }

        const icon = L.icon({
          iconUrl: iconPath,
          iconSize: [57, 30]
        });

        const marker = L.marker(this.getCoords(vehicle.posX, vehicle.posY), { icon });
        this.vehicleMarker[vehicleId] = marker;
        this.vehicleMarker[vehicleId].on('click', () => {
          if (vehicle.currentEvent > 0) {
            this.eventService.selectEvent(vehicle.currentEvent);
          }
        });
        this.addVehicleMarker(vehicleId);

        // marker.setIcon(icon);

      }
    } else {
      if (vehicle.status == 2 || vehicle.status == 6 || vehicle.status == 7) {
        this.vehicleMarker[vehicleId].remove();
        this.vehicleMarker[vehicleId] = null;
      } else {
        const coords = this.getCoords(vehicle.posX, vehicle.posY);
        const newPos = new L.LatLng(coords[0], coords[1]);
        this.vehicleMarker[vehicleId].setLatLng(newPos);
        this.vehicleMarker[vehicleId].bindPopup('<b>' + vehicle.displayName + '</b><br>' + 'Status: ' + vehicle.status + '<br>Einsatz Nr.: ' + vehicle.currentEvent, { autoPan: false, closeButton: false });
      }
    }
  }

  addVehicleMarker(vehicleId: number) {
    if (this.map !== undefined) {
      this.vehicleMarker[vehicleId].addTo(this.map);
      this.updateVehicleIcon(vehicleId, false);
    } else {
      setTimeout(() => { this.addVehicleMarker(vehicleId); }, 1000);
    }
  }

  updateVehicleIcon(vehicleId: number, signal: boolean) {
    if (this.vehicleMarker[vehicleId] != null) {
      let iconPath = 'assets/leaflet/vehicles/' + this.vehicles[vehicleId].name + '.webp';

      if (signal === true && this.vehicles[vehicleId].status != 1) {
        iconPath = 'assets/leaflet/vehicles/' + this.vehicles[vehicleId].name + '_b.webp';
      }

      const icon = L.icon({
        iconUrl: iconPath,
        iconSize: [57, 30]
      });

      this.vehicleMarker[vehicleId].setIcon(icon);

      if (this.vehicles[vehicleId].signal) {
        setTimeout(() => {
          switch (this.vehicles[vehicleId].name) {
            case 'AB-ELW': break;
            case 'AB-MANV': break;
            case 'AB-Dekon': break;
            case 'hummel_01': break;
            default: this.updateVehicleIcon(vehicleId, !signal); break;
          }
        }, 600);
      }
    }
  }

  // TODO: Das geht besser :joy:
  setDisplayDummyMarker(set: boolean) {
    if (set === true) {
      if (this.displayDummyMarker === false) {
        const icon = L.icon({
          iconUrl: 'assets/leaflet/marker-icon-red.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41]
        });

        this.dummyMarker = L.marker([-1024, 2048], {
          draggable: 'true',
          icon
        });
        this.dummyMarker.addTo(this.map);

        this.dummyMarker.on('dragend', () => {
          let newLat = this.dummyMarker.getLatLng().lat;
          let newLong = this.dummyMarker.getLatLng().lng;

          if (this.mapAssetId == '2228270134') {
            newLat = (newLat / 1526) * -1;
            newLong = (newLong / 2048);
          }

          if (this.mapAssetId == '3082008348') {
            newLat = (newLat / 2048) * -1;
            newLong = (newLong / 2048);
          }

          if (this.mapAssetId == '2228270752') {
            newLat = (newLat / 1782) * -1;
            newLong = (newLong / 2048);
          }

          this.variableService.dummyMarkerPosition.next([newLat, newLong]);
        });
      }
    } else {
      if (this.displayDummyMarker === true) {
        this.dummyMarker.remove();
        this.dummyMarker = null;

        // this.events.forEach(event => {
        //   this.updateEventMarker(event);
        // });
      }
    }

    this.displayDummyMarker = set;
  }
}

